import { Component } from '@angular/core';

@Component({
  selector: 'app-outgoing-request-type',
  templateUrl: './outgoing-request-type.component.html',
  styleUrls: ['./outgoing-request-type.component.scss']
})
export class OutgoingRequestTypeComponent {
  
  public chosenInternal: boolean = true;
  public requestType: string = 'internal';
  chooseType(){
    this.chosenInternal = !this.chosenInternal;
  }

}
