import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AmsResponseString,
  ManageUserControllerService,
} from 'src/app/oapi_client/data_symphony';

@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.scss'],
})
export class EmailConfirmationComponent {
  isLoading: boolean = true;
  isSuccess: boolean = false;
  errorMessage: string = '';
  code: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private emailVerificationService: ManageUserControllerService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.code = params['code'];
      if (this.code) {
        this.verifyEmail(this.code);
      } else {
        this.isLoading = false;
        this.errorMessage = 'Invalid verification code.';
      }
    });
  }

  navigateToLoginPage(): void {
    this.router.navigate(['/login']);
  }

  verifyEmail(verificationCode: string): void {
    this.emailVerificationService.verify(verificationCode).subscribe({
      next: (response: AmsResponseString) => {
        this.isLoading = false;
        this.isSuccess = true;
      },
      error: (error) => {
        this.isLoading = false;
        this.isSuccess = false;
        this.errorMessage =
          'Email verification failed. Please try again later.';
      },
    });
  }
}
