import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CompanyControllerService } from 'src/app/oapi_client/data_symphony';

export type RoleType =
  | 'CANDIDATE_POOLS'
  | 'PROCESS_MANAGEMENT'
  | 'TEAM_MANAGEMENT'
  | 'JOB_ADVERTISEMENT_MANAGEMENT'
  | 'TALENT_HUNT_&_TALENT_DISCOVERY'
  | 'TALENT_ACQUISITION_FULL'
  | 'OTHER';

@Component({
  selector: 'app-privileges-modal',
  templateUrl: './privileges-modal.component.html',
  styleUrls: ['./privileges-modal.component.scss'],
})
export class PrivilegesModalComponent {
  @Input() roles: RoleType[] = [];
  @Input() isVisible = false;
  @Output() close = new EventEmitter<void>();

  currentIndex = 0;
  companyName: string = 'Name';

  roleContent = {
    CANDIDATE_POOLS: {
      title: 'Candidate Pools',
      subtitle:
        'Enables viewing of groups that other team members have created from our talent database.',
      description: [
        'View candidate pools created by others with candidates from our database',
      ],
    },
    PROCESS_MANAGEMENT: {
      title: 'Process Management',
      subtitle:
        'Allows uploading of interview videos, viewing candidates within specific steps, and moving them between steps.',
      description: [
        'Ability to upload interview videos',
        'Permission to view candidates within each process step',
        'Option to move candidates between stages',
      ],
    },
    TEAM_MANAGEMENT: {
      title: 'Team Management',
      subtitle: 'Enables the member to invite additional team members.',
      description: ['Send invitations to new team members'],
    },
    JOB_ADVERTISEMENT_MANAGEMENT: {
      title: ' Job Advertisement',
      subtitle:
        'Allows creating and external posting of job ads, as well as the option to publish existing requests externally.',
      description: [
        'Create job ads',
        'Post job ads externally',
        'Publish existing requests externally',
      ],
    },
    'TALENT_HUNT_&_TALENT_DISCOVERY': {
      title: 'Talent Hunt & Discovery',
      subtitle:
        'Grants permission to search for candidates, add them to our database, and review their profiles.',
      description: [
        'Search for candidates',
        'Add candidates to our database',
        'View candidate profiles',
      ],
    },
    TALENT_ACQUISITION_FULL: {
      title: 'Talent Acquisition (Full)',
      subtitle:
        'Allows unrestricted access to all talent-related features, including searching for candidates, adding them to candidate pools, and moving them across different interview steps.',
      description: [
        'Access to talent hunt',
        'Access to talent discovery',
        'Access to candidate pools',
        'Access to job postings',
        'Creating process and interviewing people',
      ],
    },
    OTHER: {
      title: 'Other',
      subtitle:
        'Grants access to the full recruitment process, divided into multiple steps (e.g., initial interview, technical interview), with permissions to publish job ads, create teams, and invite new members.',
      description: [
        'Access to the full hiring process, divided into steps ',
        'Ability to publish and create job advertisements externally',
        'Permission to manage team members',
        'Option to send invitations to new team members',
      ],
    },
  };

  constructor(private companyService: CompanyControllerService) {}

  ngOnInit(): void {
    this.getMyCompany();
  }

  closeModal() {
    this.isVisible = false;
    this.close.emit();
    localStorage.setItem('firstSignIn', 'false');
  }

  next() {
    if (this.currentIndex < this.roles.length - 1) {
      this.currentIndex++;
    }
  }

  previous() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    }
  }

  goToSlide(index: number) {
    if (index >= 0 && index < this.roles.length) {
      this.currentIndex = index;
    }
  }

  getRoleContent(role: RoleType) {
    return this.roleContent[role];
  }

  getMyCompany() {
    this.companyService.getMyCompany().subscribe((res) => {
      this.companyName = res.body!.name ?? '';
    });
  }
}
