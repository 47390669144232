/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { AmsResponseEmailMessageContent } from '../models/AmsResponseEmailMessageContent';
import type { AmsResponseListEmailMessageContent } from '../models/AmsResponseListEmailMessageContent';
import type { AmsResponseString } from '../models/AmsResponseString';
import type { CreateEmailMessageContentRequest } from '../models/CreateEmailMessageContentRequest';
import type { UpdateEmailMessageContentRequest } from '../models/UpdateEmailMessageContentRequest';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class EmailMessageContentControllerService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @param id
     * @param requestBody
     * @returns AmsResponseEmailMessageContent OK
     * @throws ApiError
     */
    public update3(
        id: number,
        requestBody: UpdateEmailMessageContentRequest,
    ): Observable<AmsResponseEmailMessageContent> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v1/manage/emailMessage/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns AmsResponseString OK
     * @throws ApiError
     */
    public delete3(
        id: number,
    ): Observable<AmsResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/v1/manage/emailMessage/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @returns AmsResponseListEmailMessageContent OK
     * @throws ApiError
     */
    public getAll1(): Observable<AmsResponseListEmailMessageContent> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/manage/emailMessage',
        });
    }
    /**
     * @param requestBody
     * @returns AmsResponseEmailMessageContent OK
     * @throws ApiError
     */
    public create1(
        requestBody: CreateEmailMessageContentRequest,
    ): Observable<AmsResponseEmailMessageContent> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/manage/emailMessage',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
