import {
  Component,
  ViewChild,
  ChangeDetectorRef,
  NgZone,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';
import {
  CreateLinkedinProfileFilterRequest,
  GroupControllerService,
  DSResponseListGroupModelDto,
  GroupModelDto,
  LinkedinProfileDto,
  CreateProcessCandidateStatusRequest,
  ProcessCandidateStatusControllerService,
} from '../../oapi_client/data_symphony';
import { Table } from 'primeng/table';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ParameterService } from 'src/app/helpers/parameter.service';
import { PatternService } from 'src/app/helpers/pattern.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-candidate-pool-modal',
  templateUrl: './candidate-pool-modal.component.html',
  styleUrls: ['./candidate-pool-modal.component.scss'],
})
export class CandidatePoolModalComponent {
  @Output() closeModal = new EventEmitter<void>();
  @Input() processId!: number;
  @Output() peopleAdded = new EventEmitter<void>();
  filter: CreateLinkedinProfileFilterRequest = {};
  @ViewChild('dt') dataTable: Table | undefined;
  loading?: boolean;
  groups$: GroupModelDto[] = [];
  dialogRef: DynamicDialogRef | undefined;
  selectedGroup: GroupModelDto | undefined;
  profiles$: LinkedinProfileDto[] = [];
  selectedProfiles: { [pageIndex: number]: Set<string> } = {};
  totalRecords: number = 0;
  selectAll: boolean = false;

  constructor(
    private readonly groupService: GroupControllerService,
    protected paginationService: PaginationService,
    protected parameterService: ParameterService,
    public patternService: PatternService,
    private cdr: ChangeDetectorRef,
    private zone: NgZone,
    private messageService: MessageService,
    private talentService: ProcessCandidateStatusControllerService
  ) {}

  ngOnInit() {
    if (this.dataTable) {
      this.dataTable.first =
        this.paginationService.lazyProfileListPaginationState.first;
      this.dataTable.rows =
        this.paginationService.lazyProfileListPaginationState.rows;
    }
    this.getGroups();
  }

  getGroups() {
    this.groupService.getAll2().subscribe(
      (response: DSResponseListGroupModelDto) => {
        this.zone.run(() => {
          this.groups$ = response.body || [];

          this.selectedGroup = this.groups$[0];
          if (this.selectedGroup) {
            this.getMemberProfiles(this.selectedGroup.id!, 0, 10);
          }

          this.paginationService.lazyProfileListPaginationState.totalRecords = 0;
          if (this.dataTable) {
            this.dataTable.totalRecords =
              this.paginationService.lazyProfileListPaginationState.totalRecords;
          }

          this.loading = false;
          this.cdr.detectChanges();
        });
      },
      (error: any) => {
        alert(error);
      }
    );
  }

  onGroupChange() {
    if (this.selectedGroup) {
      this.selectedProfiles = {};
      this.getMemberProfiles(this.selectedGroup.id!, 0, 10);
    }
    this.cdr.detectChanges();
  }

  loadProfiles(event: any) {
    this.loading = true;
    const pageIndex = Math.floor((event.first || 0) / (event.rows || 10));
    this.filter.pageIndex = pageIndex;
    if (this.selectedGroup) {
      this.getMemberProfiles(
        this.selectedGroup!.id!,
        pageIndex,
        event.rows || 10
      );
    }
  }

  getMemberProfiles(groupId: number, pageIndex: number, pageSize: number) {
    const requestBody = {
      ...this.filter,
      pageIndex: pageIndex,
      pageSize: pageSize,
    };

    this.groupService.listMember(groupId, requestBody).subscribe(
      (response: any) => {
        this.profiles$ = response.body?.content || [];
        this.totalRecords = response.body?.totalElements || 0;
        this.loading = false;
        this.checkSelectAll();
      },
      (error: any) => {
        console.error('Error fetching member list:', error);
        this.loading = false;
      }
    );
  }

  toggleSelectAll(event: any) {
    const pageIndex = this.filter.pageIndex ?? 0;

    if (!this.selectedProfiles[pageIndex]) {
      this.selectedProfiles[pageIndex] = new Set<string>();
    }
    this.selectAll = event.target.checked;
    if (this.selectAll) {
      this.profiles$.forEach((profile) => {
        const profileId =
          profile.publicMemberId || profile.publicLinkedinMemberId;
        if (profileId) {
          this.selectedProfiles[pageIndex].add(profileId);
        }
      });
    } else {
      this.selectedProfiles[pageIndex].clear();
    }
  }

  toggleSelect(profile: LinkedinProfileDto | undefined) {
    if (!profile) return;
    const profileId = profile.publicMemberId || profile.publicLinkedinMemberId;
    if (!profileId) return;

    const pageIndex = this.filter.pageIndex ?? 0;

    if (!this.selectedProfiles[pageIndex]) {
      this.selectedProfiles[pageIndex] = new Set<string>();
    }

    if (this.selectedProfiles[pageIndex].has(profileId)) {
      this.selectedProfiles[pageIndex].delete(profileId);
    } else {
      this.selectedProfiles[pageIndex].add(profileId);
    }

    this.checkSelectAll();
  }

  checkSelectAll() {
    const pageIndex = this.filter.pageIndex ?? 0;
    if (!this.selectedProfiles[pageIndex]) {
      this.selectedProfiles[pageIndex] = new Set<string>();
    }

    const selectedProfileIds = Array.from(this.selectedProfiles[pageIndex]);
    this.selectAll = selectedProfileIds.length === this.profiles$.length;
  }

  addTalentsToProcessStep() {
    const selectedProfilesArray: string[] = this.getSelectedProfilesArray();
  
    if (this.selectAll) {
      this.addAllProfilesToProcess();
      return;
    }
  
    if (selectedProfilesArray.length > 0) {
      this.addProfilesToProcess(selectedProfilesArray);
    } else {
      this.showMessage('info', 'Info', 'No profiles selected.');
    }
  }
  
  private getSelectedProfilesArray(): string[] {
    const profiles: string[] = [];
    Object.keys(this.selectedProfiles).forEach((pageIndex) => {
      const profilesSet = this.selectedProfiles[parseInt(pageIndex, 10)];
      profiles.push(...profilesSet);
    });
    return profiles;
  }
  
  private addAllProfilesToProcess() {
    this.filter.pageSize = this.dataTable?.totalRecords;
    this.groupService.listMember(this.selectedGroup?.id!, this.filter).subscribe(
      (res) => {
        const allProfiles = res.body?.content?.map((f) => f.publicMemberId!) || [];
        this.addProfilesToProcess(allProfiles);
      },
      () => {
        this.showMessage('error', 'Error', 'Error fetching all profiles.');
      }
    );
  }
  
  private addProfilesToProcess(profileIds: string[]) {
    const requests: CreateProcessCandidateStatusRequest[] = profileIds.map((profileId) => ({
      processId: this.processId,
      memberId: profileId,
    }));
  
    this.talentService.createCandidateStatus(requests).subscribe(
      () => {
        this.showMessage('success', 'Success', 'Members added to the process');
        this.peopleAdded.emit();
        this.onClose();
      },
      () => {
        this.showMessage('error', 'Error', 'Error adding members to process.');
      }
    );
  }
  
  private showMessage(severity: string, summary: string, detail: string) {
    this.messageService.add({ severity, summary, detail });
  }

  onClose() {
    this.selectedProfiles = {};
    this.selectAll = false;
    this.closeModal.emit();
  }

  generateTooltip(profile: any): string {
    let tooltipText = '';
    profile.experience?.forEach((experience: { institutionName: string }) => {
      if (experience.institutionName && experience.institutionName.trim()) {
        if (tooltipText) {
          tooltipText += ', ';
        }
        tooltipText += experience.institutionName.trim();
      }
    });
    return tooltipText;
  }

  isValidInstitutionName(name: string | null): boolean {
    return name !== null && name?.trim().length > 0;
  }
}
