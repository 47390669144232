import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {LinkedinProfilesControllerService} from "../../oapi_client/data_symphony";
import {LinkedinProfileDto} from "../../oapi_client/data_symphony";
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import * as Sentry from '@sentry/browser';

@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss']
})
export class ProfileDetailsComponent implements OnInit {

  currentProfileID: string = '';
  currentJobID?: string;
  profiles$?: LinkedinProfileDto;
  constructor(private router: Router, private route: ActivatedRoute,
              private readonly linkedinProfileService:LinkedinProfilesControllerService, private config: DynamicDialogConfig) {
                this.currentProfileID = this.config.data.profileID;
                this.currentJobID = this.config.data.jobID;            

  }

  backToProfilesList() {
    this.router.navigate(['/profile-list', { jobID: this.currentJobID }]);
  }
  ngOnInit() {

    //this.currentProfileID = this.route.snapshot.paramMap.get('profileID')!;
    //this.currentJobID = this.route.snapshot.paramMap.get('jobID')!;
    //console.log(this.currentProfileID);
    this.getProfileDetails();//"ab5f93e4-e47e-475a-b485-137078211cee");//this.currentJobID

  }

  getProfileDetails() {
    if(this.currentProfileID)
    this.linkedinProfileService.getProfile(this.currentProfileID).subscribe((result: any)=>{
      this.profiles$ = result!.body;
    },
    error => {
      Sentry.captureException('System can not get profile details.', { tags: { customTag: 'Profile Details' }, level: 'error' });
    });
  }

}
