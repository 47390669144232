import { Component } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-outgoing-request-proposals-questions-dialog',
  templateUrl: './outgoing-request-proposals-questions-dialog.component.html',
  styleUrls: ['./outgoing-request-proposals-questions-dialog.component.scss']
})
export class OutgoingRequestProposalsQuestionsDialogComponent {

  proposals: any[]=[];

  constructor(
    public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    this.proposals = this.config.data.proposals;
  }
}
