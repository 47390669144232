import { Component, ViewChild, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { PaginationService } from 'src/app/helpers/pagination.service';
import {
  CreateLinkedinProfileFilterRequest,
  DSResponsePageLinkedinProfileDto,
  GroupControllerService,
  LinkedinProfileDto,
} from 'src/app/oapi_client/data_symphony';
import { saveAs } from 'file-saver';
import * as Papa from 'papaparse';
import { MessageService } from 'primeng/api';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { BreadcrumbService } from 'src/app/helpers/breadcrumb.service';
import { DialogService, DynamicDialogComponent } from 'primeng/dynamicdialog';
import { UpsertCandidateModalComponent } from '../candidate-pool/upsert-candidate-modal/upsert-candidate-modal.component';

@Component({
  selector: 'app-candidate-pool-member',
  templateUrl: './candidate-pool-member.component.html',
  styleUrls: ['./candidate-pool-member.component.scss'],
})
export class CandidatePoolMemberComponent implements OnInit {
  @ViewChild('dt') dataTable: Table | undefined;
  @ViewChild('confirmationModal') confirmationModal: ModalComponent | undefined;
  @ViewChild('memberModal') memberModal: ModalComponent | undefined;
  profiles$: LinkedinProfileDto[] = [];
  filter: CreateLinkedinProfileFilterRequest = {};
  groupName: string = '';
  keyword: string | null = null;
  description: string | null = null;
  selectedProfiles: { [pageIndex: number]: Set<string> } = {};
  selectAll: boolean = false;
  dropdownVisible: boolean = false;
  confirmationModalVisible: boolean = false;
  memberModalVisible: boolean = false;
  groupId: number = 0;
  isLoading: boolean = true;

  constructor(
    private readonly groupService: GroupControllerService,
    private route: ActivatedRoute,
    protected paginationService: PaginationService,
    private router: Router,
    private messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    private readonly dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.route.paramMap.subscribe((params) => {
      this.groupId = Number(params.get('id'));
      this.getGroupById(this.groupId);

      this.getMemberProfiles(
        this.groupId,
        0,
        this.paginationService.lazyProfileListPaginationState.rows || 10
      );
    });

    if (this.dataTable) {
      this.dataTable.first =
        this.paginationService.lazyProfileListPaginationState.first || 0;
      this.dataTable.rows =
        this.paginationService.lazyProfileListPaginationState.rows || 10;
      this.dataTable.totalRecords =
        this.paginationService.lazyProfileListPaginationState.totalRecords || 0;
    }
  }

  loadProfiles(event: TableLazyLoadEvent) {
    const pageIndex = Math.floor((event.first || 0) / (event.rows || 10));
    this.filter.pageIndex = pageIndex;
    const groupId = Number(this.route.snapshot.paramMap.get('id'));
    this.getMemberProfiles(groupId, pageIndex, event.rows || 10);

    this.selectAll = false;
  }

  getMemberProfiles(groupId: number, pageIndex: number, pageSize: number) {
    const requestBody: CreateLinkedinProfileFilterRequest = {
      ...this.filter,
      pageIndex: pageIndex,
      pageSize: pageSize,
    };

    this.groupService.listMember(groupId, requestBody).subscribe(
      (response: DSResponsePageLinkedinProfileDto) => {
        this.paginationService.lazyProfileListPaginationState.totalRecords =
          response.body?.totalElements || 0;
        if (this.dataTable) {
          this.dataTable.totalRecords =
            this.paginationService.lazyProfileListPaginationState.totalRecords;
        }
        this.profiles$ = response.body?.content || [];
        this.isLoading = false;
        if (!this.selectedProfiles[pageIndex]) {
          this.selectedProfiles[pageIndex] = new Set<string>();
        }

        this.selectAll =
          this.selectedProfiles[pageIndex].size === this.profiles$.length;
      },
      (error: any) => {
        console.error('Error fetching member list:', error);
        this.isLoading = false;
      }
    );
  }

  toggleSelect(profileId: any | undefined) {
    if (!profileId) return;
    const pageIndex = this.filter.pageIndex ?? 0;

    if (!this.selectedProfiles[pageIndex]) {
      this.selectedProfiles[pageIndex] = new Set<string>();
    }

    if (this.selectedProfiles[pageIndex].has(profileId)) {
      this.selectedProfiles[pageIndex].delete(profileId);
    } else {
      this.selectedProfiles[pageIndex].add(profileId.linkedinProfileLink!);
    }

    this.selectAll =
      this.selectedProfiles[pageIndex].size === this.profiles$.length;
  }

  toggleSelectAll(event: any) {
    const pageIndex = this.filter.pageIndex ?? 0;

    if (!this.selectedProfiles[pageIndex]) {
      this.selectedProfiles[pageIndex] = new Set<string>();
    }

    this.selectAll = event.target.checked;
    if (this.selectAll) {
      this.profiles$.forEach((profile) => {
        const profileId =
          profile.publicMemberId || profile.publicLinkedinMemberId;
        if (profileId) {
          this.selectedProfiles[pageIndex].add(profileId);
        }
      });
    } else {
      this.selectedProfiles[pageIndex].clear();
    }
  }

  applyFilters(filters: any) {
    this.filter = {
      ...this.filter,
      ...filters,
    };
    const groupId = Number(this.route.snapshot.paramMap.get('id'));
    this.getMemberProfiles(
      groupId,
      0,
      this.paginationService.lazyProfileListPaginationState.rows || 10
    );
  }

  clearFilters() {
    this.filter = {};
    const groupId = Number(this.route.snapshot.paramMap.get('id'));
    this.getMemberProfiles(
      groupId,
      0,
      this.paginationService.lazyProfileListPaginationState.rows || 10
    );
  }

  viewProfileDetails(selectedSearch: any) {
    this.paginationService.lazyProfileListPaginationState = {
      first: this.dataTable?.first,
      rows: this.dataTable?.rows,
      totalRecords: this.dataTable?.totalRecords || 0,
    };
    if (
      selectedSearch &&
      (selectedSearch.publicMemberId || selectedSearch.publicLinkedinMemberId)
    ) {
      const memberId =
        selectedSearch.publicMemberId || selectedSearch.publicLinkedinMemberId;
      this.breadcrumbService.setPersonName(selectedSearch.personInfo.name);

      this.router.navigate(['/profile', memberId], {
        queryParams: { source: 'candidate-member' },
      });
      localStorage.setItem('groupId', this.groupId.toString());
    }
  }

  navigateToTalentDiscovery() {
    this.router.navigate(['/talent-discovery'], {
      queryParams: { groupName: this.groupName, groupId: this.groupId },
    });
  }

  editCandidatePool(groupId: number) {
    const group = {
      id: groupId,
      name: this.groupName,
      description: this.description,
      keywordList: this.keyword,
    };
    const ref = this.dialogService.open(UpsertCandidateModalComponent, {
      data: group,
    });
    const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
    const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

    const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
    dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;

    ref.onClose.subscribe(() => {
      this.getGroupById(groupId);
    });

    return ref;
  }

  getGroupById(groupId: number) {
    this.groupService.getById4(groupId).subscribe((group) => {
      this.groupName = group.body!.name!;
      this.description = group.body!.description!;
      this.keyword = group.body!.keywordList!;
      this.breadcrumbService.setGroupName(this.groupName);
    });
  }

  exportToCSV(): void {
    const pageIndex = this.filter.pageIndex ?? 0;
  
    if (this.isNoSelection(pageIndex)) {
      this.showInfoMessage('Please select some members to perform this action!');
      return;
    }
  
    if (this.selectAll) {
      this.exportAllMembers();
    } else {
      this.exportSelectedMembers(pageIndex);
    }
  }
  
  private isNoSelection(pageIndex: number): boolean {
    const selectedProfileIds = Array.from(this.selectedProfiles[pageIndex] || []);
    return selectedProfileIds.length === 0;
  }
  
  private showInfoMessage(detail: string): void {
    this.messageService.add({
      severity: 'info',
      summary: 'Info',
      detail,
    });
  }
  
  private exportAllMembers(): void {
    this.filter.pageSize = this.dataTable?.totalRecords;
    this.groupService.listMember(this.groupId, this.filter).subscribe((res) => {
      const csvData = this.transformData(res.body?.content ?? []);
      this.downloadCSV(csvData, 'selected_group_members.csv');
      this.resetSelection();
    });
  }
  
  private exportSelectedMembers(pageIndex: number): void {
    const selectedProfileIds = Array.from(this.selectedProfiles[pageIndex] || []);
    const profilesToExport = this.profiles$.filter((profile) =>
      selectedProfileIds.includes(profile.linkedinProfileLink ?? '')
    );
  
    const csvData = this.transformData(profilesToExport);
    this.downloadCSV(csvData, 'selected_group_members.csv');
    this.resetSelection();
  }
  
  private downloadCSV(data: any[], filename: string): void {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: 'text/csv' });
    saveAs(blob, filename);
  }
  
  private resetSelection(): void {
    const pageIndex = this.filter.pageIndex ?? 0;
    this.selectedProfiles[pageIndex]?.clear();
    this.selectAll = false;
    this.toggleDropdown();
  }
  

  private transformData(data: any[]): any[] {
    return data.map((item) => {
      return {
        Name: item.personInfo?.name || '',
        Title: item.lastExperienceTitle || '',
        Experience: item.experienceCompany || '',
        Linkedin_profile_link: item.linkedinProfileLink || '',
      };
    });
  }

  toggleDropdown() {
    this.dropdownVisible = !this.dropdownVisible;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const clickedInsideDropdown = (event.target as HTMLElement).closest(
      '.dropdown'
    );
    if (!clickedInsideDropdown) {
      this.dropdownVisible = false;
    }
  }

  importFromCSV(event: any): void {
    this.toggleDropdown();
  }

  deleteGroup(groupId: number) {
    if (this.confirmationModal) {
      this.confirmationModal!.isVisible = true;
      this.confirmationModal.header =
        'Are you sure you want to delete this group?';
      this.confirmationModal.message = "Once you confirm you can't go back.";
      this.confirmationModal.acceptText = 'Yes';
      this.confirmationModal.cancelText = 'No';
      this.confirmationModal.isVisible = true;

      this.confirmationModal.accept.subscribe(() => {
        this.groupService.deleteGroup(groupId).subscribe((result) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Deleted Successfully',
            life: 3000,
          });
        });
        this.confirmationModal!.isVisible = false;
        this.router.navigate(['/candidate-pool']);
      });

      this.confirmationModal.cancel.subscribe(() => {
        this.confirmationModal!.isVisible = false;
      });
    }
  }

  public deleteGroupMember(profile: LinkedinProfileDto) {
    const profileId = profile.publicMemberId || profile.publicLinkedinMemberId;
    if (this.groupId && profileId) {
      if (this.memberModal) {
        this.memberModal!.isVisible = true;
        this.memberModal.header =
          'Are you sure you want to delete this member?';
        this.memberModal.message = "Once you confirm you can't go back.";
        this.memberModal.acceptText = 'Yes';
        this.memberModal.cancelText = 'No';
        this.memberModal.isVisible = true;

        this.memberModal.accept.subscribe(() => {
          this.groupService
            .deleteGroupMember(this.groupId, [profileId])
            .subscribe(
              (response) => {
                this.messageService.add({
                  severity: 'success',
                  summary: 'Success',
                  detail: 'Member deleted successfully',
                });
                this.getMemberProfiles(
                  this.groupId,
                  this.paginationService.lazyProfileListPaginationState.first ||
                    0,
                  this.paginationService.lazyProfileListPaginationState.rows ||
                    10
                );
              },
              (error) => {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'Error deleting member',
                });
              }
            );
          this.memberModal!.isVisible = false;
        });

        this.memberModal.cancel.subscribe(() => {
          this.memberModal!.isVisible = false;
        });
      }
    } else {
      console.warn('Invalid groupId or profileId.');
    }
  }

  generateTooltip(profile: any): string {
    let tooltipText = '';
    profile.experience?.forEach((experience: { institutionName: string }) => {
      if (experience.institutionName && experience.institutionName.trim()) {
        if (tooltipText) {
          tooltipText += ', ';
        }
        tooltipText += experience.institutionName.trim();
      }
    });
    return tooltipText;
  }
  isValidInstitutionName(name: string | null): boolean {
    return name !== null && name?.trim().length > 0;
  }

  splitKeywords(keywordList: string): string[] {
    return keywordList.split(',').filter((keyword) => keyword.trim() !== '');
  }
}
