import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../../../helpers/User';
import { AuthHelper } from '../../../helpers/auth.helper';
import { MenuItem } from 'primeng/api/menuitem';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit {
  currentUser$?: Observable<User>;
  items: MenuItem[] | undefined;
  roleLabel: string = '';
  constructor(private authHelper: AuthHelper) {}

  ngOnInit(): void {
    this.currentUser$ = this.authHelper.currentUser;
    let user: string = '';
    this.authHelper.currentUser.subscribe((usr) => {
      user = usr.userName;
    });
    this.items = [
      {
        label: 'Log Out',
        icon: 'pi pi-power-off',
        command: () => this.logout(),
      },
    ];
    this.updateRoleLabel();
  }

  logout(): void {
    this.authHelper.logout();
  }

  public getRolesFromLocalStorage(): string[] {
    const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    return currentUser.roles || [];
  }

  public setRoleLabel(roles: string[]): void {
    if (
      roles.some(
        (role) =>
          role === 'ROLE_EMPLOYER_ADMIN' || role === 'ROLE_HR_PROVIDER_ADMIN'
      )
    ) {
      this.roleLabel = 'Admin';
    } else {
      this.roleLabel = 'Member';
    }
  }

  public updateRoleLabel(): void {
    const roles = this.getRolesFromLocalStorage();
    this.setRoleLabel(roles);
  }
}
