<span class="floating-label">
    <input
        [id]="id"
        [type]="type"
        placeholder=" "
        [class]="class"
        [(ngModel)]="model"
        (keyup)="onKeyUp.emit($event)"
        (change)="onChange.emit($event)"
        (blur)="onBlur.emit($event)"
        (focus)="onFocus.emit($event)"
        [min]="type === 'number' ? min : null"
        [max]="type === 'number' ? max : null"
        (input)="onInput($event)">
    <label [for]="id">{{ label }}</label>
    <ng-content></ng-content>
</span>