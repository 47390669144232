<div class="layout-sidebar" [ngClass]="provider ? 'provider-bg': ''">
  <div class="icon-wrapper">
    <a class="logo" href="/dashboard">
      <img
        id="app-logo"
        [src]="provider ? './assets/images/hr-provider/hr-provider-logo.svg' : './assets/layout/images/logo.svg' "
        alt="DATABEATS LINQBASE"
        class="logo-image"
      />
    </a>
    <img
      [src]="provider ? './assets/images/hr-provider/hr-provider-menu-icon.svg' : './assets/images/menuIcon.svg'"
      alt="menu icon"
      class="menu-icon"
    />
  </div>
  <div class="layout-menu-container">
    <ul class="layout-menu">
      <li class="layout-root-menuitem" *ngFor="let root of items">
        <div class="layout-menuitem-root-text" [ngStyle]="{ 'color': provider ? 'white' : 'black' }">{{ root.label }}</div>
        <a pRipple tabindex="0">
          <!-- Use image instead of icon -->
          <img
            *ngIf="root.icon"
            [src]="root.icon"
            alt="{{ root.label }}"
            class="layout-menuitem-icon"
          />
          <span>{{ root.label }}</span>
          <i
            class="pi pi-fw layout-submenu-toggler"
            [class.opened]="root.items"
          ></i>
        </a>
        <ul role="menu" *ngIf="root.items">
          <li *ngFor="let item of root.items">
            <div [class.active-menuitem]="selectedRoute === item.url">
              <a
                routerLinkActive="active-route"
                pRipple
                class="active-route"
                tabindex="0"
                [routerLink]="item.url"
              >
                <img
                  *ngIf="item.icon"
                  [src]="item.icon"
                  alt="{{ item.label }}"
                  class="layout-menuitem-icon"
                  [ngClass]="provider ? 'provider-img': ''"
                />
                <span [ngClass]="provider ? 'provider-text': ''">{{ item.label }}</span>
              </a>
              <ul role="menu" *ngIf="item.items">
                <li *ngFor="let subitem of item.items">
                  <a
                    routerLinkActive="active-route"
                    pRipple
                    class="active-route"
                    tabindex="0"
                    [href]="subitem.url"
                  >
                    <img
                      *ngIf="subitem.icon"
                      [src]="subitem.icon"
                      alt="{{ subitem.label }}"
                      class="layout-menuitem-icon"
                    />
                    <span>{{ subitem.label }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
