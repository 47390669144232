<div *ngIf="isLoading">
  <app-loading-spinner></app-loading-spinner>
</div>

<div class="outgoing-requests" *ngIf="!isLoading">
  <div class="container-top">
    <div class="app-outgoing">
      <div>
        <p>Outgoing requests</p>
        <span>
          Here you’ll find the list of all the requests that you have
          prepared/sent out to other companies. You can edit them, remove them,
          see progress and add new ones.
        </span>
      </div>
      <div>
        <button
          class="create-request"
          *ngIf="processes.length !== 0"
          routerLink="/outgoing-request-type/"
        >
          Create New HR Request
        </button>
      </div>
    </div>
  </div>
  <div
    class="card"
    [ngStyle]="{ height: processes.length !== 0 ? '100vh' : 'auto' }"
  >
    <div class="search-bar mt-4" *ngIf="processes.length !== 0">
      <input
        class="search"
        placeholder="Search..."
        (input)="onSearchChange($event)"
      />
    </div>
    <div class="tabs" *ngIf="processes.length !== 0">
      <button
        [ngClass]="{'active': selectedTab === 'all'}"
        (click)="filterProcesses('all')"
      >
        View all
      </button>
      <button
        [ngClass]="{'active': selectedTab === 'CREATED'}"
        (click)="filterProcesses('CREATED')"
      >
        Created
      </button>
      <button
        [ngClass]="{'active': selectedTab === 'IN_PROGRESS'}"
        (click)="filterProcesses('IN_PROGRESS')"
      >
        In progress
      </button>
      <button
        [ngClass]="{'active': selectedTab === 'IN_REVIEW'}"
        (click)="filterProcesses('IN_REVIEW')"
      >
        In review
      </button>
      <button
        [ngClass]="{'active': selectedTab === 'SUCCEED'}"
        (click)="filterProcesses('SUCCEED')"
      >
        Done
      </button>
      <button
        [ngClass]="{'active': selectedTab === 'PENDING'}"
        (click)="filterProcesses('PENDING')"
      >
        Pending
      </button>
    </div>
    <div *ngIf="filteredProcesses.length === 0 && searchText" class="empty">
      <div><img src="../../assets/images/no-results.svg" alt="search" /></div>
      <h2>No results for your query!</h2>
      <p>Please try different keywords and filters.</p>
    </div>
    <div *ngIf="processes.length === 0" class="empty">
      <div><img src="../../../assets/images/folders.svg" alt="folder" /></div>
      <h2>No outgoing requests.</h2>
      <p>You haven‘t created any request. Press a button to do so.</p>
      <button
        class="create-request"
        routerLink="/outgoing-request-type/"
      >
        Create New HR Request
      </button>
    </div>
    <div class="small-card-container">
      <ng-container *ngFor="let process of filteredProcesses">
        <div class="card-info" (click)="navigateToDetails(process.id)">
          <div class="card-info-wrapper">
            <div class="card-info-left">
              <div class="card-name">
                <div class="overflow-text">
                  <h4>{{ process.requirement?.name }}</h4>
                  <span *ngIf="process.status !== 'CREATED'"
                    >Sent to
                    <strong>{{ getHrProviderNames(process) }}</strong></span
                  >
                </div>
              </div>
              <p
                class="status"
                [ngClass]="{
                'succeed': process.status === 'SUCCEED' || process?.status === 'IN_REVIEW',
                'pending': process.status === 'PENDING',
                'created': process.status === 'CREATED',
                'in-progress': process.status === 'IN_PROGRESS'
              }"
              >
                <ng-container
                  *ngIf="process.status === 'SUCCEED'; else otherStatus"
                >
                  done
                </ng-container>
                <ng-template
                  #otherStatus
                  >{{ process.status | titlecase }}</ng-template
                >
              </p>
            </div>
            <div class="card-info-right">
              <div class="price">
                <p *ngIf="process.budget === null">
                  Price: <strong>Not defined</strong>
                </p>
                <p *ngIf="process.budget !== null">
                  Price:
                  <strong
                    >{{ process.budget?.amount

                    }}&nbsp;{{process.budget?.type}}</strong
                  >
                </p>
                <span>{{ formatDate(process.createdAt) }}</span>
              </div>
              <hr />
              <div>
                <ng-container
                  *ngIf="process.status === 'PENDING' || process.status === 'CREATED'"
                >
                  <img
                    src="../../../assets/images/edit.svg"
                    alt="edit"
                    routerLink="/outgoing-requests-upsert/{{process.id}}"
    
                  />
                  <img
                    src="../../../assets/images/deleteIcon.svg"
                    alt="bin"
                    (click)="deleteProcess($event, process.id!, process.requirement?.name!)"
                  />
                </ng-container>
                <ng-container
                  *ngIf="process.status === 'IN_PROGRESS' || process.status === 'SUCCEED' || process?.status === 'IN_REVIEW'"
                >
                  <p class="text"><strong>View Process</strong></p>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="pagination">
      <p-paginator
        *ngIf="filteredProcesses.length > 0"
        [rows]="pageSize"
        [totalRecords]="totalRecords"
        (onPageChange)="onPageChange($event)"
      ></p-paginator>
    </div>
  </div>
</div>
