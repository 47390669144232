<div class="login-container">
  <div class="login-content">
    <p-toast></p-toast>
    <img src="../../../assets/images/purpleLogo.svg" />
    <div class="modal-wrapper">
      <div>
        <h3>Log Into Your Account</h3>
        <span class="info">Please enter the information to log in.</span>

        <form [formGroup]="form">
          <label for="txtEmail">Email</label>
          <input
            id="txtEmail"
            class="p-inputtext-lg rounded"
            formControlName="email"
            pInputText
            [ngClass]="displayFieldCss('email')"
            [(ngModel)]="email"
          />
          <label for="txtPassword">Password</label>
          <input
            id="txtPassword"
            class="p-inputtext-lg rounded"
            type="password"
            formControlName="password"
            pInputText
            [ngClass]="displayFieldCss('password')"
            [(ngModel)]="password"
          />

          <p><a href="#">Forgot password?</a></p>

          <button
            pButton
            pRipple
            type="submit"
            class="login-button"
            (click)="login()"
            label="Log in"
          ></button>
        </form>
        <p>
          Don't have an account?
          <a href="/register">Sign up.</a>
        </p>
      </div>
    </div>
  </div>
</div>
