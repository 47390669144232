import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ParameterService } from 'src/app/helpers/parameter.service';
import { DialogService, DynamicDialogComponent } from 'primeng/dynamicdialog';
import * as Sentry from '@sentry/browser';
import { PatternService } from 'src/app/helpers/pattern.service';
import {
  DSResponsePageLinkedinProfileDto,
  CreateLinkedinProfileFilterRequest,
  LinkedinProfileDto,
  LinkedinProfilesControllerService,
  DSResponseListGroupModelDto,
  GroupModelDto,
  GroupControllerService,
} from '../../oapi_client/data_symphony';
import { MenuItem, MessageService } from 'primeng/api';
import { UpsertCandidateModalComponent } from '../candidate-pool/upsert-candidate-modal/upsert-candidate-modal.component';
import { BreadcrumbService } from 'src/app/helpers/breadcrumb.service';

@Component({
  selector: 'app-profile-list',
  templateUrl: './profile-list.component.html',
  styleUrls: ['./profile-list.component.scss'],
  providers: [DatePipe],
})
export class ProfileListComponent implements OnInit {
  currentJobID?: string;
  keywordList?: string;
  countryList?: string;
  dateFormatted: string = '';
  memberId: string = '';
  items: MenuItem[] = [];
  @ViewChild('dt') dataTable: Table | undefined;
  profiles$: LinkedinProfileDto[] = [];
  filteredProfiles$: LinkedinProfileDto[] = [];
  filter: CreateLinkedinProfileFilterRequest = {};
  search?: string;
  groups$: GroupModelDto[] = [];
  dropdownVisible: boolean = false;
  selectedProfiles: { [pageIndex: number]: Set<string> } = {};
  selectAll: boolean = false;
  isLoading: boolean = true;

  constructor(
    private readonly linkedinProfileService: LinkedinProfilesControllerService,
    protected parameterService: ParameterService,
    private router: Router,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    protected paginationService: PaginationService,
    private dialogService: DialogService,
    public patternService: PatternService,
    private groupService: GroupControllerService,
    private messageService: MessageService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit() {
    this.isLoading = true;

    if (this.dataTable) {
      this.dataTable.first =
        this.paginationService.profileListPaginationState.first;
      this.dataTable.rows =
        this.paginationService.profileListPaginationState.rows;
    }
    
    this.currentJobID = this.route.snapshot.queryParamMap.get('jobID')!;
    this.keywordList = this.route.snapshot.queryParamMap.get('keywordList')!;
    this.countryList = this.route.snapshot.queryParamMap.get('countryList')!;

    this.filter = this.parameterService.onlineFilterState;
    this.filter.jobId = this.currentJobID;
    this.getOnlineProfiles(this.filter);
    this.items = [
      { label: 'Talent Hunt', url: '/talent-hunt' },
      { label: 'View Profiles', url: '' },
    ];

    this.getGroups();
    this.breadcrumbService.setGroupName(this.keywordList);
  }

  getGroups() {
    this.groupService.getAll2().subscribe(
      (response: DSResponseListGroupModelDto) => {
        this.groups$ = response.body || [];
        this.paginationService.lazyProfileListPaginationState.totalRecords = 0;
        if (this.dataTable) {
          this.dataTable.totalRecords =
            this.paginationService.lazyProfileListPaginationState.totalRecords;
        }
      },
      (error: any) => {
        alert(error);
      }
    );
  }

  getOnlineProfiles(filter: CreateLinkedinProfileFilterRequest) {
    if (!filter.sortBy) {
      delete filter.sortDirection;
    }
    this.linkedinProfileService.filter1(filter).subscribe(
      (response: DSResponsePageLinkedinProfileDto) => {
        this.paginationService.profileListPaginationState.totalRecords =
          response.body?.totalElements || 0;
        if (this.dataTable) {
          this.dataTable.totalRecords =
            this.paginationService.profileListPaginationState.totalRecords;
        }
        this.profiles$ = response.body?.content as LinkedinProfileDto[];
        this.isLoading = false;
        this.checkSelectAll();
      },
      (error: any) => {
        Sentry.captureException('System can not get the search list.', {
          tags: { customTag: 'Online Profile List' },
          level: 'error',
        });
        this.isLoading = false;
      }
    );
  }

  checkSelectAll() {
    const pageIndex = this.filter.pageIndex ?? 0;
    if (!this.selectedProfiles[pageIndex]) {
      this.selectedProfiles[pageIndex] = new Set<string>();
    }

    const selectedProfileIds = Array.from(this.selectedProfiles[pageIndex]);
    this.selectAll = selectedProfileIds?.length === this.profiles$?.length;
  }

  toggleSelect(profile: LinkedinProfileDto | undefined) {
    if (!profile) return;
    const profileId = profile.publicMemberId || profile.publicLinkedinMemberId;
    if (!profileId) return;

    const pageIndex = this.filter.pageIndex ?? 0;

    if (!this.selectedProfiles[pageIndex]) {
      this.selectedProfiles[pageIndex] = new Set<string>();
    }

    if (this.selectedProfiles[pageIndex].has(profileId)) {
      this.selectedProfiles[pageIndex].delete(profileId);
    } else {
      this.selectedProfiles[pageIndex].add(profileId);
    }

    this.checkSelectAll();
  }

  toggleSelectAll(event: any) {
    const pageIndex = this.filter.pageIndex ?? 0;

    if (!this.selectedProfiles[pageIndex]) {
      this.selectedProfiles[pageIndex] = new Set<string>();
    }

    this.selectAll = event.target.checked;
    if (this.selectAll) {
      this.profiles$.forEach((profile) => {
        const profileId =
          profile.publicMemberId || profile.publicLinkedinMemberId;
        if (profileId) {
          this.selectedProfiles[pageIndex].add(profileId);
        }
      });
    } else {
      this.selectedProfiles[pageIndex].clear();
    }
  }

  addMembersToGroup(groupId: any) {
    if (groupId) {
      const selectedProfilesArray: string[] = [];
      Object.keys(this.selectedProfiles).forEach((pageIndex) => {
        const pageIndexNumber = parseInt(pageIndex);
        if (!isNaN(pageIndexNumber)) {
          const profilesSet = this.selectedProfiles[pageIndexNumber];
          profilesSet.forEach((profileId) => {
            selectedProfilesArray.push(profileId);
          });
        }
      });

      if (selectedProfilesArray.length > 0) {
        this.groupService.addMember(groupId, selectedProfilesArray).subscribe(
          () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Members added to the group',
            });
          },
          () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Error adding members to group.',
            });
          }
        );
      } else {
        this.messageService.add({
          severity: 'info',
          summary: 'Info',
          detail: 'No profiles selected.',
        });
      }
    } else {
      console.warn('Invalid groupId.');
    }
    this.toggleDropdown();
  }

  toggleDropdown() {
    if (this.groups$.length === 0) {
      const ref = this.dialogService.open(UpsertCandidateModalComponent, {});
      const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
      const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;
      const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
      dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
      return ref;
    } else {
      this.dropdownVisible = !this.dropdownVisible;
      return;
    }
  }

  openDialog() {
    const ref = this.dialogService.open(UpsertCandidateModalComponent, {});
    const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
    const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

    const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
    dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
    return ref;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const clickedInsideDropdown = (event.target as HTMLElement).closest(
      '.dropdown'
    );
    if (!clickedInsideDropdown) {
      this.dropdownVisible = false;
    }
  }

  viewProfileDetails(selectedSearch: any) {
    if (
      selectedSearch &&
      (selectedSearch.publicMemberId || selectedSearch.publicLinkedinMemberId)
    ) {
      const memberId =
        selectedSearch.publicMemberId || selectedSearch.publicLinkedinMemberId;
      this.router.navigate(['/profile', memberId], {
        queryParams: { source: 'talent-hunt' },
      });
    }
  }

  backToSearches() {
    this.router.navigateByUrl('/talent-hunt');
  }

  formatDateString(dateString: string): string {
    try {
      this.dateFormatted = <string>(
        this.datePipe.transform(dateString, 'dd/MM/yyyy HH:mm')
      );
    } catch (ex: any) {
      this.dateFormatted = dateString;
      Sentry.captureException(
        'System can not format the date:' + this.dateFormatted,
        { tags: { customTag: 'Online Profile List' }, level: 'error' }
      );
    }
    return this.dateFormatted;
  }

  loadProfiles(event: TableLazyLoadEvent) {
    setTimeout(() => {
      if (event?.rows) {
        this.filter.pageIndex = (event?.first || 0) / event?.rows;
        this.getOnlineProfiles(this.filter);
      }
    }, 10);
  }

  createCandidatePool() {
    const ref = this.dialogService.open(UpsertCandidateModalComponent, {});
    const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
    const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

    const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
    dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
    return ref;
  }

  flattenExperiences(profile: LinkedinProfileDto): string | undefined {
    return profile.experience?.map((exp) => exp?.institutionName).join('\n ');
  }

  eliminateDuplicates(experiences: any[]): any[] {
    const uniqueExperiences = new Set();
    if (experiences == null) return [];
    return experiences.filter((experience) => {
      if (!uniqueExperiences.has(experience.institutionName)) {
        uniqueExperiences.add(experience.institutionName);
        return true;
      }
      return false;
    });
  }

  customGlobalFilter(filter: String) {
    const filterValue = filter.toLowerCase();
    this.filteredProfiles$ = this.profiles$.filter((profile) => {
      const flattenedExperiences = this.flattenExperiences(profile); // Implement your flattenExperiences function
      return (
        (profile.personInfo?.name?.toLowerCase() || '').includes(filterValue) ||
        (profile.personInfo?.headline?.toLowerCase() || '').includes(
          filterValue
        ) ||
        (flattenedExperiences?.toLowerCase() || '').includes(filterValue)
      );
    });
  }

  formatKeywordList(): string {
    if (!this.keywordList || this.keywordList === 'undefined') return '';
    return this.keywordList
      .split(',')
      .map((keyword) => keyword.trim())
      .join(', ');
  }

  applyFilters(filters: any) {
    const { sortBy, sortDirection, ...restFilters } = filters;

    this.filter = {
      ...this.filter,
      ...restFilters,
    };

    if (sortBy) {
      this.filter.sortBy = sortBy;
      this.filter.sortDirection = sortDirection;
    } else {
      delete this.filter.sortDirection;
    }
    this.getOnlineProfiles(this.filter);
  }

  clearFilters() {
    this.filter = {};
    this.getOnlineProfiles(this.filter);
  }

  generateTooltip(profile: any): string {
    let tooltipText = '';
    profile.experience?.forEach((experience: { institutionName: string }) => {
      if (experience.institutionName && experience.institutionName.trim()) {
        if (tooltipText) {
          tooltipText += ', ';
        }
        tooltipText += experience.institutionName.trim();
      }
    });
    return tooltipText;
  }

  isValidInstitutionName(name: string | null): boolean {
    return name !== null && name?.trim().length > 0;
  }
}
