<div *ngIf="isLoading">
  <app-loading-spinner></app-loading-spinner>
</div>

<div class="modal-header-container" *ngIf="!isLoading">
  <p class="modal-title">Add from Talent discovery</p>
  <span class="modal-description"
    >You can add complete groups or you can choose individual people.</span
  >
</div>

<div class="table-container" *ngIf="!isLoading">
  <div class="talent-wrapper">
    <p-table
      #dt
      [value]="profiles$"
      [lazy]="true"
      (onLazyLoad)="loadProfiles($event)"
      [rows]="rows"
      [first]="first"
      [paginator]="filtersApplied"
      [rowHover]="true"
      dataKey="externalId"
      [paginatorPosition]="'bottom'"
      class="table"
      *ngIf="!isLoading"
    >
      <ng-template pTemplate="header" class="header">
        <tr>
          <th colspan="5">
            <div class="button-wrapper">
              <div style="display: flex">
                <th style="display: flex; align-items: center">
                  <label class="custom-checkbox-label" *ngIf="filtersApplied">
                    <input
                      type="checkbox"
                      [checked]="selectAll"
                      (change)="toggleSelectAll($event)"
                    />
                    <span class="custom-checkbox-icon"></span>
                  </label>
                  <span style="padding-left: 5px;" *ngIf="filtersApplied">
                    Select All
                    <span *ngIf="selectAll" class="records">
                      ({{totalRecords}})
                    </span>
                  </span>
                  <div class="filters">
                    <app-experience-location-modal
                      (filterApplied)="applyFilters($event)"
                    ></app-experience-location-modal>
                    <app-industry-education-modal
                      (filterApplied)="applyFilters($event)"
                    ></app-industry-education-modal>
                  </div>
                </th>
              </div>
            </div>
          </th>
        </tr>
        <tr class="header-row" *ngIf="filtersApplied">
          <td colspan="5">
            <div class="header-content">
              <th
                pColumn="profile.person_info.name"
                style="width: 25.3%; margin-left: 25px;"
              >
                Name
              </th>
              <th pColumn="person_info.headline" style="width: 21.3%;">
                Title
              </th>
              <th pColumn="person_info.experience" style="width: 39.1%">
                Experiences
              </th>

              <th pColumn="person_info.location" style="width: 10.8%;">
                Location
              </th>
            </div>
          </td>
        </tr>
      </ng-template>

      <ng-template
        pTemplate="body"
        let-profile
        let-expanded="expanded"
        *ngIf="filtersApplied"
      >
        <tr>
          <td style="padding-left: 1rem; width: 26.5%;">
            <div class="name">
              <label class="custom-checkbox-label">
                <input
                  type="checkbox"
                  [checked]="selectedProfiles[filter.pageIndex ?? 0]?.has(profile.publicMemberId || profile.publicLinkedinMemberId)"
                  (change)="toggleSelect(profile)"
                  class="custom-checkbox"
                />
                <span class="custom-checkbox-icon"></span>
              </label>

              <span
                class="ellipsis-description"
                [pTooltip]="profile.personInfo.name"
                tooltipPosition="bottom"
                style="padding-left: 5px; width: 172px;"
              >
                {{ profile.personInfo.name }}
              </span>
            </div>
          </td>
          <td style="width: 21%;">
            <div
              [pTooltip]="profile?.personInfo?.headline"
              tooltipPosition="bottom"
            >
              <div class="ellipsis-description">
                {{ profile?.lastExperienceTitle }}
              </div>
            </div>
          </td>
          <td style="width: 39%">
            <div [pTooltip]="generateTooltip(profile)" tooltipPosition="bottom">
              <div class="ellipsis-description" style="width: 273px">
                <ng-container
                  *ngFor="let experience of profile?.experienceCompany; let last = last"
                >
                  <span *ngIf="!last && isValidInstitutionName(experience)"
                    >{{ experience }},
                  </span>
                  <span *ngIf="last">{{ experience }}</span>
                </ng-container>
              </div>
            </div>
          </td>
          <td style="width: 12.8%;">
            <div class="ellipsis-description">
              {{ profile.personInfo?.location }}
            </div>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="paginatorright" *ngIf="profiles$.length !== 0">
        <span class="records">
          Showing {{ first + 1 }} to {{ first + profiles$!.length }} of
          {{ totalRecords }}
        </span>
      </ng-template>
    </p-table>
  </div>
  <div class="empty-page" *ngIf="!filtersApplied">
    <p>To see the results, you need to enter the appropriate filters.</p>
  </div>
  <div class="button-container">
    <button class="button-transparent" (click)="onClose()">Cancel</button>
    <button class="button-purple" (click)="addTalentsToProcessStep()">
      Add
    </button>
  </div>
</div>
