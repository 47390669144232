<div class="modal-overlay" (click)="closeModal()">
  <div class="modal-content" (click)="$event.stopPropagation()">
    <div class="modal-header">
      <div>
        <h2>Countries</h2>
        <p>Full list</p>
      </div>
      <button (click)="closeModal()">X</button>
    </div>
  
    <table>
      <thead>
        <tr>
          <th></th>
          <th>0-2</th>
          <th>2-5</th>
          <th>5-8</th>
          <th>8-10</th>
          <th>10+</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let country of countries">
          <td>{{country.name}}</td>
          <td>
            <img src="../../../assets/images/people.svg" alt="person icon" />
            {{country.experience['0-2']}}
          </td>
          <td>
            <img src="../../../assets/images/people.svg" alt="person icon" />
            {{country.experience['2-5']}}
          </td>
          <td>
            <img src="../../../assets/images/people.svg" alt="person icon" />
            {{country.experience['5-8']}}
          </td>
          <td>
            <img src="../../../assets/images/people.svg" alt="person icon" />
            {{country.experience['8-10']}}
          </td>
          <td>
            <img src="../../../assets/images/people.svg" alt="person icon" />
            {{country.experience['10+']}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
