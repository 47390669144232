<div class="container">
  <div class="title">
    <h4>{{modalTitle}}</h4>
    <span class="description">Please enter the information</span>
  </div>
  <form [formGroup]="form">
    <div class="content">
      <div class="form">
        <label>Name</label>
        <input
          class="input"
          type="text"
          placeholder="Name"
          formControlName="name"
          [ngClass]="getClassObject('name')"
        />
      </div>
      <div class="form">
        <label>Keywords</label>
        <p-chips
          placeholder="Type your keywords and press enter"
          formControlName="values"
          (onRemove)="onValueRemoved($event)"
          (onAdd)="onValueAdded($event)"
          id="chipsInput"
        >
          <ng-template let-item pTemplate="item">
            <span class="chip-item">{{ item }}</span>
            <img
              src="../../../assets/images/closeIconKeyword.svg"
              alt="remove"
              class="remove-icon"
              (click)="removeKeyword(item)"
            /> </ng-template
        ></p-chips>
      </div>
      <div class="form">
        <label>Description</label>
        <div class="container-icons">
          <div class="icons-p">
            <span class="limit">{{characterCount}}/3000</span>
          </div>
        </div>
        <textarea
          placeholder="Enter message here..."
          maxlength="3000"
          (ngModelChange)="incrementCount()"
          formControlName="description"
          [ngClass]="getClassObject('description')"
        ></textarea>
      </div>
      <div class="paraph">
        <p>
          Once you have created the Group, you can proceed with adding
          connections, by clicking the button Connections list.
        </p>
      </div>
    </div>

    <div class="mt-4">
      <div class="button-class">
        <div class="position-right">
          <button
            type="submit"
            label="Save"
            class="p-button-save"
            iconPos="left"
            (click)="onSubmit()"
            [disabled]="!form.valid"
          >
            {{button}}
          </button>
        </div>
        <div>
          <button
            type="button"
            label="Cancel"
            class="p-button-cancel"
            iconPos="left"
            (click)="closeDialog()"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
