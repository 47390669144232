/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { CreateHRCompanyFilterRequest } from '../models/CreateHRCompanyFilterRequest';
import type { CreateStarRequest } from '../models/CreateStarRequest';
import type { DSResponseCompanyDto } from '../models/DSResponseCompanyDto';
import type { DSResponseHRProviderOverviewDto } from '../models/DSResponseHRProviderOverviewDto';
import type { DSResponseListCompanyDto } from '../models/DSResponseListCompanyDto';
import type { DSResponseListMiniHRProviderOverviewDto } from '../models/DSResponseListMiniHRProviderOverviewDto';
import type { DSResponseListOutgoingProcessDto } from '../models/DSResponseListOutgoingProcessDto';
import type { DSResponseListReceivedProcessDto } from '../models/DSResponseListReceivedProcessDto';
import type { DSResponseStarDto } from '../models/DSResponseStarDto';
import type { DSResponseString } from '../models/DSResponseString';
import type { UpdateCompanyRequest } from '../models/UpdateCompanyRequest';
import type { UpdateStarRequest } from '../models/UpdateStarRequest';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class CompanyControllerService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @param id
     * @param requestBody
     * @returns DSResponseString OK
     * @throws ApiError
     */
    public updateCompany(
        id: number,
        requestBody: UpdateCompanyRequest,
    ): Observable<DSResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v1/processManagement/company/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns DSResponseStarDto OK
     * @throws ApiError
     */
    public updateStar(
        id: number,
        requestBody: UpdateStarRequest,
    ): Observable<DSResponseStarDto> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v1/processManagement/company/star/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns DSResponseString OK
     * @throws ApiError
     */
    public deleteStar(
        id: number,
    ): Observable<DSResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/v1/processManagement/company/star/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param requestBody
     * @returns DSResponseStarDto OK
     * @throws ApiError
     */
    public createStar(
        requestBody: CreateStarRequest,
    ): Observable<DSResponseStarDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/processManagement/company/star',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns DSResponseListMiniHRProviderOverviewDto OK
     * @throws ApiError
     */
    public filter(
        requestBody: CreateHRCompanyFilterRequest,
    ): Observable<DSResponseListMiniHRProviderOverviewDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/processManagement/company/filter',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param type
     * @returns DSResponseListCompanyDto OK
     * @throws ApiError
     */
    public getCompanyList(
        type: 'EMPLOYER' | 'HR_PROVIDER',
    ): Observable<DSResponseListCompanyDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/processManagement/company/{type}',
            path: {
                'type': type,
            },
        });
    }
    /**
     * @param id
     * @returns DSResponseHRProviderOverviewDto OK
     * @throws ApiError
     */
    public getHrProviderOverviewById(
        id: number,
    ): Observable<DSResponseHRProviderOverviewDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/processManagement/company/hrProviderOverview/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @returns DSResponseListReceivedProcessDto OK
     * @throws ApiError
     */
    public getReceivedRequest(): Observable<DSResponseListReceivedProcessDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/processManagement/company/getReceivedRequest',
        });
    }
    /**
     * @returns DSResponseListOutgoingProcessDto OK
     * @throws ApiError
     */
    public getOutgoingRequest(): Observable<DSResponseListOutgoingProcessDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/processManagement/company/getOutgoingRequest',
        });
    }
    /**
     * @returns DSResponseCompanyDto OK
     * @throws ApiError
     */
    public getMyCompany(): Observable<DSResponseCompanyDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/processManagement/company/getMyCompany',
        });
    }
    /**
     * @param id
     * @returns DSResponseCompanyDto OK
     * @throws ApiError
     */
    public getById2(
        id: number,
    ): Observable<DSResponseCompanyDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/processManagement/company/getById/{id}',
            path: {
                'id': id,
            },
        });
    }
}
