import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserControllerService } from '../oapi_client/data_symphony';

export const roleGuard = () => {
  const authService = inject(UserControllerService);
  const router = inject(Router);

  const userRoles = authService.getUserRoles();
  userRoles.subscribe((roles) => {
    if (roles.body!.includes('ROLE_HR_PROVIDER_ADMIN') || (roles.body!.includes('ROLE_HR_PROVIDER_MEMBER') && roles.body!.includes('PROCESS_MANAGEMENT'))) {
      return true;
    } else {
      router.navigate(['/dashboard']);
      return false;
    }
  });
};
