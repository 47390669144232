import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function forbiddenEmailDomainValidator(
  forbiddenDomain: string
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const email = control.value;
    if (email && email.includes('@')) {
      const domain = email.split('@')[1];
      if (domain.toLowerCase() === forbiddenDomain.toLowerCase()) {
        return { forbiddenDomain: true };
      }
    }
    return null;
  };
}
