<div class="p-3">
    <h4>Questions</h4>
    <p>Review the questions asked from HR providers and answer them.</p>
    <div *ngFor="let proposal of proposals">
        <div class="question-card" *ngIf="proposal.questionAnswers.length > 0">
            <div style="display: flex;" class="mb-2">
                <img src="{{proposal.hrRProviderDto.logoUrl}}" class="image">
                <span>{{proposal.hrRProviderDto.name}}</span>
            </div>
            <div *ngFor="let pqa of proposal.questionAnswers, let i = index" >
                <h6 class="mt-1 mb-0">{{pqa.questionAnswer}}</h6>
                <input class="input"  placeholder="Type your answer" *ngIf="proposal.questionAnswers.length === i - 1 && 
                proposal.questionAnswers.at(-1).authorCompanyType == 'HR_PROVIDER'"/>
            </div>
        </div>
    </div>
</div>