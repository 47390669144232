<div *ngIf="isLoading">
  <app-loading-spinner></app-loading-spinner>
</div>

<div class="container-top" *ngIf="!isLoading">
  <div class="user-info">
    <div>
      <p>Candidate Pool</p>
      <span>
        Here you’ll find the list of all the candidate pools you have created.
      </span>
    </div>

    <button class="custom-button" (click)="createCandidatePool()">
      Create New Candidate Pool
    </button>
  </div>
</div>

<div class="table-container">
  <div>
    <div class="analytics">
      <img src="../../../assets/images/candidates.svg" alt="candidates icon" />
      <div>
        <span>Candidates</span>
        <p>{{ totalMemberCount }}</p>
      </div>
      <hr />
      <img src="../../../assets/images/folder.svg" alt="folder icon" />
      <div>
        <span>Pools</span>
        <p>{{ numberOfPools }}</p>
      </div>
    </div>

    <div class="card talent-wrapper">
      <div *ngIf="!isLoading && orgGroups$.length ===0 && searchText === ''" class="user-info empty">
        <img
          src="../../../assets/images/hr-provider/hr-provider-candidate-pools.svg"
        />
        <h3>No candidate pools</h3>
        <p>You haven't created any candidate pools yet.</p>
        <button class="custom-button" (click)="createCandidatePool()">
          Create New Candidate Pool
        </button>
      </div>
      <div *ngIf="orgGroups$.length > 0">
        <div class="col-12 filter-wrapper">
          <div class="col-5">
            <input
              class="search-input"
              pInputText
              placeholder="Search..."
              (keyup)="searchPools()"
              [(ngModel)]="searchText"
            />
          </div>
        </div>
        <p-table
          #dt
          [value]="groups$"
          [rows]="paginationService.searchListPaginationState.rows"
          [first]="paginationService.searchListPaginationState.first"
          [paginator]="true"
          [globalFilterFields]="['name']"
          [rowHover]="true"
          dataKey="externalId"
          [showCurrentPageReport]="true"
        >
          <ng-template pTemplate="header" class="header">
            <tr class="header-row">
              <td colspan="7">
                <div class="header-content">
                  <th style="width: 3.5%"></th>
                  <th pColumn="group.name" style="width: 18.1%;">Candidate Pool</th>
                  <th pColumn="group.name" style="width: 18.3%;">Created by</th>
                  <th pColumn="group.description" style="width: 19.5%">
                    Description
                  </th>
                  <th pColumn="group.keywordList" style="width: 26%">Keywords</th>
  
                  <th pColumn="group.memberCount" style="width: 9.5%">Profile</th>
                  <th>Actions</th>
                </div>
              </td>
            </tr>
          </ng-template>
  
          <ng-template pTemplate="body" let-group let-expanded="expanded">
            <tr>
              <td style="width: 5%">
                <div class="wrapper" style="padding-left: 1rem;  width: 43px;">
                  <button
                    (click)="group.expanded = !group.expanded"
                    class="expand-button"
                  >
                    <img
                      src="../../../assets/images/arrowDown.svg"
                      class="arrow"
                      [ngClass]="{'arrow-up': group.expanded}"
                    />
                  </button>
                </div>
              </td>
  
              <td (click)="viewProfileDetails(group.id)" style="width: 20.6%">
                <div
                  class="wrapper"
                  style="min-width: 204px;"
                  [pTooltip]="group?.name"
                  tooltipPosition="bottom"
                >
                  <div
                    [ngClass]="{'ellipsis-description': !group.expanded}"
                    style="max-width: 172px; "
                  >
                    {{ group.name }}
                  </div>
                </div>
              </td>
              <td (click)="viewProfileDetails(group.id)" style="width: 20.6%">
                <div
                  class="wrapper"
                  style="min-width: 204px;"
                  [pTooltip]="group?.name"
                  tooltipPosition="bottom"
                >
                  <div
                    [ngClass]="{'ellipsis-description': !group.expanded}"
                    style="max-width: 172px; "
                  >
                    {{group.createdByUser}}
                  </div>
                </div>
              </td>
              <td (click)="viewProfileDetails(group.id)" style="width: 212px">
                <div
                  class="wrapper"
                  [pTooltip]="group?.description"
                  tooltipPosition="bottom"
                  style=" width: 212px"
                >
                  <div
                    [ngClass]="{'ellipsis-description': !group.expanded}"
                    style="max-width: 400px;"
                  >
                    {{ group.description }}
                  </div>
                </div>
              </td>
  
              <td (click)="viewProfileDetails(group.id)" style="width: 27.5%">
                <div
                  class="wrapper wrapper-tags"
                  [pTooltip]="group?.keywordList"
                  tooltipPosition="bottom"
                  style="width: 310px;"
                >
                  <ng-container *ngIf="!group.expanded">
                    <ng-container
                      *ngFor="let keyword of getTruncatedKeywords(group?.keywordList)"
                    >
                      <span class="tag">{{ keyword }}</span>
                    </ng-container>
  
                    <span *ngIf="hasEllipsis">...</span>
                  </ng-container>
  
                  <ng-container *ngIf="group.expanded">
                    <div class="wrapper-expanded">
                      <ng-container
                        *ngFor="let keyword of group?.keywordList.split(',')"
                      >
                        <span class="tag">{{ keyword.trim() }}</span>
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
              </td>
  
              <td (click)="viewProfileDetails(group.id)" style="width: 31%">
                <div class="wrapper" style="min-width: 95px;">
                  {{ group.memberCount }}
                </div>
              </td>
  
              <td>
                <div class="flex wrapper">
                  <button
                    class="image-button mr-2"
                    pTooltip="Edit"
                    tooltipPosition="bottom"
                    (click)="editCandidatePool(group.id)"
                  >
                    <img src="../../../assets/images/edit.svg" alt="Edit Image" />
                  </button>
  
                  <button class="image-button mr-2" pTooltip="Delete"  tooltipPosition="bottom">
                    <img
                      src="../../../assets/images/deleteIcon.svg"
                      alt="Delete Image"
                      (click)="deleteGroup(group.id)"
                    />
                  </button>
                </div>
              </td>
            </tr>
            <div *ngIf="groups$.length ===0 && searchText !== ''" class="user-info empty">
              searchhh
            </div> 
          </ng-template>
        </p-table>
        <div *ngIf="groups$?.length === 0  && searchText.length > 0 ">
          <div class="text-center font-bold">No results found for "{{ searchText }}". Please try a different query.
          </div>
        </div>
      </div>
     
    </div>
  </div>
</div>
<app-modal
  #confirmationModal
  [isVisible]="confirmationModalVisible"
></app-modal>
