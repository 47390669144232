import { AfterViewChecked, Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { RegisterControllerService, ManageUserControllerService, UserControllerService, CreateInviteUserRequest, MiniUserDto } from 'src/app/oapi_client/data_symphony';

type Privileges = {
  talentAcquisition: boolean;
  talentHuntDiscovery: boolean;
  candidatePools: boolean;
  other: boolean;
  processManagement: boolean;
  teamManagement: boolean;
  jobAdvertisement: boolean;
};


@Component({
  selector: 'app-team-managament-privileges-modal',
  templateUrl: './team-managament-privileges-modal.component.html',
  styleUrls: ['./team-managament-privileges-modal.component.scss']
})

export class TeamManagamentPrivilegesModalComponent{
  constructor(
    private registerService: RegisterControllerService,
    private manageUserService: ManageUserControllerService,
    private userService: UserControllerService,
    private messageService: MessageService,
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
  ) {
    this.user = this.config.data.user
   }

  display: boolean = false;
  emails: string[] = [];
  invitedUsers: any[] = [];
  user: MiniUserDto | undefined;

  privileges: Privileges = {
    talentAcquisition: false,
    talentHuntDiscovery: false,
    candidatePools: false,
    other: false,
    processManagement: false,
    teamManagement: false,
    jobAdvertisement: false,
  };

  ngOnInit(): void {
    this.setPrivilegesBasedOnRoles();
  }

  setPrivilegesBasedOnRoles() {
    const roleToPrivilegeMap: { [key: string]: keyof Privileges } = {
      "CANDIDATE_POOLS": "candidatePools",
      "JOB_ADVERTISEMENT_MANAGEMENT": "jobAdvertisement",
      "PROCESS_MANAGEMENT": "processManagement",
      "TEAM_MANAGEMENT": "teamManagement",
      "TALENT_HUNT_&_TALENT_DISCOVERY": "talentHuntDiscovery",
    };

    this.user!.roles.forEach((role: string) => {
      const privilegeKey = roleToPrivilegeMap[role];

      if (privilegeKey) {
        this.privileges[privilegeKey] = true;
      }
    });

    this.user!.roles.map(user=>{
      if(user.includes('TALENT_HUNT_&_TALENT_DISCOVERY' &&  "CANDIDATE_POOLS")){
        this.privileges.talentAcquisition = true;
      }
      else if (user.includes("JOB_ADVERTISEMENT_MANAGEMENT" && "PROCESS_MANAGEMENT" && "JOB_ADVERTISEMENT_MANAGEMENT")){
        this.privileges.other = true;
      }
    })
  }

  showTooltip: boolean = false;

  privilegeLabels = {
    talentAcquisition: 'Talent acquisition',
    talentHuntDiscovery: 'Talent hunt & Talent discovery',
    candidatePools: 'Candidate pools',
    other: 'Other',
    processManagement: 'Process management',
    teamManagement: 'Team management',
    jobAdvertisement: 'Job advertisement management',
  };

  validateEmail(event: any) {
    const email = event.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      this.emails = this.emails.filter((e) => e !== email);
      alert('Invalid email format.');
    }
  }

  removeEmail(event: any) {
    this.emails = this.emails.filter((email) => email !== event.value);
  }

  updatePrivileges() {
    if (this.privileges.processManagement) {
      this.privileges.talentAcquisition = true;
      this.privileges.talentHuntDiscovery = true;
      this.privileges.candidatePools = true;
    } else {
      this.privileges.talentAcquisition = false;
      this.privileges.talentHuntDiscovery = false;
      this.privileges.candidatePools = false;
    }
  }

  selectTalentAcquisition() {
    if (this.privileges.talentAcquisition) {
      this.privileges.talentHuntDiscovery = true;
      this.privileges.candidatePools = true;
    } else {
      this.privileges.talentHuntDiscovery = false;
      this.privileges.candidatePools = false;
    }
  }

  selectChildPrivileges() {
    if (this.privileges.talentHuntDiscovery && this.privileges.candidatePools) {
      this.privileges.talentAcquisition = true;
    } else {
      this.privileges.talentAcquisition = false;
    }
  }

  selectOther() {
    if (this.privileges.other) {
      this.privileges.processManagement = true;
      this.privileges.teamManagement = true;
      this.privileges.jobAdvertisement = true;
    } else {
      this.privileges.processManagement = false;
      this.privileges.teamManagement = false;
      this.privileges.jobAdvertisement = false;
    }
  }

  saveChanges(){
    this.manageUserService.updatePrivileges(this.user!.id, this.getRolesBasedOnPrivileges()).subscribe({
    next:() =>{
      this.ref.close();
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: `You have successfully updated ${this.user?.email} privileges.`,
        life: 3000,
      });
    },
    error:() =>{
      this.ref.close();
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: `Privileges for ${this.user?.email} couldn't be updated`,
        life: 3000,
      });
    }
  })
    
  }

  private getRolesBasedOnPrivileges(): string[] {
    const roles: string[] = [];

    if (this.privileges.talentAcquisition) {
      roles.push('TALENT_HUNT_&_TALENT_DISCOVERY', 'CANDIDATE_POOLS');
    }
    if (
      this.privileges.talentHuntDiscovery &&
      !this.privileges.talentAcquisition
    ) {
      roles.push('TALENT_HUNT_&_TALENT_DISCOVERY');
    }
    if (this.privileges.candidatePools && !this.privileges.talentAcquisition) {
      roles.push('CANDIDATE_POOLS');
    }

    if (this.privileges.processManagement) {
      roles.push('PROCESS_MANAGEMENT');
    }
    if (this.privileges.teamManagement) {
      roles.push('TEAM_MANAGEMENT');
    }
    if (this.privileges.jobAdvertisement) {
      roles.push('JOB_ADVERTISEMENT_MANAGEMENT');
    }

    return roles;
  }

  resetPrivileges() {
    this.privileges = {
      talentAcquisition: false,
      talentHuntDiscovery: false,
      candidatePools: false,
      other: false,
      processManagement: false,
      teamManagement: false,
      jobAdvertisement: false,
    };
  }

  isInviteButtonDisabled(): boolean {
    return (
      this.emails.length === 0 ||
      (!this.privileges.talentAcquisition &&
        !this.privileges.talentHuntDiscovery &&
        !this.privileges.candidatePools &&
        !this.privileges.processManagement &&
        !this.privileges.teamManagement &&
        !this.privileges.jobAdvertisement)
    );
  }

  resetData() {
    this.emails = [];
    this.invitedUsers = [];
    this.resetPrivileges();
  }
}

