<div class="card-provider" (click)="!isRequestSent && selectProvider()" [ngClass]="{'sent-request': isRequestSent}"
  [ngStyle]="select ? {'border-color': '#4942E5'} : {}">
  <div class="provider">
    <div class="cologn">
      <div class="image">
        <img [src]="provider?.logoUrl" (error)="onImageError($event)" alt="Logo" />
      </div>
      <div class="provider-information ml-3">
        <div class="provider-info">
          {{provider.name}}
          <span *ngIf="isRequestSent" class="tag">Sent</span>
        </div>
        <div class="provider-rating">
          <p-rating [(ngModel)]="provider.meanStarCount" [readonly]="true" [cancel]="false"></p-rating>
          <span class="rating">({{provider.meanStarCount}})</span>
        </div>
        <div class="provider-jobs-done">
          {{provider.completedProcessCount}} Jobs Done
        </div>
      </div>
    </div>
    <div (click)="!isRequestSent && navigateToProviderDetails(provider.id)" class="router-button">
      <button pButton pRipple type="button" [disabled]="isRequestSent"
        [ngClass]="{'sent-request-button': isRequestSent}">
        <img src="../../../assets/images/arrowRight.svg" class="dropdown-image" />
      </button>
    </div>
  </div>
</div>