import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from 'src/app/helpers/breadcrumb.service';
import { ProcessUtilsService } from 'src/app/helpers/process-utils.service';
import {
  CompanyControllerService,
  DSResponseHRProviderOverviewDto,
  HRProviderOverviewDto,
} from 'src/app/oapi_client/data_symphony';

interface WorkHistoryItem {
  title: string;
  company: string;
  date: string;
  totalCost: string;
  reviews: {
    title: string;
    comment: string;
    starCount: number;
    createdBy: string;
    createdAt: string;
  }[];
}

@Component({
  selector: 'app-hr-provider-details',
  templateUrl: './hr-provider-details.component.html',
  styleUrls: ['./hr-provider-details.component.scss'],
})
export class HrProviderDetailsComponent implements OnInit {
  company: HRProviderOverviewDto | undefined;
  workHistory: WorkHistoryItem[] = [];
  public averageRating: number = 0;
  totalBudget: string = '';

  constructor(
    private companyService: CompanyControllerService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private processUtils: ProcessUtilsService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const id = +params['id'];
      this.getCompanyById(id);
    });
  }

  getCompanyById(id: number): void {
    this.companyService.getHrProviderOverviewById(id).subscribe(
      (data: DSResponseHRProviderOverviewDto) => {
        this.company = data.body;
        if (this.company) {
          const reviewsMap = new Map<number, any[]>();

          this.company.companyDto!.companyStarsDto!.forEach((star) => {
            if (!reviewsMap.has(star.processId!)) {
              reviewsMap.set(star.processId!, []);
            }
            reviewsMap.get(star.processId!)?.push({
              title: star.title || 'No Title',
              comment: star.comment || 'No Review',
              starCount: star.starCount || 0,
              createdBy: star.employer?.name || 'Unknown',
              createdAt: star.createdAt || '',
            });
          });

          this.workHistory = this!.company!.completedProcessList!.map(
            (process) => ({
              title: process.requirement?.name || 'No Title',
              company: process.employerName || 'Unknown',
              date: this.formatDate(process.createdAt),
              totalCost: `${process.budget?.amount} ${process.budget?.type}`,
              reviews: reviewsMap.get(process?.id!) || [],
            })
          );

          this.averageRating = this.company.meanStarCount ?? 0;
          this.breadcrumbService.setGroupName(
            this.company.companyDto!.name ?? ''
          );

          if (this.company.totalBudget && this.company.totalBudget.length > 0) {
            const budget = this.company.totalBudget[0];
            this.totalBudget = `${budget.amount} ${budget.type}`;
          }
        }
      },
      (error) => {
        console.error('Error', error);
      }
    );
  }
  
  formatDate(dateString: string | undefined): string {
    return this.processUtils.formatDate(dateString);
  }

  onImageError(event: Event): void {
    (event.target as HTMLImageElement).src = '../assets/images/hr-provider/default-logo.svg';
    console.error('Image failed to load, using fallback.');
  }
}
