<button class="note-button" (click)="getQuestions()">
    <img src="../assets/images/hr-provider/note-notification.svg" alt="Note"
        *ngIf="proposal?.questionAnswers?.length > 0">
</button>
<ul class="list-none border-round shadow-2 absolute" *ngIf="isOpen">
    <div class="note-modal">
        <div *ngFor="let item of proposal?.questionAnswers">
            <div class="note">
                <div style="display: inline-flex; width: 400px;">
                    <div>
                        <img src="{{getImageSource(item)}}"  style="width: 29px;
                          border-radius: 50%; object-fit: cover; margin-top: 4px; margin-right: 11px;">
                    </div>
                
                    <div>
                        <h5 class="mt-1" *ngIf="item?.authorCompanyType === 'EMPLOYER'">
                            {{item.senderName}}<span class="ml-1"
                            *ngIf="item?.myNote">(you)</span></h5>
                        <h5 class="mt-1" *ngIf="item?.authorCompanyType === 'HR_PROVIDER'">{{item.senderName}} 
                             <span class="ml-1"
                                *ngIf="item?.myNote">(you)</span></h5>
                        <div style="text-align: left;"><span class="date">{{item?.createdAt}}</span></div>
                    </div>
                </div>
                <div class="text mt-2">
                    <p>{{item?.questionAnswer}}</p>
                </div>
                <div class="translate-text">
                    <img src="../assets/images/translation.svg">
                    <div class="text">Translate to Bosnian Language</div>
                </div>
            </div>
        </div>
        <div *ngIf="proposal?.questionAnswer?.length ==0" class="no-notes">
            There are currently no notes. Add some using the input below.
        </div>

        <div class="add-note">
            <input class="add" [(ngModel)]="note" placeholder="Respond to the question..." (keydown.enter)="addNote()">
        </div>
        <div *ngIf="errorMessage.length > 0" class="no-notes-error">
            {{errorMessage}}
        </div>
    </div>
</ul>