import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItem } from 'primeng/api/menuitem';
import { filter } from 'rxjs';
import { AuthHelper } from 'src/app/helpers/auth.helper';
import {
  ManageUserControllerService,
  UserControllerService,
} from 'src/app/oapi_client/data_symphony';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  items: MenuItem[] = [];
  selectedRoute: string | null = null;
  provider: boolean | null = false;
  constructor(
    private authHelper: AuthHelper,
    private router: Router,
    private authService: UserControllerService
  ) {
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        this.selectedRoute = event.url.substring(1);
      });
  }

  setEmployerMenu() {
    this.items = [
      {
        label: 'HOME',
        styleClass: 'layout-root-menuitem',
        items: [
          {
            label: 'Dashboard',
            icon: '../../../assets/images/dashboardIcon.svg',
            url: 'dashboard',
          },
        ],
      },
      {
        label: 'SEARCH MANAGEMENT',
        styleClass: 'layout-root-menuitem',
        items: [
          {
            label: 'Talent Hunt',
            icon: '../../../assets/images/talentHuntIcon.svg',
            url: 'talent-hunt',
          },
          {
            label: 'Talent Discovery',
            icon: '../../../assets/images/talentDiscoveryIcon.svg',
            url: 'talent-discovery',
          },
          {
            label: 'Candidate Pool',
            icon: '../../../assets/images/cpIcon.svg',
            url: 'candidate-pool',
          },
          {
            label: 'HR Providers',
            icon: '../../../assets/images/hrProviderIcon.svg',
            url: 'hr-providers',
          },
        ],
      },
      {
        label: 'PROCESS MANAGEMENT',
        styleClass: 'layout-root-menuitem',
        items: [
          {
            label: 'Outgoing Requests',
            icon: '../../../assets/images/folders.svg',
            url: 'outgoing-request',
          },
          // {
          //   label: 'Team management',
          //   icon: '../../../assets/images/teamManagementIcon.svg',
          //   url: 'team-management',
          // },
        ],
      },
    ];
  }

  setProviderMenu() {
    this.items = [
      {
        label: 'HOME',
        styleClass: 'layout-root-menuitem',
        items: [
          {
            label: 'Dashboard',
            icon: '../../../assets/images/hr-provider/hr-provider-dashboard.svg',
            url: 'dashboard',
          },
        ],
      },
      {
        label: 'SEARCH MANAGEMENT',
        styleClass: 'layout-root-menuitem',
        items: [
          {
            label: 'Talent Hunt',
            icon: '../../../assets/images/hr-provider/hr-provider-talent-hunt.svg',
            url: 'talent-hunt',
          },
          {
            label: 'Talent Discovery',
            icon: '../../../assets/images/hr-provider/hr-provider-talent-discovery.svg',
            url: 'talent-discovery',
          },
          {
            label: 'Candidate Pool',
            icon: '../../../assets/images/hr-provider/hr-provider-candidate-pools.svg',
            url: 'candidate-pool',
          },
        ],
      },
      {
        label: 'PROCESS MANAGEMENT',
        styleClass: 'layout-root-menuitem',
        items: [
          {
            label: 'Received Requests',
            icon: '../../../assets/images/hr-provider/hr-provider-received-request.svg',
            url: 'received-requests',
          },
          {
            label: 'Team management',
            icon: '../../../assets/images/hr-provider/hr-provider-team-management-icon.svg',
            url: 'team-management',
          },
        ],
      },
    ];
  }

  ngOnInit(): void {
    this.authService.getUserRoles().subscribe((roles) => {
      if (roles.body!.includes('ROLE_EMPLOYER_ADMIN')) {
        this.setEmployerMenu();
      } else {
        this.setProviderMenu();
        this.provider = true;
      }
    });
  }
}
