import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserControllerService, AmsResponseListString } from '../oapi_client/data_symphony';
import { firstValueFrom } from 'rxjs';

export const privilegesGuard = async (requiredPrivileges: string[]) => {
  const authService = inject(UserControllerService);
  const router = inject(Router);

  const response: AmsResponseListString = await firstValueFrom(authService.getUserRoles());
  if (response.body?.includes('ROLE_EMPLOYER_ADMIN') || response.body?.includes('ROLE_HR_PROVIDER_ADMIN')) {
    return true;
  }
  const userPrivileges = response?.body || [];
 
  const hasPrivileges = requiredPrivileges.every(privilege =>
    userPrivileges.includes(privilege)
  );

  if (!hasPrivileges) {
    await router.navigate(['/dashboard']);
    return false;
  }

  return true;
};
