import { Component, Input } from '@angular/core';
import { stat } from 'fs';
import { DialogService, DynamicDialogComponent } from 'primeng/dynamicdialog';
import { ConfirmationDialogComponent } from '../../../negotiation-dialogs/confirmation-dialog/confirmation-dialog.component';
import {
  BudgetDto,
  CompanyControllerService,
  CreateNegotiationRequest,
  NegotiationControllerService,
  ProcessControllerService,
} from 'src/app/oapi_client/data_symphony';
import { SuccessDialogComponent } from '../../../negotiation-dialogs/success-dialog/success-dialog.component';
import { SendCounterOfferComponent } from '../../../negotiation-dialogs/send-counter-offer/send-counter-offer.component';
import { ErrorDialogComponent } from 'src/app/admin/negotiation-dialogs/error-dialog/error-dialog.component';
import { OutgoingRequestProposalsQuestionsDialogComponent } from './outgoing-request-proposals-questions-dialog/outgoing-request-proposals-questions-dialog.component';

@Component({
  selector: 'app-outgoing-request-proposals',
  templateUrl: './outgoing-request-proposals.component.html',
  styleUrls: ['./outgoing-request-proposals.component.scss'],
})
export class OutgoingRequestProposalsComponent {
  public proposals: any[] = [];
  public successTitle: string = '';
  public successParaph: string = '';
  public paraph: string = '';
  public title: string = '';
  public proposal: string | null | undefined;
  @Input() processId: number = 0;
  public hasMultipleQuestionAnswers: boolean = false;

  constructor(
    private dialogService: DialogService,
    private negotiationControllerService: NegotiationControllerService,
    private processControllerService: ProcessControllerService,
    private companyController: CompanyControllerService
  ) { }

  ngOnInit() {
    this.getProposals();

  }

  openQuestionDialog(){
    const ref = this.dialogService.open(OutgoingRequestProposalsQuestionsDialogComponent, {
      modal: true,
      data: {
        proposals: this.proposals
      },
    });
    const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
    const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

    const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
    dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
  }

  getProposals() {
    this.negotiationControllerService
      .getAllByProcessId(this.processId)
      .subscribe((proposals) => {
        if (proposals.body) {
          this.proposals = proposals.body;
          // this.hasMultipleQuestionAnswers = proposals.body.some(item => {
          //   return item.questionAnswers?.length! >0
          // });
          if (this.proposals.filter(
              (proposal) => proposal.negotiationList.map((nl: { isEmployerAgreed: boolean; isHrAgreed: boolean; })=>nl.isEmployerAgreed == true 
                && nl.isHrAgreed == true)).length == 1) {
                  
          }
        }
      });
  }

  openDialog(
    status: string,
    proposalId: number,
    proposalAmount?: number,
    proposalMoneyType?: string,
    employerId?: number
  ) {
    switch (status) {
      case Status.Accept:
        this.title = "Are you sure you want to accept HRing's offer?";
        this.paraph =
          'Once you confirm all the other companies offers will be automatically declined.';
        this.proposal = '50 EUR';
        this.showDialog(
          ConfirmationDialogComponent,
          Status.Accept,
          proposalId,
          proposalAmount,
          proposalMoneyType
        );
        break;
      case Status.Decline:
        this.title = "Are you sure you want to decline HRing's offer?";
        this.paraph = "Once you confirm, you can't go back";
        this.proposal = null;
        this.showDialog(
          ConfirmationDialogComponent,
          Status.Decline,
          proposalId,
          undefined,
          undefined
        );
        break;
      case Status.Counter:
        this.title = 'Counter offer';
        this.paraph = "You can send the counter-offer if you wish for a price change.";
        this.showDialog(
          SendCounterOfferComponent,
          Status.Counter,
          proposalId,
        )
        break;
    }
  }

  showDialog(
    component: any,
    status: Status,
    proposalId: number,
    proposalAmount?: number,
    proposalMoneyType?: string
  ) {
    const ref = this.dialogService.open(component, {
      modal: true,
      data: {
        title: this.title,
        paraph: this.paraph,
        successTitle: this.successTitle,
        successParaph: this.successParaph,
        proposal:
          proposalAmount && proposalMoneyType
            ? `${proposalAmount} ${proposalMoneyType}`
            : undefined,
      },
    });
    const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
    const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

    const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
    dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;

    if (Status.Accept === status) {
      ref.onClose.subscribe((result) => {
        if (result) {
          this.acceptOffer(proposalId);
        }
      });
    } else if (Status.Decline === status) {
      ref.onClose.subscribe((result) => {
        if (result) {
          this.declineOffer(proposalId);
        }
      });
    } else if (Status.Counter === status) {
      ref.onClose.subscribe((result) => {
        if (result) {
          this.counterOffer(proposalId, result.amount, result.note, result.currency);
        }
      });
    }
  }

  cancelProvider(hrProviderId: number) {
    const ref = this.dialogService.open(ConfirmationDialogComponent, {
      modal: true,
      data: {
        title: `Are you sure you want to cancel this proposal?`,
        paraph: `It will remove the proposal from the list.`,
      },
    });
    const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
    const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

    const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
    dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;

    ref.onClose.subscribe((result) => {
      if (result) {
        this.processControllerService.cancelHrProvider(this.processId, hrProviderId).subscribe(() => {
          this.getProposals();
          const refSuccess = this.dialogService.open(SuccessDialogComponent, {
            modal: true,
            data: {
              title: `The proposal has been succesfully canceled.`,
              paraph: 'You can send the request to the HR provider again.',
            },
          });
          const dialogRef = this.dialogService.dialogComponentRefMap.get(refSuccess);
          const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

          const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
          dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
        }, error => {
          const ref = this.dialogService.open(ErrorDialogComponent, {
            data: {
              title: "The proposal cancellation failed.",
              paraph: "Please try again."
            },
          })
          const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
          const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

          const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
          dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
        })
      }
    })
  }

  deleteProposal(hrProviderId: number) {
    const ref = this.dialogService.open(ConfirmationDialogComponent, {
      modal: true,
      data: {
        title: `Are you sure you want to delete this proposal?`,
        paraph: `It will remove the proposal from the list.`,
      },
    });
    const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
    const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

    const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
    dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;

    ref.onClose.subscribe((result) => {
      if (result) {
        this.processControllerService.cancelHrProvider(this.processId, hrProviderId).subscribe(() => {
          this.getProposals();
          const refSuccess = this.dialogService.open(SuccessDialogComponent, {
            modal: true,
            data: {
              title: `The proposal has been succesfully deleted.`,
              paraph: 'You can send the request to the HR provider again.',
            },
          });
          const dialogRef = this.dialogService.dialogComponentRefMap.get(refSuccess);
          const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

          const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
          dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
        }, error => {
          const ref = this.dialogService.open(ErrorDialogComponent, {
            data: {
              title: "The proposal deletion failed.",
              paraph: "Please try again."
            },
          })
          const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
          const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

          const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
          dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
        })
      }
    })
  }

  counterOffer(proposalId: number, amount: number, note: string, currency: BudgetDto.type) {
    const offer: CreateNegotiationRequest = {
      offerToId: proposalId,
      processId: this.processId,
      offer: {
        type: currency,
        amount: amount,
      },
      note: note,
    };

    this.negotiationControllerService.createNegotiation(offer).subscribe(() => {
      this.getProposals();
    })
  }

  public acceptOffer(proposalId: number) {
    this.processControllerService
      .approveNegotiation(proposalId)
      .subscribe(() => {
        const ref = this.dialogService.open(SuccessDialogComponent, {
          data: {
            title: 'Offer accepted',
            paraph: 'You have accepted the offer. The process can start.',
          },
        });
        const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
        const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

        const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
        dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
        this.getProposals();
      }, error => {
        const ref = this.dialogService.open(ErrorDialogComponent, {
          data: {
            title: "Offer acceptence failed.",
            paraph: "Please try again."
          },
        })
        const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
        const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

        const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
        dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
      });
  }

  public declineOffer(proposalId: number) {
    this.negotiationControllerService
      .declineOffer(proposalId)
      .subscribe((response) => {
        const ref = this.dialogService.open(SuccessDialogComponent, {
          data: {
            title: 'Request declined',
            paraph:
              'You have declined the request. The Employer will be notified.',
          },
        });
        const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
        const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

        const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
        dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
        this.getProposals();
      }, error => {
        const ref = this.dialogService.open(ErrorDialogComponent, {
          data: {
            title: "Declining the offer failed.",
            paraph: "Please try again."
          },
        })
        const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
        const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

        const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
        dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
      });
  }
}

enum Status {
  Accept = 'Accept',
  Decline = 'Decline',
  Counter = 'Counter',
}
