import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import {
  CompanyDto,
  ProcessControllerService,
  ProcessDto,
  CompanyControllerService,
  OutgoingProcessDto,
} from 'src/app/oapi_client/data_symphony';

@Component({
  selector: 'app-outgoing-requests-list',
  templateUrl: './outgoing-requests-list.component.html',
  styleUrls: ['./outgoing-requests-list.component.scss'],
})
export class OutgoingRequestsListComponent {
  public isLoading: boolean = true;
  public first: number = 0;
  public rows: number = 8;
  public providersCount: number = 0;
  public pagedItems: any[] = [];
  public searchText: string = '';
  public totalRecords: number = 0;
  public buttonSplit: boolean = false;
  public items: MenuItem[] = [
    { label: 'View all' },
    { label: 'Pending' },
    { label: 'Created' },
  ];
  public status: string = 'View all';
  public requests: ProcessDto[] = [];
  public providers: CompanyDto[] = [];
  filteredProcesses: OutgoingProcessDto[] = [];

  constructor(
    private router: Router,
    private processManagementService: CompanyControllerService
  ) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras.state) {
      this.providers = navigation.extras.state['providers'];
    }
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.getProcesses();
  }

  getProcesses() {
    this.processManagementService.getOutgoingRequest().subscribe({
      next: (response) => {
        if (response.body) {
          this.requests =
            response?.body!.sort(
              (a, b) =>
                new Date(b.createdAt!).getTime() -
                new Date(a.createdAt!).getTime()
            ) || [];
          if (this.status === 'View all' || this.status === '') {
            this.status = '';
          }
          this.updatePagedItems(0, 5);
          this.isLoading = false;
        }
      },
      error: (error) => {
        console.error('Error fetching outgoing requests:', error);
        this.isLoading = false;
      },
    });
  }

  paginate(event: any) {
    const startIndex = event.first;
    const endIndex = startIndex + event.rows;
    this.updatePagedItems(startIndex, endIndex);
  }

  updatePagedItems(startIndex: number, endIndex: number) {
    const filteredRequests = this.requests.filter(
      (request) =>
        request.requirement?.name
          ?.toLocaleLowerCase()
          .includes(this.searchText.toLocaleLowerCase()) &&
        request.status
          ?.toLocaleLowerCase()
          .includes(this.status.toLocaleLowerCase())
    );
    this.totalRecords = filteredRequests.length;
    this.pagedItems = filteredRequests.slice(startIndex, endIndex);
  }

  searchProviders() {
    this.updatePagedItems(0, 5);
  }

  update() {
    this.buttonSplit = false;
    this.getProcesses();
  }

  onActiveItemChange(event: any) {
    this.status = event.label;
    if (this.status === 'View all') {
      this.status = '';
    } else {
      this.status = event.label;
    }
    this.updatePagedItems(0, 5);
  }
}
