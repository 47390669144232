import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewChild,
  AfterViewInit,
  Renderer2,
  OnDestroy
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageService } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AuthHelper } from 'src/app/helpers/auth.helper';
import {
  CountryControllerService,
  DSResponseListString,
  LinkedinControllerService,
  LinkedinProfilesControllerService,
} from 'src/app/oapi_client/data_symphony';

interface Country {
  code: string;
  name: string;
}

interface ExpandedFilterInput {
  title: string; 
  value: string; 
  ngModel: any;
  pinned: boolean; 
  multiSelect?: boolean; 
}

interface ExpandedFilterGroup {
  groupName: string,
  members: ExpandedFilterInput[]
};

@Component({
  selector: 'app-advanced-filter-sidebar',
  templateUrl: './advanced-filter-sidebar.component.html',
  styleUrls: ['./advanced-filter-sidebar.component.scss'],
})
export class AdvancedFilterSidebarComponent implements OnInit, OnDestroy {
  @Output() filterApplied: EventEmitter<any> = new EventEmitter();
  @Output() sidebarHeightChange = new EventEmitter<number>();
  private filterSubject = new Subject<void>();
  private sidebarResizeObserver!: ResizeObserver;

  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  @ViewChild('filterSidebarContainer') filterSidebarContainer!: ElementRef;
  @ViewChild('filterSidebarSmall') filterSidebarSmall!: ElementRef;

  isOpen: boolean = false;
  maxYearsInput: number | string = '';
  minYearsInput: number | string = '';
  suggestedWords: string[] = [];
  displayedSuggestions: string[] = [];
  checkbox1: boolean = false;
  checkbox2: boolean = false;
  values: string[] = [];
  selectedSkills: string[] = [];
  locations: any;
  selectedLocations: any[] = [];
  selectedExperienceLocations: any[] = [];
  loading: boolean = false;
  page: number = 0;
  empty: boolean = false;
  currentUserID: number = 0;
  personNameSurname: string = '';

  industries: any[] = [];
  selectedIndustries: { name: string }[] = [];
  companies: any[] = [];
  selectedCompanies: { name: string }[] = [];
  languages: any[] = [];
  selectedLanguages: { name: string }[] = [];
  titles: any[] = [];
  selectedTitles: { name: string }[] = [];
  degrees: any[] = [];
  selectedDegrees: { name: string }[] = [];
  schools: any[] = [];
  selectedSchools: { name: string }[] = [];
  // empty: boolean = false;
  pageIndustries: number = 0;
  pageCompanies: number = 0;
  pageLanguages: number = 0;
  pageTitles: number = 0;
  pageDegrees: number = 0;
  pageSchools: number = 0;

  certificationTitle!: string;
  certificationInstitutionName!: string;
  certificationAbout!: string;

  awardInstitution!: string;
  awardTitle!: string;

  courseAbout!: string;
  courseTitle!: string;

  languageDegree!: string;

  publicationAbout!: string;
  publicationInstitutionName!: string;
  publicationTitle!: string;

  testScoreEquals!: string;
  testScoreTitle!: string;

  experienceAbout!: string;
  sidebarFilterGeneralName!: string;

  countries?: Array<Country>;
  selectedCountries!: Array<Country>;
  institutions?: Array<Country>;
  selectedInstitutions?: Array<Country>;

  activeAccordionIndexes: Array<number> = [0]; 
  isExpandedAccordionTabs: boolean = false;

  expandedInputs: ExpandedFilterInput[] = [];

  testInputs: ExpandedFilterGroup[] = [];

  pinnedFilters: ExpandedFilterInput[] = [];
  hasPinnedFilter: boolean = false; 

  isPinned = false;
  togglePin(index: number) {
    const input = this.expandedInputs[index];
    input.pinned = !input.pinned;

    if (input.pinned) {
      // Input pinned tiklaninca pinnedFilters listesine ekle
      this.pinnedFilters.push(input);
    } else {
      // Input unpinned tiklaninca pinnedFilters listesinden çıkar
      this.pinnedFilters = this.pinnedFilters.filter(
        pinnedInput => pinnedInput !== input
      );
    }

    this.updateHasPinnedFilter();

    localStorage.setItem('talentDiscorveryPinnedFilters', JSON.stringify(this.pinnedFilters))

  }

  unpinFromSidebar(filter: ExpandedFilterInput) {
    this.pinnedFilters = this.pinnedFilters.filter(
      pinnedInput => pinnedInput !== filter
    );

    // Sol tarafta ilgili input'u bulup pinned durumunu false yap
    const originalInput = this.expandedInputs.find(input => input.title === filter.title);
    if (originalInput) {
      originalInput.pinned = false;
    }

    this.updateHasPinnedFilter();

    localStorage.setItem('talentDiscorveryPinnedFilters', JSON.stringify(this.pinnedFilters))

  }

  private updateHasPinnedFilter(): void {
    this.hasPinnedFilter = this.pinnedFilters.length > 0;
  }

  isPinnedFilter(index: number): boolean {
    return this.expandedInputs[index].pinned;
  }

  isFilterSidebarOpen = true;
  toggleFilterSidebar() {
    this.isFilterSidebarOpen = !this.isFilterSidebarOpen;
  }

  isExtendedFilterSidebarOpen = false;
  toggleExtendedFilterSidebar() {
    this.isExtendedFilterSidebarOpen = !this.isExtendedFilterSidebarOpen;
  } 


  constructor(
    private elementRef: ElementRef,
    private linkedinProfileService: LinkedinProfilesControllerService,
    private authHelper: AuthHelper,
    private messageService: MessageService,
    private countryControllerService: CountryControllerService,
    private linkedinService: LinkedinControllerService,
    private renderer: Renderer2
  ) {
    countryControllerService.findCountry('EN').subscribe((response) => {
      if (response.success) {
        this.locations = response.body;
      }
    });
    this.updateDisplayedSuggestions();

    this.filterSubject
      .pipe(debounceTime(300)) // 300ms bekle ve filtreyi uygula
      .subscribe(() => this.applyFilter());

    this.expandedInputs = [
      { title: 'Publication Title', value: this.publicationTitle, ngModel: 'publicationTitle', pinned: false, multiSelect: false },
      { title: 'Publication institution name', value: this.publicationInstitutionName, ngModel: 'publicationInstitutionName', pinned: false, multiSelect: false },
      { title: 'Publication about', value: this.publicationAbout, ngModel: 'publicationAbout', pinned: false, multiSelect: false },
      { title: 'Course Title', value: this.courseTitle, ngModel: 'courseTitle', pinned: false, multiSelect: false },
      { title: 'Course About', value: this.courseAbout, ngModel: 'courseAbout', pinned: false, multiSelect: false },
      { title: 'Award Title', value: this.awardTitle, ngModel: 'awardTitle', pinned: false, multiSelect: false },
      { title: 'Award Institution', value: this.awardInstitution, ngModel: 'awardInstitution', pinned: false, multiSelect: false },
      { title: 'Language', value: 'language', ngModel: 'languages', pinned: false, multiSelect: true },
      { title: 'Language Degree', value: this.languageDegree, ngModel: 'languageDegree', pinned: false, multiSelect: true },
      { title: 'Test Score Title', value: this.testScoreTitle, ngModel: 'testScoreTitle', pinned: false, multiSelect: false },
      { title: 'Test Score Equals', value: this.testScoreEquals, ngModel: 'testScoreEquals', pinned: false, multiSelect: false },
    ]

    this.testInputs = [
      {
        groupName: 'Publication',
        members: [
          { title: 'Publication Title', value: this.publicationTitle, ngModel: 'publicationTitle', pinned: false, multiSelect: false },
          { title: 'Publication institution name', value: this.publicationInstitutionName, ngModel: 'publicationInstitutionName', pinned: false, multiSelect: false },
          { title: 'Publication about', value: this.publicationAbout, ngModel: 'publicationAbout', pinned: false, multiSelect: false },
        ]
      },
      {
        groupName: 'Course',
        members: [
          { title: 'Course Title', value: this.courseTitle, ngModel: 'courseTitle', pinned: false, multiSelect: false },
          { title: 'Course About', value: this.courseAbout, ngModel: 'courseAbout', pinned: false, multiSelect: false },
        ]
      },
        {
        groupName: 'Awards',
        members: [
          { title: 'Award Title', value: this.awardTitle, ngModel: 'awardTitle', pinned: false, multiSelect: false },
          { title: 'Award Institution', value: this.awardInstitution, ngModel: 'awardInstitution', pinned: false, multiSelect: false },
        ]
        },
        {
        groupName: 'Language',
        members: [
          { title: 'Language', value: 'language', ngModel: 'languages', pinned: false, multiSelect: true },
          { title: 'Language Degree', value: this.languageDegree, ngModel: 'languageDegree', pinned: false, multiSelect: true },
        ]
        },
      {
        groupName: 'Test Score',
        members: [
          { title: 'Test Score Title', value: this.testScoreTitle, ngModel: 'testScoreTitle', pinned: false, multiSelect: false },
          { title: 'Test Score Equals', value: this.testScoreEquals, ngModel: 'testScoreEquals', pinned: false, multiSelect: false },
        ]
      },
      {
        groupName: 'Certification',
        members: [
          { title: 'Certification Title', value: this.certificationTitle, ngModel: 'certificationTitle', pinned: false, multiSelect: false },
          { title: 'Certification About', value: this.certificationAbout, ngModel: 'certificationAbout', pinned: false, multiSelect: false },
          { title: 'Certification Institution Name', value: this.certificationInstitutionName, ngModel: 'certificationInstitutionName', pinned: false, multiSelect: false },
        ]
      }
    ]

  }

  ngOnInit(): void {
    this.authHelper.currentUser.subscribe(
      (user) => (this.currentUserID = user.id)
    );
    this.fetchIndustries(this.pageIndustries);
    this.fetchCompanies(this.pageCompanies);
    this.fetchLanguages(this.pageLanguages);
    this.fetchTitles(this.pageTitles);
    this.fetchDegrees(this.pageTitles);
    this.fetchSchools(this.pageSchools);

    const pinnedFiltersFromStorage = localStorage.getItem('talentDiscorveryPinnedFilters');
  
    if (pinnedFiltersFromStorage) {
      const parsedFilters: ExpandedFilterInput[] = JSON.parse(pinnedFiltersFromStorage);

      // localStorageden inputlari al, ExpandedInputs'teki indexleri bul
      const matchedIndexes = parsedFilters.map(filter =>
        this.expandedInputs.findIndex(input => input.title === filter.title)
      );

      // tekrar pinnedInputsa setlemek ve value eslestirmek icin
      // pinnedFiltersa setleme yap.
      matchedIndexes.forEach(index => {
        if (index !== -1) {
          this.togglePin(index); 
        }
      });

      // expandenInputs icerisindeki pinned durumlarini locaStrogaden gelenlere gore guncelle
      this.expandedInputs.forEach(input => {
        const matchedFilter = this.pinnedFilters.find(pinned => pinned.title === input.title);
        
        if (matchedFilter) {
          input.pinned = matchedFilter.pinned;
        } else {
          input.pinned = false;
        }
      });

      this.updateHasPinnedFilter();
    }

  }


  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  onValueRemoved(event: any): void {
    const removedWord = event.value;
    const index = this.selectedSkills.indexOf(removedWord);
    if (index !== -1) {
      this.selectedSkills.splice(index, 1);
      this.addWordToSuggestions(removedWord);
      this.updateDisplayedSuggestions();
    }

    this.filterSubject.next();
  }

  onValueAdded(event: any): void {
    this.suggestKeyword(this.currentUserID, event.value).subscribe(
      (response) => {
        if (response.body) {
          this.suggestedWords = response.body || [];
          this.updateDisplayedSuggestions();
        }
      }
    );

    this.filterSubject.next();
  }

  suggestKeyword(
    userId: number,
    input: string
  ): Observable<DSResponseListString> {
    return this.linkedinProfileService.suggestKeyword(userId, input);
  }

  removeEnteredWordsFromSuggestions(): void {
    this.values.forEach((enteredWord: string) => {
      const index = this.suggestedWords.indexOf(enteredWord);
      if (index !== -1) {
        this.suggestedWords.splice(index, 1);
      }
    });
    this.updateDisplayedSuggestions();
  }

  addWordToSkills(event: Event, word: string): void {
    event.stopPropagation();
    if (this.suggestedWords.includes(word)) {
      const index = this.values.indexOf(word);
      if (index === -1) {
        this.values.push(word);
        this.removeWordFromSuggestions(word);
        this.updateDisplayedSuggestions();
      }
    } else {
      const index = this.values.indexOf(word);
      if (index === -1) {
        this.values.push(word);
      }
    }
  }

  updateDisplayedSuggestions(): void {
    const availableSuggestions = this.suggestedWords.filter(
      (word) => !this.values.includes(word.toLowerCase())
    );
    const allWords = [...availableSuggestions, ...this.selectedSkills];
    this.displayedSuggestions = allWords.slice(0, 4);
  }

  addWordToSuggestions(word: string): void {
    if (!this.suggestedWords.includes(word)) {
      this.suggestedWords.push(word);
      this.updateDisplayedSuggestions();
    }
  }

  removeWordFromSuggestions(word: string): void {
    const index = this.suggestedWords.indexOf(word);
    if (index !== -1) {
      this.suggestedWords.splice(index, 1);
    }
  }

  onSelect(event: any){
    console.log("onSelect event", event);
    if(event?.originalEvent){
      event.originalEvent.stopPropagation();
    }
    const selectedValues = event.value;
    this.locations?.sort((a: any, b: any) => {
      const isSelectedA = selectedValues.includes(a);
      const isSelectedB = selectedValues.includes(b);
  
      if (isSelectedA && !isSelectedB) {
        return -1;
      } else if (!isSelectedA && isSelectedB) {
        return 1;
      } else {
        return 0;
      }
    });

    this.filterSubject.next();
  }

  onSelectAdvanced(event: any, optionsArray: any[]) {
    console.log("onSelectAdvanced event", {event, optionsArray});
    if(event?.originalEvent){
      event.originalEvent.stopPropagation();
    }
    const selectedValues = event.value;
    optionsArray?.sort((a: any, b: any) => {
      const isSelectedA = selectedValues?.includes(a);
      const isSelectedB = selectedValues?.includes(b);
  
      if (isSelectedA && !isSelectedB) {
        return -1; 
      } else if (!isSelectedA && isSelectedB) {
        return 1; 
      } else {
        return 0; 
      }
    });

    this.filterSubject.next();
  }

  clearFilter() {
    this.personNameSurname = '';
    this.minYearsInput = 0;
    this.maxYearsInput = 0;
    this.values = [];
    this.displayedSuggestions = [];
    this.selectedLocations = [];
    this.selectedTitles = [];
    this.selectedIndustries = [];
    this.selectedLanguages = [];
    this.selectedDegrees = [];
    this.selectedSchools = [];
    this.selectedCompanies = [];
    this.selectedSkills = [];
    this.checkbox1 = false;
    this.checkbox2 = false;
    this.experienceAbout = '';
    this.awardInstitution = '';
    this.awardTitle = '';
    this.courseAbout = '';
    this.courseTitle = '';
    this.publicationAbout = '';
    this.publicationInstitutionName = '';
    this.publicationTitle = '';
    this.testScoreEquals = '';
    this.testScoreTitle = '';
    this.certificationTitle = '';
    this.certificationAbout = '';
    this.certificationInstitutionName = '';

    this.expandedInputs = [
      { title: 'Publication Title', value: this.publicationTitle, ngModel: 'publicationTitle', pinned: false, multiSelect: false },
      { title: 'Publication institution name', value: this.publicationInstitutionName, ngModel: 'publicationInstitutionName', pinned: false, multiSelect: false },
      { title: 'Publication about', value: this.publicationAbout, ngModel: 'publicationAbout', pinned: false, multiSelect: false },
      { title: 'Course Title', value: this.courseTitle, ngModel: 'courseTitle', pinned: false, multiSelect: false },
      { title: 'Course About', value: this.courseAbout, ngModel: 'courseAbout', pinned: false, multiSelect: false },
      { title: 'Award Title', value: this.awardTitle, ngModel: 'awardTitle', pinned: false, multiSelect: false },
      { title: 'Award Institution', value: this.awardInstitution, ngModel: 'awardInstitution', pinned: false, multiSelect: false },
      { title: 'Language', value: 'language', ngModel: 'languages', pinned: false, multiSelect: true },
      { title: 'Language Degree', value: this.languageDegree, ngModel: 'languageDegree', pinned: false, multiSelect: true },
      { title: 'Test Score Title', value: this.testScoreTitle, ngModel: 'testScoreTitle', pinned: false, multiSelect: false },
      { title: 'Test Score Equals', value: this.testScoreEquals, ngModel: 'testScoreEquals', pinned: false, multiSelect: false },
    ]

    const matchedIndexes = this.pinnedFilters.map(filter =>
      this.expandedInputs.findIndex(input => input.title === filter.title)
    );

    this.pinnedFilters = [];

    // tekrar pinnedInputsa setlemek ve value eslestirmek icin
    // pinnedFiltersa setleme yap.
    matchedIndexes.forEach(index => {
      if (index !== -1) {
        this.togglePin(index); 
      }
    });

    //filtre alanlarini temizledikten sonra tabloyu da temizle
    this.filterSubject.next();
  }

  onMinYearsInput(event: any): void {
    let value: string = event.target.value.trim();
    value = value.replace(/^(-)?0+(?=\d)/, '$1');
    const parsedValue: number = parseFloat(value);
    if (isNaN(parsedValue) || parsedValue < 0 || parsedValue > 50) {
      event.target.value = '';
      this.minYearsInput = '';
    } else {
      this.minYearsInput = parsedValue;
    }

    this.filterSubject.next();
  }

  onMaxYearsInput(event: any): void {
    let value: string = event.target.value.trim();
    value = value.replace(/^(-)?0+(?=\d)/, '$1');
    const parsedValue: number = parseFloat(value);
    if (isNaN(parsedValue) || parsedValue < 0 || parsedValue > 50) {
      event.target.value = '';
      this.maxYearsInput = '';
    } else {
      this.maxYearsInput = parsedValue;
    }

    this.filterSubject.next();
  }

  validateYears(): boolean {
    if (
      this.maxYearsInput &&
      this.minYearsInput &&
      this.maxYearsInput < this.minYearsInput
    ) {
      return false;
    }
    return true;
  }

  onKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.code === 'Enter'){
      this.filterSubject.next(); // Her input güncellemesinde debounce mekanizmasını tetikle
    }
  }

  applyFilter() {
    if (!this.validateYears()) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Max years cannot be less than Min years.',
      });
      return;
    }

    const filters = {
      personNameSurname: this.personNameSurname,
      country: this.selectedLocations.map((location) => location.code),
      minYears: this.minYearsInput || '',
      maxYears: this.maxYearsInput || '',

      skill: this.values,

      industryName: this.selectedIndustries.map((industry) => industry.name),
      languageName: this.selectedLanguages.map((language) => language.name),

      educationDegree: this.selectedDegrees.map((degree) => degree.name),
      educationInstitutionName: this.selectedSchools.map((school) => school.name),

      experienceTitle: this.selectedTitles.map((title) => title.name),
      experienceInstitutionName: this.selectedCompanies.map((company) => company.name),
      experienceLocation: this.selectedExperienceLocations.map((location) => location.code),

      experienceAbout: this.experienceAbout,
      certificationTitle: this.certificationTitle,
      certificationInstitutionName: this.certificationInstitutionName,
      certificationAbout: this.certificationAbout,
      awardInstitution: this.awardInstitution,
      awardTitle: this.awardTitle,
      courseAbout: this.courseAbout,
      courseTitle: this.courseTitle,
      publicationAbout: this.publicationAbout,
      publicationInstitutionName: this.publicationInstitutionName,
      publicationTitle: this.publicationTitle,
      testScoreEquals: this.testScoreEquals,
      testScoreTitle: this.testScoreTitle,
    };

    this.filterApplied.emit(filters);
    this.isOpen = false;

  }

  removeKeyword(event: MouseEvent, value: string) {
    event.stopPropagation();
    const index = this.values.indexOf(value);
    if (index !== -1) {
      this.values.splice(index, 1);
      this.onValueRemoved({ value });
    }
  }

  fetchIndustries(event: any) {
    if (!this.empty) {
      this.linkedinService
        .getLinkedinIndustries(this.pageIndustries)
        .subscribe((data) => {
          if (data.body) {
            const newIndustries = data.body.content?.map((industry) => ({
              name: industry.name || "",
            }));
            this.industries = [...this.industries, ...newIndustries!];
            this.empty = data.body?.empty ? data.body?.empty : false;
          }
        });
      this.pageIndustries++;
    }
  }

  fetchCompanies(event: any) {
    if (!this.empty) {
      this.linkedinService
        .getLinkedinCompanies(this.pageCompanies)
        .subscribe((data) => {
          if (data.body) {
            const newCompanies = data.body.content?.map((company) => ({
              name: company.name || "",
            }));
            this.companies = [...this.companies, ...newCompanies!];
            this.empty = data.body?.empty ? data.body?.empty : false;
          }
        });
      this.pageCompanies++;
    }
  }

  fetchLanguages(event: any) {
    if (!this.empty) {
      this.linkedinService
        .getLinkedinLanguages(this.pageLanguages)
        .subscribe((data) => {
          if (data.body) {
            const newLanguages = data.body.content?.map((lang) => ({
              name: lang.name || "",
            }));
            this.languages = [...this.languages, ...newLanguages!];
            this.empty = data.body?.empty ? data.body?.empty : false;
          }
        });
      this.pageLanguages++;
    }
  }

  fetchTitles(event: any) {
    if (!this.empty) {
      this.linkedinService
        .getLinkedinTitles(this.pageTitles)
        .subscribe((data) => {
          if (data.body) {
            const newTitles = data.body.content?.map((title) => ({
              name: title.name || "",
            }));
            this.titles = [...this.titles, ...newTitles!];
            this.empty = data.body?.empty ? data.body?.empty : false;
          }
        });
      this.pageTitles++;
    }
  }

  fetchDegrees(event: any) {
    if (!this.empty) {
      this.linkedinService
        .getLinkedinDegrees(this.pageDegrees)
        .subscribe((data) => {
          if (data.body) {
            const newDegrees = data.body.content?.map((degree) => ({
              name: degree.name || "",
            }));
            this.degrees = [...this.degrees, ...newDegrees!];
            this.empty = data.body?.empty ? data.body?.empty : false;
          }
        });
      this.pageDegrees++;
    }
  }

  fetchSchools(event: any) {
    if (!this.empty) {
      this.linkedinService
        .getLinkedinSchools(this.pageSchools)
        .subscribe((data) => {
          if (data.body) {
            const newSchools = data.body.content?.map((school) => ({
              name: school.name || "",
            }));
            this.schools = [...this.schools, ...newSchools!];
            this.empty = data.body?.empty ? data.body?.empty : false;
          }
        });
      this.pageSchools++;
    }
  }

  toggleExpandCollapseAccordions() {
    if (this.isExpandedAccordionTabs) {
      // Tum filter accordion lari kapali hale getir
      this.activeAccordionIndexes = [];
    } else {
      // Tum filter accordion lari acik hale getir
      this.activeAccordionIndexes = [0, 1, 2, 3, 4]; 
    }
    this.isExpandedAccordionTabs = !this.isExpandedAccordionTabs;

    // HTML DOM'dan #filterSidebarSmall idli elementi al
    const sidebarElement = this.filterSidebarSmall.nativeElement;

    if (sidebarElement) {
      this.emitSidebarHeight(sidebarElement);
    }
  }

  /**
   * filter sidebardaki accordionlardan herhangi birine tiklaninca calisir
   * @param filter accordion indexsi 
   */
  onPanelToggle(index: number): void {

    const sidebarElement = this.filterSidebarSmall.nativeElement;

    if (sidebarElement) {
      this.emitSidebarHeight(sidebarElement);
    }
  }

  ngAfterViewInit() {

    if (!this.filterSidebarSmall) {
      //console.error('height Filter Sidebar reference not found!');
      return;
    }

    // HTML DOM'dan #filterSidebarSmall idli elementi al
    const sidebarElement = this.filterSidebarSmall.nativeElement;

    this.sidebarResizeObserver = new ResizeObserver(() => {
      this.emitSidebarHeight(sidebarElement);
    });

    this.sidebarResizeObserver.observe(sidebarElement);

  }

  ngOnDestroy(): void {
    if (this.sidebarResizeObserver) {
      this.sidebarResizeObserver.disconnect();
    }
  }

  /**
   * filter Sidebar icin hesaplanan heighti bir ust componente gonderir
   * @param sidebarElement 
   */
  private emitSidebarHeight(sidebarElement: HTMLElement): void {
    const height = sidebarElement.offsetHeight;
    this.sidebarHeightChange.emit(height); 
  }

  getValue(key: string): any {
    return this[key as keyof this];
  }
  
  setValue(key: string, value: any): void {
    this[key as keyof this] = value;
  }

  protected readonly onfocus = onfocus;
}
