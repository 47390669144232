/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { AmsResponseBoolean } from '../models/AmsResponseBoolean';
import type { AmsResponseListMiniUserDto } from '../models/AmsResponseListMiniUserDto';
import type { AmsResponseMiniUserDto } from '../models/AmsResponseMiniUserDto';
import type { AmsResponseString } from '../models/AmsResponseString';
import type { AmsResponseUser } from '../models/AmsResponseUser';
import type { CreateInviteUserRequest } from '../models/CreateInviteUserRequest';
import type { EmailRequest } from '../models/EmailRequest';
import type { UpdateUserPasswordRequest } from '../models/UpdateUserPasswordRequest';
import type { UserDto } from '../models/UserDto';
import type { VerifyAndRegisterUserRequest } from '../models/VerifyAndRegisterUserRequest';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class ManageUserControllerService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @param userId
     * @param requestBody
     * @returns AmsResponseString OK
     * @throws ApiError
     */
    public updatePrivileges(
        userId: number,
        requestBody: Array<string>,
    ): Observable<AmsResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v1/manage/users/updatePrivileges/{userId}',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns AmsResponseBoolean OK
     * @throws ApiError
     */
    public signUp(
        requestBody: UserDto,
    ): Observable<AmsResponseBoolean> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/manage/users/sign-up',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param email
     * @param roleName
     * @returns AmsResponseBoolean OK
     * @throws ApiError
     */
    public addRole(
        email: string,
        roleName: string,
    ): Observable<AmsResponseBoolean> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/manage/users/role/{email}/{roleName}',
            path: {
                'email': email,
                'roleName': roleName,
            },
        });
    }
    /**
     * @param email
     * @param roleName
     * @returns AmsResponseBoolean OK
     * @throws ApiError
     */
    public removeRole(
        email: string,
        roleName: string,
    ): Observable<AmsResponseBoolean> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/v1/manage/users/role/{email}/{roleName}',
            path: {
                'email': email,
                'roleName': roleName,
            },
        });
    }
    /**
     * @param requestBody
     * @returns AmsResponseString OK
     * @throws ApiError
     */
    public changeTeammatePassword(
        requestBody?: UpdateUserPasswordRequest,
    ): Observable<AmsResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/manage/users/resetUserPassword',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param email
     * @returns AmsResponseString OK
     * @throws ApiError
     */
    public resetPassword(
        email: string,
    ): Observable<AmsResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/manage/users/resetPassword/{email}',
            path: {
                'email': email,
            },
        });
    }
    /**
     * @param requestBody
     * @returns AmsResponseString OK
     * @throws ApiError
     */
    public resendVerificationEmail(
        requestBody: EmailRequest,
    ): Observable<AmsResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/manage/users/resendVerificationEmail',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns AmsResponseMiniUserDto OK
     * @throws ApiError
     */
    public inviteMember(
        requestBody: CreateInviteUserRequest,
    ): Observable<AmsResponseMiniUserDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/manage/users/invite',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns AmsResponseString OK
     * @throws ApiError
     */
    public acceptInvitation(
        requestBody: VerifyAndRegisterUserRequest,
    ): Observable<AmsResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/manage/users/acceptInvitation',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param email
     * @returns AmsResponseUser OK
     * @throws ApiError
     */
    public getUser(
        email: string,
    ): Observable<AmsResponseUser> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/manage/users/{email}',
            path: {
                'email': email,
            },
        });
    }
    /**
     * @param verificationCode
     * @returns AmsResponseString OK
     * @throws ApiError
     */
    public verify(
        verificationCode: string,
    ): Observable<AmsResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/manage/users/verify',
            query: {
                'verificationCode': verificationCode,
            },
        });
    }
    /**
     * @returns AmsResponseListMiniUserDto OK
     * @throws ApiError
     */
    public userTeammates(): Observable<AmsResponseListMiniUserDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/manage/users/teammates',
        });
    }
    /**
     * @param verificationCode
     * @returns AmsResponseString OK
     * @throws ApiError
     */
    public getEmailForVerificationCode(
        verificationCode: string,
    ): Observable<AmsResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/manage/users/getVerificationCodeEmail/{verificationCode}',
            path: {
                'verificationCode': verificationCode,
            },
        });
    }
    /**
     * @param userId
     * @returns AmsResponseString OK
     * @throws ApiError
     */
    public deactivateUser(
        userId: number,
    ): Observable<AmsResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/manage/users/deactivate/{userId}',
            path: {
                'userId': userId,
            },
        });
    }
    /**
     * @param userId
     * @returns AmsResponseString OK
     * @throws ApiError
     */
    public cancelInvitation(
        userId: number,
    ): Observable<AmsResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/manage/users/cancelInvitation/{userId}',
            path: {
                'userId': userId,
            },
        });
    }
    /**
     * @param userId
     * @returns AmsResponseString OK
     * @throws ApiError
     */
    public activateUser(
        userId: number,
    ): Observable<AmsResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/manage/users/activate/{userId}',
            path: {
                'userId': userId,
            },
        });
    }
    /**
     * @param userId
     * @returns AmsResponseString OK
     * @throws ApiError
     */
    public deleteUser(
        userId: number,
    ): Observable<AmsResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/v1/manage/users/{userId}',
            path: {
                'userId': userId,
            },
        });
    }
}
