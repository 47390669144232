import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { DSResponseListJobTraceWithUserInfoDto, JobTraceWithUserInfoDto, JobTraceControllerService } from '../oapi_client/data_symphony';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private searchesSubject = new BehaviorSubject<JobTraceWithUserInfoDto[]>([]);
  public searches$: Observable<JobTraceWithUserInfoDto[]> = this.searchesSubject.asObservable();
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  constructor(private jobTraceService: JobTraceControllerService) { }

  setLoading(isLoading: boolean): void {
    this.loadingSubject.next(isLoading);
  }

  updateSearches() {
    this.setLoading(true);
    this.jobTraceService.jobLinkedinList().pipe(
      map((response: DSResponseListJobTraceWithUserInfoDto) => response.body || [])
    ).subscribe((result: JobTraceWithUserInfoDto[]) => {
      this.searchesSubject.next(result);
      this.setLoading(false);
    });
  }
}
