import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MessageService } from 'primeng/api';
import {
  DynamicDialogRef,
  DynamicDialogConfig,
  DynamicDialogComponent,
} from 'primeng/dynamicdialog';
import { GroupControllerService } from 'src/app/oapi_client/data_symphony';

@Component({
  selector: 'app-upsert-candidate-modal',
  templateUrl: './upsert-candidate-modal.component.html',
  styleUrls: ['./upsert-candidate-modal.component.scss'],
  providers: [DynamicDialogComponent],
})
export class UpsertCandidateModalComponent {
  public messageModel: string = '';
  public characterCount: number = 0;
  public group: any = {};
  public title: string = '';
  public subTitle: string = '';
  public editMode: boolean = false;
  public values: string[] = [];
  public selectedSkills: any;
  public form: UntypedFormGroup;
  public button: string = 'Save';
  public modalTitle: string = 'Create New Candidate Pool';

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private groupControllerService: GroupControllerService,
    private cdr: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private messageService: MessageService
  ) {
    this.form = this.formBuilder.group({
      name: new UntypedFormControl(
        this.config.data ? this.config.data.name : '',
        [Validators.required, Validators.minLength(3)]
      ),
      description: new UntypedFormControl(
        this.config.data ? this.config.data.description : '',
        [Validators.required]
      ),
      values: new UntypedFormControl(
        this.config.data
          ? this.config.data.keywordList?.split(',')
          : this.values,
        [Validators.required]
      ),
    });
  }

  ngOnInit(): void {
    if (this.config.data != undefined) {
      this.editMode = true;
      this.button = 'Update';
      this.modalTitle = `Update ${this.config.data.name} pool`;
    }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  incrementCount() {
    this.characterCount = this.form.value.description.length;
  }

  removeTag(removeName: string) {
    this.group.tags = this.group.tags.filter((f: string) => f != removeName);
  }

  getClassObject(field: string) {
    return {
      'border-edit': this.editMode,
      'border-invalid':
        !this.form!.get(field)!.valid && this.form!.get(field)!.touched,
    };
  }

  closeDialog() {
    this.ref.close();
  }

  onValueRemoved(event: any): void {
    const index = this.selectedSkills?.indexOf(event.value);
    if (index !== -1) {
      this.selectedSkills.splice(index, 1);
    }
  }

  onValueAdded(event: any): void {
    this.values = this.values.map((str) => str.trim().toLowerCase());
    const uniqueSkills = [...new Set(this.values)];
    this.values = uniqueSkills.flat();
  }

  onSubmit() {
    if (this.form.valid) {
      const trimmedName = this.form.value.name.trim();
      const trimmedDescription = this.form.value.description.trim();
      if (!this.editMode) {
        this.groupControllerService
          .createGroup({
            name: trimmedName,
            description: trimmedDescription,
            keywordList: this.form.value.values,
          })
          .subscribe(
            () => {
              this.ref.close();
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'You have successfully added a Group',
              });
            },
            (error) => {
              console.log(error, 'error');
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'There is an error while adding a Group.',
              });
            }
          );
      } else {
        this.groupControllerService
          .updateGroup(Number(this.config.data.id), {
            name: trimmedName,
            description: trimmedDescription,
            keywordList: this.form.value.values,
          })
          .subscribe(
            () => {
              this.ref.close();
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'You have successfully updated a Group',
              });
            },
            (error) => {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'There is an error while adding a Group.',
              });
              console.log(error, 'error');
            }
          );
      }
    }
  }

  removeKeyword(item: string): void {
    const valuesControl = this.form.get('values');
    if (valuesControl && valuesControl.value) {
      const updatedValues = valuesControl.value.filter(
        (value: string) => value !== item
      );
      valuesControl.setValue(updatedValues);
    }
  }
}
