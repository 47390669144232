import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { AdminLayoutComponent } from './layout/admin-layout/admin-layout.component';
import { FooterComponent } from './layout/footer/footer.component';
import { MenuComponent } from './menu/menu.component';
import { TopBarComponent } from './layout/top-bar/top-bar.component';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '../shared/shared.module';
import { AdminRoutingModule } from './admin-routing.module';
import { NewSearchComponent } from './new-search/new-search.component';
import { ProfileListComponent } from './profile-list/profile-list.component';
import { ProfileDetailsComponent } from './profile-details/profile-details.component';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { OfflineProfileListComponent } from './offline-profile-list/offline-profile-list.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { PreviousHuntsComponent } from '../shared/previous-hunts/previous-hunts.component';
import { ExperienceLocationModalComponent } from '../shared/experience-location-modal/experience-location-modal.component';
import { IndustryEducationModalComponent } from '../shared/industry-education-modal/industry-education-modal.component';
import { BreadcrumbComponent } from '../shared/breadcrumb/breadcrumb.component';
import { UserProfileDetailsComponent } from '../shared/user-profile-details/user-profile-details';
import { ModalComponent } from '../shared/modal/modal.component';
import { CandidatePoolComponent } from './candidate-pool/candidate-pool.component';
import { CandidatePoolMemberComponent } from './candidate-pool-member/candidate-pool-member.component';
import { UpsertCandidateModalComponent } from './candidate-pool/upsert-candidate-modal/upsert-candidate-modal.component';
import { StyleClassModule } from 'primeng/styleclass';
import { CountryModalComponent } from './admin-home/country-modal/country-modal.component';
import { HrProviderDetailsComponent } from './hr-provider-details/hr-provider-details.component';
import { OutgoingRequestDetailsComponent } from './outgoing-requests/outgoing-request-details/outgoing-request-details.component';
import { HrProvidersListComponent } from './hr-providers/hr-providers-list/hr-providers-list.component';
import { FiltersModalComponent } from '../shared/filters-modal/filters-modal.component';
import { HrProviderComponent } from './hr-providers/hr-providers-list/hr-provider/hr-provider.component';
import { RatingModule } from 'primeng/rating';
import { TabMenuModule } from 'primeng/tabmenu';
import { UpsertOutgoingRequestComponent } from './hr-providers/outgoing-requests-list/upsert-outgoing-request/upsert-outgoing-request.component';
import { OutgoingRequestsListComponent } from './hr-providers/outgoing-requests-list/outgoing-requests-list.component';
import { OutgoingRequestItemComponent } from './hr-providers/outgoing-requests-list/outgoing-request-item/outgoing-request-item.component';
import { ConfirmDialogOutgoingRequestComponent } from './hr-providers/outgoing-requests-list/upsert-outgoing-request/confirm-dialog-outgoing-request/confirm-dialog-outgoing-request.component';
import { SendCounterOfferComponent } from './negotiation-dialogs/send-counter-offer/send-counter-offer.component';
import { ConfirmationDialogComponent } from './negotiation-dialogs/confirmation-dialog/confirmation-dialog.component';
import { SuccessDialogComponent } from './negotiation-dialogs/success-dialog/success-dialog.component';
import { TalentDiscoveryModalComponent } from './talent-discovery-modal/talent-discovery-modal.component';
import { CandidatePoolModalComponent } from './candidate-pool-modal/candidate-pool-modal.component';
import { ErrorDialogComponent } from './negotiation-dialogs/error-dialog/error-dialog.component';
import { NotesModalComponent } from '../shared/notes-modal/notes-modal.component';
import { LoadingSpinnerComponent } from '../shared/loading-spinner/loading-spinner.component';
import { CommentModalComponent } from '../shared/comments-modal/comment-modal.component';
import { ProcessCandidatesTableComponent } from './received-requests/received-request-details/process-candidates-table/process-candidates-table.component';
import { UploadVideoDialogComponent } from './received-requests/received-request-details/process-candidates-table/upload-video-dialog/upload-video-dialog.component';
import { ReceivedRequestDetailsComponent } from './received-requests/received-request-details/received-request-details.component';
import { ReceivedRequestsComponent } from './received-requests/received-requests.component';
import { OutgoingRequestProposalsComponent } from './outgoing-requests/outgoing-request-details/outgoing-request-proposals/outgoing-request-proposals.component';
import { OutgoingRequestReviewProviderComponent } from './outgoing-requests/outgoing-request-details/outgoing-request-review-provider/outgoing-request-review-provider.component';
import { OutgoingRequestsComponent } from './outgoing-requests/outgoing-requests.component';
import { TeamManagementComponent } from './team-management/team-management.component';
import { InviteMemberModalComponent } from './team-management/invite-member-modal/invite-member-modal.component';
import { SidebarModule } from 'primeng/sidebar';
import { AccordionModule } from 'primeng/accordion';
import { AdvancedFilterSidebarComponent } from '../shared/advanced-filter-sidebar/advanced-filter-sidebar.component';
import { OutgoingRequestTypeComponent } from './outgoing-requests/outgoing-request-type/outgoing-request-type.component';
import { TeamManagamentPrivilegesModalComponent } from './team-management/team-managament-privileges-modal/team-managament-privileges-modal.component';
import { PrivilegesModalComponent } from './privileges-modal/privileges-modal.component';
import { QuestionDialogComponent } from './negotiation-dialogs/question-dialog/question-dialog.component';
import { AnswersQuestionsModalComponent } from '../shared/answers-questions-modal/answers-questions-modal.component';
import { JobAdsComponent } from './job-ads/job-ads.component';
import { JobAdsPreviewDialogComponent } from './job-ads/job-ads-preview-dialog/job-ads-preview-dialog.component';
import { JobAdsEditDialogComponent } from './job-ads/job-ads-edit-dialog/job-ads-edit-dialog.component';
import { JobAdsScreenQuestionsComponent } from './job-ads/job-ads-screen-questions/job-ads-screen-questions.component';
import { JobAdsPreviewComponent } from './job-ads/job-ads-preview/job-ads-preview.component';
import { JobAdsPreviewCandidateTableComponent } from './job-ads/job-ads-preview/job-ads-preview-candidate-table/job-ads-preview-candidate-table.component';
import { JobAdsFilterComponent } from '../shared/job-ads-filter/job-ads-filter.component';
import { JobAdsPreviewCandidateComponent } from './job-ads/job-ads-preview/job-ads-preview-candidate/job-ads-preview-candidate.component';
import { JobAdsProcessDialogComponent } from './job-ads/job-ads-preview/job-ads-process-dialog/job-ads-process-dialog.component';
import { OutgoingRequestProposalsQuestionsDialogComponent } from './outgoing-requests/outgoing-request-details/outgoing-request-proposals/outgoing-request-proposals-questions-dialog/outgoing-request-proposals-questions-dialog.component';

@NgModule({
  declarations: [
    AdminHomeComponent,
    AdminLayoutComponent,
    FooterComponent,
    MenuComponent,
    TopBarComponent,
    NewSearchComponent,
    ProfileListComponent,
    ProfileDetailsComponent,
    OfflineProfileListComponent,
    PreviousHuntsComponent,
    ExperienceLocationModalComponent,
    IndustryEducationModalComponent,
    BreadcrumbComponent,
    UserProfileDetailsComponent,
    ModalComponent,
    CandidatePoolComponent,
    CandidatePoolMemberComponent,
    UpsertCandidateModalComponent,
    CountryModalComponent,
    HrProviderDetailsComponent,
    OutgoingRequestDetailsComponent,
    HrProvidersListComponent,
    FiltersModalComponent,
    HrProviderComponent,
    OutgoingRequestProposalsComponent,
    OutgoingRequestsComponent,
    OutgoingRequestsListComponent,
    OutgoingRequestItemComponent,
    UpsertOutgoingRequestComponent,
    ConfirmDialogOutgoingRequestComponent,
    ReceivedRequestsComponent,
    ReceivedRequestDetailsComponent,
    SendCounterOfferComponent,
    ConfirmationDialogComponent,
    SuccessDialogComponent,
    TalentDiscoveryModalComponent,
    CandidatePoolModalComponent,
    ProcessCandidatesTableComponent,
    UploadVideoDialogComponent,
    ErrorDialogComponent,
    NotesModalComponent,
    OutgoingRequestReviewProviderComponent,
    LoadingSpinnerComponent,
    CommentModalComponent,
    TeamManagementComponent,
    InviteMemberModalComponent,
    AdvancedFilterSidebarComponent,
    OutgoingRequestTypeComponent,
    TeamManagamentPrivilegesModalComponent,
    PrivilegesModalComponent,
    QuestionDialogComponent,
    AnswersQuestionsModalComponent,
    JobAdsComponent,
    JobAdsPreviewDialogComponent,
    JobAdsEditDialogComponent,
    JobAdsScreenQuestionsComponent,
    JobAdsPreviewComponent,
    JobAdsPreviewCandidateTableComponent,
    JobAdsFilterComponent,
    JobAdsPreviewCandidateComponent,
    JobAdsProcessDialogComponent,
    OutgoingRequestProposalsQuestionsDialogComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AdminRoutingModule,
    SharedModule,
    TieredMenuModule,
    BreadcrumbModule,
    StyleClassModule,
    RatingModule,
    TabMenuModule,
    SidebarModule,
    AccordionModule
  ],
  providers: [DatePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AdminModule {}
