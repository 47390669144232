/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { CreateLinkedinProfileFilterRequest } from '../models/CreateLinkedinProfileFilterRequest';
import type { CreateLinkedinSearchLinkJobRequest } from '../models/CreateLinkedinSearchLinkJobRequest';
import type { DSResponseInteger } from '../models/DSResponseInteger';
import type { DSResponseLinkedinProfileDto } from '../models/DSResponseLinkedinProfileDto';
import type { DSResponseListString } from '../models/DSResponseListString';
import type { DSResponsePageEntryStringListLinkedinProfileCountryStatisticsModel } from '../models/DSResponsePageEntryStringListLinkedinProfileCountryStatisticsModel';
import type { DSResponsePageLinkedinMiniProfileDto } from '../models/DSResponsePageLinkedinMiniProfileDto';
import type { DSResponsePageLinkedinProfileDto } from '../models/DSResponsePageLinkedinProfileDto';
import type { DSResponseString } from '../models/DSResponseString';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class LinkedinProfilesControllerService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @param requestBody
     * @returns DSResponsePageLinkedinMiniProfileDto OK
     * @throws ApiError
     */
    public filter1(
        requestBody: CreateLinkedinProfileFilterRequest,
    ): Observable<DSResponsePageLinkedinMiniProfileDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/linkedin/profile/filter',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns DSResponseString OK
     * @throws ApiError
     */
    public getLinkedinProfiles(
        requestBody: CreateLinkedinSearchLinkJobRequest,
    ): Observable<DSResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/linkedin/profile/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param profileId
     * @returns DSResponseLinkedinProfileDto OK
     * @throws ApiError
     */
    public getProfile(
        profileId: string,
    ): Observable<DSResponseLinkedinProfileDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/linkedin/profile/{profileId}',
            path: {
                'profileId': profileId,
            },
        });
    }
    /**
     * @param userId
     * @param input
     * @returns DSResponseListString OK
     * @throws ApiError
     */
    public suggestKeyword(
        userId: number,
        input: string,
    ): Observable<DSResponseListString> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/linkedin/profile/suggestKeyword',
            query: {
                'userId': userId,
                'input': input,
            },
        });
    }
    /**
     * @returns DSResponseInteger OK
     * @throws ApiError
     */
    public totalProfileCount(): Observable<DSResponseInteger> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/linkedin/profile/statistics/totalProfileCount',
        });
    }
    /**
     * @returns DSResponseInteger OK
     * @throws ApiError
     */
    public lastWeekProfileCount(): Observable<DSResponseInteger> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/linkedin/profile/statistics/lastWeekProfileCount',
        });
    }
    /**
     * @returns DSResponseInteger OK
     * @throws ApiError
     */
    public lastMonthProfileCount(): Observable<DSResponseInteger> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/linkedin/profile/statistics/lastMonthProfileCount',
        });
    }
    /**
     * @returns DSResponseInteger OK
     * @throws ApiError
     */
    public lastDayProfileCount(): Observable<DSResponseInteger> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/linkedin/profile/statistics/lastDayProfileCount',
        });
    }
    /**
     * @param pageSize
     * @param pageIndex
     * @returns DSResponsePageEntryStringListLinkedinProfileCountryStatisticsModel OK
     * @throws ApiError
     */
    public countryExperienceStatistic(
        pageSize?: number,
        pageIndex?: number,
    ): Observable<DSResponsePageEntryStringListLinkedinProfileCountryStatisticsModel> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/linkedin/profile/statistics/countryExperienceStatistic',
            query: {
                'pageSize': pageSize,
                'pageIndex': pageIndex,
            },
        });
    }
    /**
     * @param jobId
     * @param pageIndex
     * @returns DSResponsePageLinkedinProfileDto OK
     * @throws ApiError
     */
    public getResults(
        jobId: string,
        pageIndex?: number,
    ): Observable<DSResponsePageLinkedinProfileDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/linkedin/profile/results/{jobId}',
            path: {
                'jobId': jobId,
            },
            query: {
                'pageIndex': pageIndex,
            },
        });
    }
}
