<div class="card-request" [routerLink]="['/outgoing-request-details', request.id]" [state]="{ providers: providers}">
    <div class="employer-name">
        <div class="employer">
            <div class="info">
                <div style="display: inline-flex;">
                    <div class="name">{{request.requirement?.name}}</div>
                    <div class="status"
                    [ngClass]="{
                        'succeed': request.status === 'SUCCEED' ||  request?.status === 'IN_REVIEW',
                        'pending':  request.status === 'PENDING',
                        'created':  request.status === 'CREATED',
                        'in-progress':  request.status === 'IN_PROGRESS'
                      }">
                        <div class="status-text">{{request.status}}</div>
                    </div>
                </div>
                <div>
                    <div class="overflow-text">
                        <span>Sent to: <span class="provider-names ">{{getProviderNames()}}</span></span>
                    </div>
                </div>
            </div>

        </div>
        <div class="price-details">
            <div>
                <span>Price: <span class="budget">{{ budgetDisplay }}
                </span></span>
            </div>
            <span class="date">{{getDate(request.createdAt!)}}</span>
        </div>
    </div>
    <div class="edit-button">
        <button pTooltip="Edit">
            <img src="../../../assets/images/edit.svg" alt="Edit Image"
                routerLink="/outgoing-requests-upsert/{{request.id}}" />
        </button>
    </div>
</div>