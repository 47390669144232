import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogComponent } from 'primeng/dynamicdialog';
import { ProcessUtilsService } from 'src/app/helpers/process-utils.service';
import {
  CompanyControllerService,
  OutgoingProcessDto,
  ProcessControllerService,
  UserControllerService,
} from 'src/app/oapi_client/data_symphony';
import { ConfirmationDialogComponent } from '../negotiation-dialogs/confirmation-dialog/confirmation-dialog.component';
import { SuccessDialogComponent } from '../negotiation-dialogs/success-dialog/success-dialog.component';
import { stat } from 'fs';
import { ErrorDialogComponent } from '../negotiation-dialogs/error-dialog/error-dialog.component';

@Component({
  selector: 'app-outgoing-requests',
  templateUrl: './outgoing-requests.component.html',
  styleUrls: ['./outgoing-requests.component.scss'],
  providers: [DatePipe],
})
export class OutgoingRequestsComponent {
  processes: any[] = [];
  filteredProcesses: any[] = [];
  selectedTab: string = 'all';
  page: number = 1;
  totalRecords: number = 0;
  pageSize: number = 5;
  searchText: string = '';
  isLoading: boolean = true;
  emptyMessage: string = 'No outgoing request.';
  emptyParaph: string = 'You haven‘t created any request yet.'
  loggedMember: boolean = false;

  constructor(
    private processManagementService: CompanyControllerService,
    private router: Router,
    private processUtils: ProcessUtilsService,
    private dialogService: DialogService,
    private processService: ProcessControllerService,
    private authService: UserControllerService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.getRequests();
    this.checkIfMember();
  }

  getRequests(): void {
    this.processManagementService.getOutgoingRequest().subscribe({
      next: (response) => {
        this.processes =
          response?.body!.sort(
            (a, b) =>
              new Date(b.createdAt!).getTime() -
              new Date(a.createdAt!).getTime()
          ) || [];
        this.filterProcesses('all');
        this.isLoading = false;
      },
      error: (error) => {
        console.error('Error fetching outgoing requests:', error);
        this.isLoading = false;
      },
    });
  }

  filterProcesses(status: string): void {
    this.selectedTab = status;
    this.page = 1;
    let filtered = this.processes;

    if(status ==='INTERNAL'){
      filtered = filtered.filter((process) => process.isInternal);
    }

    else if(status ==='EXTERNAL'){
      filtered = filtered.filter((process) => !process.isInternal);
    }

    else if (status !== 'all') {
      filtered = filtered.filter((process) => process.status === status);
    }

    if (this.searchText) {
      filtered = filtered.filter((process) =>
        process.requirement?.name
          ?.toLowerCase()
          .includes(this.searchText.toLowerCase())
      );
    }

    this.totalRecords = filtered.length;
    if(this.totalRecords == 0){
      this.setMessage(status);
    }
    this.filteredProcesses = filtered.slice(0, this.pageSize);
  }

  setMessage(status: string){
    if(status == 'all'){
      this.emptyMessage = 'No outgoing request.';
      this.emptyParaph = 'You haven‘t created any request yet.'
      return;
    }
    this.emptyMessage = `No ${status.charAt(0).toUpperCase() + status.slice(1).toLocaleLowerCase()} requests.`;
    this.emptyParaph = `There is no ${status.charAt(0).toUpperCase() + status.slice(1).toLocaleLowerCase()} requests yet.`
  }

  onPageChange(event: any): void {
    this.page = event.page + 1;
    this.updateFilteredProcesses();
  }

  onSearchChange(event: any): void {
    this.searchText = event.target.value;
    this.filterProcesses(this.selectedTab);
  }

  deleteProcess(event: any, processId: number, processName: string) {
    event.stopPropagation();
    this.dialogService
      .open(ConfirmationDialogComponent, {
        data: {
          title: `Are you sure that you want to delete ${processName} request?`,
          paraph: 'Once you confirm you can’t go back.',
        },
      })
      .onClose.subscribe((res) => {
        if (res) {
          this.processService.deleteProcess(processId).subscribe((res) => {
            this.dialogService
              .open(SuccessDialogComponent, { data: { title: 'You have successfully deleted the process.' } })
              .onClose.subscribe(() => {
                this.getRequests();
              });
          });
        }
      }, error=>{
        const ref = this.dialogService.open(ErrorDialogComponent, {
          data: {
            title: "You failed to delete the process.",
            paraph:
              'Please try again.',
          },
        })
        const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
        const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;
    
        const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
        dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
      });
  }

  updateFilteredProcesses(): void {
    const start = (this.page - 1) * this.pageSize;
    const end = this.page * this.pageSize;
    const filtered = this.processes.filter((process) => {
      const matchesStatus =
        this.selectedTab === 'all' || process.status === this.selectedTab;
      const matchesSearch = process.requirement?.name
        ?.toLowerCase()
        .includes(this.searchText.toLowerCase());
      return matchesStatus && matchesSearch;
    });

    this.totalRecords = filtered.length;
    this.filteredProcesses = filtered.slice(start, end);
  }

  navigateToDetails(id: any): void {
    this.router.navigate([`/outgoing-request-details/${id}`]);
  }

  formatDate(dateString: string | undefined): string {
    return this.processUtils.formatDate(dateString);
  }

  getHrProviderNames(process: OutgoingProcessDto): string {
    if (process.status === 'SUCCEED' || process.status === 'IN_PROGRESS') {
      return process.acceptedHRProvider?.name || 'N/A';
    } else {
      return (
        process.hrProviders?.map((provider) => provider.name).join(', ') || ''
      );
    }
  }

  public checkIfMember(){
    this.authService.getUserRoles().subscribe((roles) => {
      if (
        roles.body!.includes('ROLE_EMPLOYER_MEMBER') || 
        roles.body!.includes('ROLE_HR_PROVIDER_MEMBER')
      ) {
        this.loggedMember = true;
      } else {
        this.loggedMember = false;
      }
    });
  }

  approve(process: any, event: any){
    event.stopPropagation();
    const ref = this.dialogService
    .open(ConfirmationDialogComponent, {
      data: {
        title: `Are you sure you want to approve ${process?.requirement?.name} process?`,
        paraph: `It will move the process to the next stage and notify relevant stakeholders.`,
      }
    })

    const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
    const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

    const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
    dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;

    ref.onClose.subscribe(result=>{
      if(result){
        this.processService.approveProcess(process?.id!).subscribe(()=>{
          const ref = this.dialogService.open(SuccessDialogComponent, {
            data: {
              title: 'Successfully approved!',
              paraph:
                'You have successfully approved the process.',
            },
          }).onClose.subscribe(()=>{
            this.getRequests();
          });
        })
      }
    })
  }

  decline(process: any, event: any){
    event.stopPropagation();
    const ref = this.dialogService
    .open(ConfirmationDialogComponent, {
      data: {
        title: `Are you sure you want to reject ${process?.requirement?.name} process?`,
        paraph: `This action is irreversible. Once rejected, the process and its associated data cannot be recovered.`,
      }
    })

    const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
    const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

    const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
    dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;

    ref.onClose.subscribe(result=>{
      if(result){
        this.processService.declineApprove({processId: process?.id!, note: ''}).subscribe(()=>{
          const ref = this.dialogService.open(SuccessDialogComponent, {
            data: {
              title: 'Successfully rejected!',
              paraph:
                'You have successfully rejected the process.',
            },
          }).onClose.subscribe(()=>{
            this.getRequests();
          });
        })
      }
    })
  }
}
