<div class="filter-dropdown" [ngClass]="{ isOpen: isOpen }">
  <button (click)="toggleDropdown()" class="dropdown-button">
    <img src="../../../assets/images/dropdownIcon.svg" class="dropdown-image" />
    Previous Hunts
  </button>
  <div *ngIf="isOpen" class="dropdown-container">
    <div class="dropdown-content">
      <label for="multiSelectInput">User</label>
      <p-multiSelect
        [(ngModel)]="selectedUsers"
        [options]="users"
        optionLabel="fullName"
        [filter]="true"
        placeholder="Select a user"
        id="multiSelectInput"
        (ngModelChange)="onSelect($event, countries)"
      >
      </p-multiSelect>
    </div>

    <div class="dropdown-content">
      <label for="singleSelectInput">Date</label>
      <p-dropdown
        [options]="dateOptions"
        [(ngModel)]="selectedDate"
        placeholder="Select the period"
        optionLabel="label"
        id="singleSelectInput"
      >
      </p-dropdown>
    </div>
    <div class="dropdown-content">
      <label for="multiSelectInput">Country</label>
      <p-multiSelect
        [options]="countries"
        [(ngModel)]="selectedCountries"
        placeholder="Select country"
        optionLabel="name"
        id="multiSelectInput"
        (ngModelChange)="onSelect($event, countries)"
      >
      </p-multiSelect>
    </div>
    <div class="dropdown-content">
      <label for="chipsInput">Keywords</label>
      <p-chips
        [(ngModel)]="values"
        placeholder="Type your keywords and press enter"
        id="chipsInput"
        (onRemove)="onValueRemoved($event)"
        (onAdd)="onValueAdded($event)"
        class="skills"
        [suggestions]="displayedSuggestions"
      >
        <ng-template let-item pTemplate="item">
          <span class="chip-item">{{ item }}</span>
          <img
            src="../../../assets/images/closeIconKeyword.svg"
            alt="remove"
            class="remove-icon"
            (click)="removeKeyword($event,item)"
          /> </ng-template
      ></p-chips>
    </div>

    <div class="dropdown-content">
      <label>Suggested</label>
      <div>
        <span *ngFor="let word of displayedSuggestions">
          <button (click)="addWordToSkills($event, word)" class="keyword">
            {{ word }}
          </button>
        </span>
      </div>
    </div>

    <div (click)="stopEventPropagation($event)">
      <p>
        <p-checkbox
          label="In progress"
          [(ngModel)]="checkbox1"
          [binary]="true"
        ></p-checkbox>
      </p>

      <p-checkbox
        label="Done"
        [(ngModel)]="checkbox2"
        [binary]="true"
      ></p-checkbox>
    </div>

    <div class="buttons-wrapper">
      <button (click)="clearFilter()">Clear All</button>
      <button
        pButton
        label="Apply"
        tooltipPosition="top"
        class="custom-button p-mr-2"
        (click)="applyFilter()"
      ></button>
    </div>
  </div>
</div>
