import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'floating-label-multiselect',
  templateUrl: './floating-label-multiselect.component.html',
  styleUrls: ['./floating-label-multiselect.component.scss']
})
export class FloatingLabelMultiselectComponent {
  @Input() id: string = '';
  @Input() label: string = '';
  @Input() options: any[] = [];
  @Input() selectedOptions: any[] = [];
  @Input() class: string = '';

  @Input() scrollHeight: string = '200px';
  @Input() virtualScroll: boolean = false;
  @Input() lazy: boolean = false;
  @Input() virtualScrollItemSize: number = 42;

  @Output() selectedOptionsChange = new EventEmitter<any[]>();
  @Output() onChange = new EventEmitter<any>();
  @Output() onLazyLoad = new EventEmitter<any>();

  @ViewChild('multiSelectContainer', { static: true }) multiSelectContainer!: ElementRef;
  isFocused: boolean = false;

  onSelectionChange(event: any) {
    this.selectedOptionsChange.emit(this.selectedOptions);
    this.onChange.emit({ event, options: this.options });
  }

  fetchOptions(event: any) {
    this.onLazyLoad.emit(event);
  }

  onFocus() {
    this.isFocused = true;
  }

  onBlur() {
    this.isFocused = this.selectedOptions.length > 0;
  }
}
