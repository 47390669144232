import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from '../helpers/auth-guard.service';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { AdminLayoutComponent } from './layout/admin-layout/admin-layout.component';
import { NewSearchComponent } from './new-search/new-search.component';
import { ProfileListComponent } from './profile-list/profile-list.component';
import { OfflineProfileListComponent } from './offline-profile-list/offline-profile-list.component';
import { UserProfileDetailsComponent } from '../shared/user-profile-details/user-profile-details';
import { CandidatePoolComponent } from './candidate-pool/candidate-pool.component';
import { CandidatePoolMemberComponent } from './candidate-pool-member/candidate-pool-member.component';
import { HrProviderDetailsComponent } from './hr-provider-details/hr-provider-details.component';
import { HrProvidersListComponent } from './hr-providers/hr-providers-list/hr-providers-list.component';
import { OutgoingRequestsComponent } from './outgoing-requests/outgoing-requests.component';
import { OutgoingRequestsListComponent } from './hr-providers/outgoing-requests-list/outgoing-requests-list.component';
import { ReceivedRequestsComponent } from './received-requests/received-requests.component';
import { UpsertOutgoingRequestComponent } from './hr-providers/outgoing-requests-list/upsert-outgoing-request/upsert-outgoing-request.component';
import { ReceivedRequestDetailsComponent } from './received-requests/received-request-details/received-request-details.component';
import { roleGuard } from '../helpers/role-guard.service';
import { OutgoingRequestDetailsComponent } from './outgoing-requests/outgoing-request-details/outgoing-request-details.component';
import { TeamManagementComponent } from './team-management/team-management.component';
import { OutgoingRequestTypeComponent } from './outgoing-requests/outgoing-request-type/outgoing-request-type.component';
import { privilegesGuard } from '../helpers/privileges-guard.service';
import { JobAdsComponent } from './job-ads/job-ads.component';
import { JobAdsEditDialogComponent } from './job-ads/job-ads-edit-dialog/job-ads-edit-dialog.component';
import { JobAdsScreenQuestionsComponent } from './job-ads/job-ads-screen-questions/job-ads-screen-questions.component';
import { JobAdsPreviewComponent } from './job-ads/job-ads-preview/job-ads-preview.component';
import { JobAdsPreviewCandidateComponent } from './job-ads/job-ads-preview/job-ads-preview-candidate/job-ads-preview-candidate.component';

const routes: Routes = [
  {
    path: '',

    component: AdminLayoutComponent,
    children: [
      {
        path: 'dashboard',
        component: AdminHomeComponent,
        canActivate: [authGuard],
      },
      {
        path: 'talent-hunt',
        canActivate: [authGuard, () => privilegesGuard(['TALENT_HUNT_&_TALENT_DISCOVERY'])],
        component: NewSearchComponent,
      },
      {
        path: 'profile-list',
        canActivate: [authGuard, () => privilegesGuard(['TALENT_HUNT_&_TALENT_DISCOVERY'])],
        component: ProfileListComponent,
      },
      {
        path: 'talent-discovery',
        canActivate: [authGuard, () => privilegesGuard(['TALENT_HUNT_&_TALENT_DISCOVERY'])],
        component: OfflineProfileListComponent,
      },
      {
        path: 'profile/:id',
        canActivate: [authGuard, () => privilegesGuard(['TALENT_HUNT_&_TALENT_DISCOVERY'])],
        component: UserProfileDetailsComponent,
      },
      {
        path: 'candidate-pool',
        canActivate: [authGuard, () => privilegesGuard(['CANDIDATE_POOLS'])],
        component: CandidatePoolComponent,
      },
      {
        path: 'candidate-member/:id',
        canActivate: [authGuard, () => privilegesGuard(['TALENT_HUNT_&_TALENT_DISCOVERY'])],
        component: CandidatePoolMemberComponent,
      },
      {
        path: 'provider-details/:id',
        canActivate: [authGuard,],
        component: HrProviderDetailsComponent,
      },
      {
        path: 'outgoing-request-details/:id',
        canActivate: [authGuard, () => privilegesGuard(['PROCESS_MANAGEMENT'])],
        component: OutgoingRequestDetailsComponent,
      },
      {
        path: 'hr-providers',
        canActivate: [authGuard, () => privilegesGuard(['PROCESS_MANAGEMENT'])],
        component: HrProvidersListComponent,
      },
      {
        path: 'hr-providers/:id',
        canActivate: [authGuard, () => privilegesGuard(['PROCESS_MANAGEMENT'])],
        component: HrProvidersListComponent,
      },
      {
        path: 'outgoing-request',
        canActivate: [authGuard, () => privilegesGuard(['PROCESS_MANAGEMENT'])],
        component: OutgoingRequestsComponent,
      },
      {
        path: 'outgoing-requests',
        canActivate: [authGuard, () => privilegesGuard(['PROCESS_MANAGEMENT'])],
        component: OutgoingRequestsListComponent,
      },
      {
        path: 'outgoing-request-type',
        canActivate: [authGuard, () => privilegesGuard(['PROCESS_MANAGEMENT'])],
        component: OutgoingRequestTypeComponent,
      },
      {
        path: 'received-requests',
        canActivate: [authGuard, roleGuard, () => privilegesGuard(['PROCESS_MANAGEMENT'])],
        component: ReceivedRequestsComponent,
      },
      {
        path: 'job-ads',
        canActivate: [authGuard, () => privilegesGuard(['PROCESS_MANAGEMENT'])],
        component: JobAdsComponent,
      },
      {
        path: 'job-ads/add',
        canActivate: [authGuard, () => privilegesGuard(['PROCESS_MANAGEMENT'])],
        component: JobAdsEditDialogComponent,
      },
      {
        path: 'job-ads/questions',
        canActivate: [authGuard, () => privilegesGuard(['PROCESS_MANAGEMENT'])],
        component: JobAdsScreenQuestionsComponent,
      },
      {
        path: 'job-ads/preview/:id',
        canActivate: [authGuard, () => privilegesGuard(['PROCESS_MANAGEMENT'])],
        component: JobAdsPreviewComponent,
      },
      {
        path: 'job-ads/preview-candidate/:id',
        canActivate: [authGuard, () => privilegesGuard(['PROCESS_MANAGEMENT'])],
        component: JobAdsPreviewCandidateComponent,
      },
      {
        path: 'received-request-details/:id',
        canActivate: [authGuard, () => privilegesGuard(['PROCESS_MANAGEMENT'])],
        component: ReceivedRequestDetailsComponent,
      },
      {
        path: 'process-request-form/:id',
        canActivate: [authGuard, () => privilegesGuard(['PROCESS_MANAGEMENT'])],
        component: UpsertOutgoingRequestComponent,
      },
      {
        path: 'process-request-form',
        canActivate: [authGuard, () => privilegesGuard(['PROCESS_MANAGEMENT'])],
        component: UpsertOutgoingRequestComponent,
      },
      {
        path: 'team-management',
        canActivate: [authGuard, () => privilegesGuard(['TEAM_MANAGEMENT'])],
        component: TeamManagementComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
