import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { error } from 'console';
import { Observable } from 'rxjs';
import { CompanyControllerService, CreateHRCompanyFilterRequest, DSResponseListCompanyDto, ProcessCandidateStatusControllerService, ProcessCandidateStatusNoteDto } from 'src/app/oapi_client/data_symphony';

@Component({
  selector: 'app-comment-modal',
  templateUrl: './comment-modal.component.html',
  styleUrls: ['./comment-modal.component.scss']
})
export class CommentModalComponent {
  public isOpen=false;
  public note: string = '';
  public errorMessage: string = '';
  constructor(private elementRef: ElementRef, private datePipe: DatePipe, 
  private talentService: ProcessCandidateStatusControllerService) { }

  ngOnInit(){
    console.log('tu sam')
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  updateStep(){
  }
}
