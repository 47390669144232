<p-dialog header="Invite people" [(visible)]="display" [modal]="true" [styleClass]="'custom-dialog'"
  (onHide)="resetData()">
  <div class="dialog-subtitle">
    Enter email of people you want to invite and give them privileges.
  </div>
  <div class="dialog-content">
    <div class="flex justify-content-space-between">
      <div class="email-input pr-2">
        <label>First Name</label>
        <input [(ngModel)]="firstName" placeholder="First Name" type="text" />
      </div>
      <div class="email-input">
        <label>Last Name</label>
        <input [(ngModel)]="lastName" placeholder="Last Name" type="text" />
      </div>
    </div>
    <div class="email-input">
      <label>Email</label>
      <input [(ngModel)]="email" placeholder="Email" (onAdd)="validateEmail($event)"/>
      
      <div *ngIf="emailError" class="error-message">
        <img src="../../../assets/images/error.svg" alt="error" />
        {{ emailError }}
      </div>
      <div *ngIf="errorMessage" class="error-message">
        {{errorMessage}}
      </div>
    </div>

    <div class="privileges-section">
      <label>Mark privileges (you can choose them individually or in bulk)</label>
      <div class="privileges">
        <div class="privilege-column">
          <div>
            <p-checkbox [(ngModel)]="privileges.talentAcquisition" label="Talent acquisition"
              (onChange)="selectTalentAcquisition()" [binary]="true"
              [ngClass]="{ 'selected-checkbox': privileges.talentAcquisition }">
            </p-checkbox>
            <i class="pi pi-info-circle"
              pTooltip="Allows unrestricted access to all talent-related features, including searching for candidates, adding them to groups, and moving them across different interview stages."
              tooltipPosition="top"></i>
          </div>
          <hr />

          <div>
            <p-checkbox [(ngModel)]="privileges.talentHuntDiscovery" label="Talent hunt & Talent discovery"
              [binary]="true" (onChange)="selectChildPrivileges()"
              [ngClass]="{ 'selected-checkbox': privileges.talentHuntDiscovery }">
            </p-checkbox>
            <i class="pi pi-info-circle"
              pTooltip="Grants permission to search for candidates, add them to our database, and review their profiles."
              tooltipPosition="top"></i>
          </div>
          <div>
            <p-checkbox [(ngModel)]="privileges.candidatePools" label="Candidate pools" [binary]="true"
              (onChange)="selectChildPrivileges()" [ngClass]="{ 'selected-checkbox': privileges.candidatePools }">
            </p-checkbox>
            <i class="pi pi-info-circle"
              pTooltip="Enables viewing of groups that other team members have created from our talent database."
              tooltipPosition="top"></i>
          </div>
        </div>

        <div class="privilege-column">
          <div>
            <p-checkbox [(ngModel)]="privileges.other" label="Other" (onChange)="selectOther()" [binary]="true"
              [ngClass]="{ 'selected-checkbox': privileges.other }"></p-checkbox>
          </div>
          <hr />
          <div>
            <p-checkbox [(ngModel)]="privileges.processManagement" label="Process management"
              (onChange)="updatePrivileges()" [binary]="true"
              [ngClass]="{ 'selected-checkbox': privileges.processManagement }">
            </p-checkbox>
            <i class="pi pi-info-circle"
              pTooltip="Allows uploading of interview videos, viewing candidates within specific stages, and moving them between stages."
              tooltipPosition="top"></i>
          </div>
          <div>
            <p-checkbox [(ngModel)]="privileges.teamManagement" label="Team management" [binary]="true"
              [ngClass]="{ 'selected-checkbox': privileges.teamManagement }">
            </p-checkbox>
            <i class="pi pi-info-circle" pTooltip="Enables the member to invite additional team members."
              tooltipPosition="top"></i>
          </div>

          <div>
            <p-checkbox [(ngModel)]="privileges.jobAdvertisement" label="Job advertisement management" [binary]="true"
              [ngClass]="{ 'selected-checkbox': privileges.jobAdvertisement }">
            </p-checkbox>
            <i class="pi pi-info-circle"
              pTooltip="Allows the creation and external posting of job ads, as well as the option to publish existing requests externally."
              tooltipPosition="top"></i>
          </div>
        </div>
      </div>
    </div>

    <button class="general-button-purple" (click)="invite()" [disabled]="isInviteButtonDisabled()">
      Invite
    </button>
    <hr />
    <div class="invited-list">
      <p>Invited people</p>

      <div class="user-card" *ngFor="let user of invitedUsers">
        <div class="column">
          <img src="../../../../assets/layout/images/userIcon.svg" alt="profile image" class="profile-image" />
          <div class="email" pTooltip="{{ user.email }}">{{ user.email }}</div>
        </div>
        <div class="column  privileges-column">
          <div *ngFor="let privilege of user.displayPrivileges.split(',')">
            {{ privilege.trim() }}
          </div>
        </div>
        <div class="column">
          <a (click)="cancelInvitation(user.email)">Cancel Invitation</a>
        </div>
      </div>
    </div>
  </div>
</p-dialog>