<div class="layout-topbar">
  <div class="topbar-line">
    <div class="topbar-left">
      <img
        id="logo-horizontal"
        src="assets/layout/images/logo.svg"
        alt="diamond-layout"
        class="horizontal-logo"
        tabindex="0"
      />
      <img
        id="logo-mobile"
        alt="diamond-layout"
        class="mobile-logo"
        src="assets/layout/images/logo.svg"
      />
    </div>

    <app-menu> </app-menu>

    <div class="layout-mask modal-in"></div>

    <app-breadcrumb></app-breadcrumb>

    <div class="topbar-right flex">
      <ul class="topbar-menu">
        <li class="notifications-item">
          <a href="#" tabindex="0">
            <img
              src="../../../../assets/layout/images/bell.svg"
              alt="bell image"
              class="bell-image"
            />
          </a>
        </li>

        <li class="profile-item" *ngIf="currentUser$ | async as currentUser">
          <p-menu #menu [model]="items" [popup]="true" appendTo="body"></p-menu>
          <a (click)="menu.toggle($event)">
            <div class="user-role">
              <span class="profile-name">{{ currentUser?.userName }}</span>
              <span class="role">{{ roleLabel }}</span>
            </div>

            <img
              src="../../../../assets/layout/images/userIcon.svg"
              alt="profile image"
              class="profile-image"
              (error)="onImageError($event)" 
              [src]="logoUrl"
            />
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
<p-toast></p-toast>
