<div *ngIf="isLoading">
  <app-loading-spinner></app-loading-spinner>
</div>

<div class="container-top" *ngIf="!isLoading">
  <div class="app-outgoing">
  <div>
    <p>Outgoing requests</p>
    <span>
      Here you’ll find the list of all the requests that you have prepared/sent
      out to other companies. You can edit them, remove them, see progress and
      add new ones.
    </span>
  </div>
  <div>
    <!-- <button class="create-request" *ngIf="process?.status === 'IN_PROGRESS' && process?.isInternal" (click)="openJobAdDialog()">
      Publish as Job Ad
    </button> -->
  </div>
  </div>
</div>

<div class="wrapper expanded" *ngIf="!isLoading">
  <img
    src="../../../assets/images/closeIcon.svg"
    alt="close"
    class="close-icon"
    (click)="navigateToPreviousPage()"
  />

  <div style="display: flex; justify-content: space-between; width: 100%">
    <div class="company-info">
      <span>{{formatDate(process?.createdAt)}}</span>
      <div class="provider-info">
        <h2 class="title">{{process?.requirement?.name ?? ''}}</h2>
        <p
          *ngIf="process?.status!== 'IN_REVIEW'"
          class="status"
          [ngClass]="{
                'succeed': process?.status === 'SUCCEED', 
                'pending': process?.status === 'PENDING',
                'in-progress': process?.status === 'IN_PROGRESS',
                'created': process?.status === 'CREATED' || process?.status === 'TO_BE_APPROVED',
              }"
        >
          <ng-container *ngIf="process?.status === 'SUCCEED'; else otherStatus">
            done
          </ng-container>
          <ng-template
            #otherStatus
            >{{ process?.status | titlecase }}</ng-template
          >
        </p>
        <button
          class="button-purple"
          (click)="acceptAsCompleted()"
          *ngIf="process?.status === 'IN_REVIEW'"
        >
          Accept as completed
        </button>
       
        <div  *ngIf="process?.status === 'TO_BE_APPROVED'" style="display: flex;">
          <button class="status-approve approve" (click)="approve($event)">Approve</button>
          <button class="status-approve decline" (click)="decline($event)">Decline</button>
        </div>

        <div class="image-container">
          <img
            src="../../../assets/images/info.svg"
            alt="info"
            *ngIf="process?.status === 'IN_REVIEW'"
            (mouseover)="showInfo()"
            (mouseleave)="hideInfo()"
            [ngClass]="{'hovered': isInfoVisible}"
          />
          <div *ngIf="isInfoVisible" class="info-text">
            HR company completed their process. If it’s all set, click the
            button.
          </div>
        </div>

        <span
          *ngIf="(process?.status == 'PENDING' || process?.status == 'CREATED')"
          (click)="assignToHrProvider()"
          style="cursor: pointer;"
          >Assign To HR Provider</span
        >
        <img
          *ngIf="process?.status == 'PENDING' || process?.status == 'CREATED'"
          src="../../../assets/images/whiteEditIcon.svg"
          alt="edit"
          routerLink="/process-request-form/{{id}}"
        />
        <img
          *ngIf="process?.status == 'PENDING' || process?.status == 'CREATED'"
          src="../../../assets/images/deleteIcon.svg"
          alt="Delete icon"
        />
      </div>
    </div>
    <div style="margin-right: 40px;" *ngIf="adminEmployer">
      <p class="mb-0">Assignee</p>
      <p-dropdown
        class="dropdown1"
        [options]="users"
        [(ngModel)]="selectedUser"
        optionLabel="email"
        placeholder="Select assigne"
        (onChange)="setAssigne()"
      ></p-dropdown>
    </div>
  </div>
  <div class="container">
    <div class="container-wrapper">
      <div>
        <h2>Deadline</h2>
        <p>{{process?.deadline}}</p>
      </div>
      <div>
        <h2>Candidates needed</h2>
        <p class="text-center text-bold">{{process?.numberOfHires}}</p>
      </div>
      <div>
        <h2>Experience</h2>
        <p>
          {{ formatExperience(process?.requirement?.minExperience, process?.requirement?.maxExperience) }}
        </p>
      </div>
      <div>
        <h2>Language(s)</h2>
        <p>{{ formatLanguages(process?.requirement?.languages) }}</p>
      </div>
      <div>
        <h2>Location</h2>
        <p>{{process?.requirement?.location}}</p>
      </div>
      <div>
        <h2>Working type</h2>
        <p>{{ formatWorkingTypes(process?.requirement?.workingTypes) }}</p>
      </div>
      <div>
        <h2>Job type</h2>
        <p>{{ formatJobTypes(process?.requirement?.jobTypes) }}</p>
      </div>
    </div>
    <div class="container-wrapper second-wrapper">
      <div>
        <h2>Salary range</h2>
        <p>{{process?.salaryRange}}</p>
      </div>
      <div *ngIf="process?.requirement?.keywordList">
        <h2>Required capabilities</h2>
        <div class="skills">
          <ng-container
            *ngFor="let keyword of process?.requirement?.keywordList"
          >
            <span>{{ keyword }}</span>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="description" *ngIf="process?.requirement?.description">
    <h2>Job description</h2>
    <p>{{ process?.requirement?.description }}</p>
  </div>
  <hr />
  <div *ngIf="process?.note">
    <h2>Note</h2>
    <p>{{ process?.note }}</p>
    <hr />
  </div>
  <div class="process-top">
    <h2>Process</h2>
    <p
      *ngIf="process?.status=== 'IN_REVIEW'"
      class="status"
      [ngClass]="{
    'succeed': process?.status === 'IN_REVIEW'}"
    >
      To be reviewed
    </p>
  </div>

  <p>
    When HR company marks the whole process as ‘To be reviewed‘, you can close
    the job.
  </p>

  <div *ngFor="let step of processSteps">
    <div class="process-item" [ngClass]="{'open': step.isOpen}">
      <div class="process">
        <div
          class="not-completed"
          *ngIf="process?.status !== 'IN_REVIEW' && process?.status !== 'SUCCEED'"
        ></div>
        <div
          class="not-completed"
          *ngIf="process?.status === 'IN_REVIEW' || process?.status === 'SUCCEED'"
        >
          <img src="../../../assets/images/checkmark.svg" />
        </div>
        <div class="step-info">
          <span class="step-name">{{ step.name }}</span>
          <div class="status-container" *ngIf="process?.status !== 'PENDING'">
            <ng-container
              *ngFor="let statusCount of step.displayedStatusCounts"
            >
              <div
                class="status-dot"
                [ngClass]="getStatusDotClass(statusCount.label)"
              ></div>
              <div class="status-counts">
                <span class="status-label"
                  >{{ statusCount.label

                  }}<span class="status-count">({{ statusCount.count }})</span>
                </span>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="process-action">
        <button class="note-button" [disabled]="true" *ngIf="step.isOpen">
          <img
            src="../../../assets/images/note-process.svg"
            alt="Note"
            class="disabled"
          />
        </button>
        <button
          pButton
          pRipple
          icon="pi pi-angle-down"
          class="expand-button"
          (click)="filterCandidates(step)"
          *ngIf="process?.status !== 'PENDING'"
          [ngClass]="{'open': step.isOpen, 'closed': !step.isOpen}"
        ></button>
      </div>
    </div>
    <div class="panel-content" [ngClass]="{'opened': step.isOpen}">
      <app-process-candidates-table
        [candidates]="allCandidatesByProcess"
      ></app-process-candidates-table>
    </div>
  </div>
  <hr />
</div>

<app-outgoing-request-proposals
  *ngIf="process?.status !== 'CREATED' && !isLoading && !process?.isInternal"
  [processId]="id"
></app-outgoing-request-proposals>
<app-modal
  #confirmationModal
  [isVisible]="confirmationModalVisible"
></app-modal>
