import { Component, OnInit } from '@angular/core';
import { AuthHelper } from '../../helpers/auth.helper';
import { map } from 'rxjs/operators';
import {
  DSResponseListJobTraceWithUserInfoDto,
  GroupControllerService,
  JobTraceControllerService,
  JobTraceWithUserInfoDto,
} from '../../oapi_client/data_symphony';
import { LinkedinProfilesControllerService } from 'src/app/oapi_client/data_symphony';
import * as Sentry from '@sentry/browser';
import { Router } from '@angular/router';
interface LinkedinProfileCountryStatisticsModel {
  experience_type?: ExperienceType;
  profile_count?: number;
  retrieval_time?: string;
}

type ExperienceType =
  | 'ZERO_TWO_YEARS'
  | 'TWO_FIVE_YEARS'
  | 'FIVE_EIGHT_YEARS'
  | 'EIGHT_TEN_YEARS'
  | 'TEN_PLUS_YEARS'
  | 'TOTAL';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss'],
})
export class AdminHomeComponent implements OnInit {
  currentUserID: number = 0;
  searchStatistics$: JobTraceWithUserInfoDto[] = [];
  statsCounts = {
    completed: 0,
    started: 0,
    inProgress: 0,
  };
  totalResultCount = 0;
  todayResultCount = 0;
  isLoading: boolean = true;
  userGroupCount = 0;
  teamGroupCount = 0;
  statistics: any[] = [];

  constructor(
    private authHelper: AuthHelper,
    private readonly jobTraceSAervice: JobTraceControllerService,
    private readonly linkedinProfileService: LinkedinProfilesControllerService,
    private readonly groupControllerService: GroupControllerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.authHelper.currentUser.subscribe(
      (user) => (this.currentUserID = user.id)
    );
    this.getSearchStatistics();
    this.getProfileStatistics();
    this.fetchCountryStatistics();
    this.getCandidatePool();
  }

  getSearchStatistics() {
    this.jobTraceSAervice
      .jobLinkedinList()
      .pipe(
        map(
          (response: DSResponseListJobTraceWithUserInfoDto) =>
            response.body || []
        )
      )
      .subscribe(
        (result) => {
          this.searchStatistics$ = result;
          this.statsCounts.started = result.filter(
            (stat) => stat.searchStatus?.toLowerCase() === 'started'
          ).length;
          this.statsCounts.inProgress = result.filter(
            (stat) => stat.searchStatus?.toLowerCase() === 'in_progress'
          ).length;
          this.statsCounts.completed = result.filter(
            (stat) => stat.searchStatus?.toLowerCase() === 'completed'
          ).length;
          this.isLoading = false;
        },
        (error) => {
          Sentry.captureException('System can not get search statistics.', {
            tags: { customTag: 'Dashboard' },
            level: 'error',
          });
          this.isLoading = false;
        }
      );
  }

  getProfileStatistics() {
    this.linkedinProfileService
      .totalProfileCount()
      .pipe()
      .subscribe(
        (result) => {
          this.totalResultCount = result.body || 0;
          this.isLoading = false;
        },
        (error) => {
          Sentry.captureException('System can not get profile statistics.', {
            tags: { customTag: 'Dashboard' },
            level: 'error',
          });
          this.isLoading = false;
        }
      );
    this.linkedinProfileService
      .lastDayProfileCount()
      .pipe()
      .subscribe(
        (result) => {
          this.todayResultCount = result.body || 0;
          this.isLoading = false;
        },
        (error) => {
          Sentry.captureException(
            'System can not get today profile statistics.',
            { tags: { customTag: 'Dashboard' }, level: 'error' }
          );
          this.isLoading = false;
        }
      );
  }

  fetchCountryStatistics(): void {
    this.linkedinProfileService
      .countryExperienceStatistic()
      .subscribe((data) => {
        const content = data.body?.content ?? [];

        this.statistics = content.map((item) => {
          const [countryCode, countryStats] = Object.entries(item)[0] as [
            string,
            LinkedinProfileCountryStatisticsModel[]
          ];

          const stats: Record<ExperienceType, number> = {
            ZERO_TWO_YEARS: 0,
            TWO_FIVE_YEARS: 0,
            FIVE_EIGHT_YEARS: 0,
            EIGHT_TEN_YEARS: 0,
            TEN_PLUS_YEARS: 0,
            TOTAL: 0,
          };

          countryStats.forEach(
            (stat: LinkedinProfileCountryStatisticsModel) => {
              if (stat.experience_type) {
                stats[stat.experience_type] = stat.profile_count ?? 0;
              }
            }
          );

          return {
            country: countryCode,
            ...stats,
          };
        });
        this.statistics.sort((a, b) => b.ALL - a.ALL);
      });
  }

  getCandidatePool() {
    this.groupControllerService.lastDayProfileCount1().subscribe(
      (response) => {
        if (response.body) {
          this.userGroupCount = response.body?.['userGroupCount'];
          this.teamGroupCount = response.body?.['teamGroupCount'];
          this.isLoading = false;
        }
      },
      (error) => {
        Sentry.captureException(
          'System can not get candidate pool statistics.',
          {
            tags: { customTag: 'Dashboard' },
            level: 'error',
          }
        );
        this.isLoading = false;
      }
    );
  }

  navigateToTalentDiscovery(): void {
    this.router.navigate(['/talent-discovery']);
  }

  navigateToTalentHunt(): void {
    this.router.navigate(['/talent-hunt']);
  }

  navigateToCandidatePool(): void {
    this.router.navigate(['/candidate-pool']);
  }
}
