<div class="container-top">
    <div class="app-outgoing">
      <div>
        <p>Outgoing requests</p>
        <span>
          Here you’ll find the list of all the requests that you have
          prepared/sent out to other companies. You can edit them, remove them,
          see progress and add new ones.
        </span>
      </div>
    </div>
  </div>

  <div class="request-container">
    <div class="request-content">
      <h2 class="request-title">Choose request type</h2>
      <p class="request-subtitle">Select where you want to send your request.</p>
      <div class="request-buttons">
        <button class="request-button" [ngClass]="chosenInternal ? 'active': ''" (click)="chosenInternal = true; requestType='internal'">Internal (To my team)</button>
        <button class="request-button" [ngClass]="!chosenInternal ? 'active': ''" (click)="chosenInternal = false;  requestType='external'">External (To HR Provider)</button>
      </div>
    </div>
    <div class="request-actions">
      <button class="cancel-button" routerLink="/outgoing-request">Cancel</button>
      <button class="next-button" routerLink="/outgoing-requests-upsert/{{requestType}}">Next</button>
    </div>
  </div>
  
  