<div
  #filterSidebarContainer
  class="filter-sidebar-container"
  [ngClass]="isFilterSidebarOpen ? 'open' : 'close'"
  [class.sidebar-extended]="isExtendedFilterSidebarOpen"
>
  <button
    class="sidebar-tab"
    [ngClass]="isFilterSidebarOpen ? 'open' : 'close'"
    (click)="toggleFilterSidebar()"
  >
    <img src="../../../assets/images/filter-show-icon.svg" />
  </button>

  <div
    class="filter-sidebar-content"
    [ngClass]="isExtendedFilterSidebarOpen ? 'extended' : 'small'"
  >
    <div
      class="extended-sidebar-content p-0 flex"
      *ngIf="isExtendedFilterSidebarOpen"
    >
      <div class="extend-close-button cursor-pointer">
        <img
          src="../../../assets/images/closeIcon.svg"
          style="width: 15px"
          (click)="toggleExtendedFilterSidebar()"
          alt=""
        />
      </div>
      <div class="content w-full">
        <div class="grid">
          <div class="col-6">
            <div *ngFor="let group of testInputs | slice: 0:3; let i = index" class="flex flex-column mb-3">
                <div class="text-gray-600 font-bold mb-3">{{group.groupName}}</div>
                <div *ngFor="let input of group.members;" class="mb-3">
                    <floating-label-input
                        [id]="input.value"
                        class="extended-input"
                        [label]="input.title"
                        type="text"
                        [(ngModel)]="input.value"
                        (ngModelChange)="setValue(input.ngModel, $event)"
                        (keyup)="onKeyUp($event)">
                        <button class="pin-button" (click)="togglePin(i)">
                            <img
                                    [src]="
                        input.pinned
                          ? '../../../assets/images/pinnedIcon.svg'
                          : '../../../assets/images/unpinnedIcon.svg'
                      "
                                    alt="Pin icon"
                            />
                        </button>
                    </floating-label-input>
                </div>
            </div>
          </div>
          <div class="col-6">
              <div *ngFor="let group of testInputs | slice: 3; let i = index" class="flex flex-column mb-3">
                  <div class="text-gray-600 font-bold mb-3">{{group.groupName}}</div>
                  <div *ngFor="let input of group.members;" class="mb-3">
                      <ng-container *ngIf="input.title === 'Language'; else defaultInput">
                          <div>
                              <floating-label-multiselect
                                  [class]="'extended-multiselect'"
                                  [options]="languages"
                                  [(ngModel)]="selectedLanguages"
                                  [virtualScroll]="true"
                                  [scrollHeight]="'200px'"
                                  [lazy]="true"
                                  [label]="input.title"
                                  [virtualScrollItemSize]="42"
                                  (onLazyLoad)="fetchLanguages($event)"
                                  (onChange)="onSelectAdvanced($event, languages)">
                                  <button class="pin-button" (click)="togglePin(i + 5)">
                                      <img
                                          [src]="
                                            input.pinned
                                              ? '../../../assets/images/pinnedIcon.svg'
                                              : '../../../assets/images/unpinnedIcon.svg'
                                          "
                                          alt="Pin icon"
                                      />
                                  </button>
                              </floating-label-multiselect>

                          </div>
                      </ng-container>
                      <ng-template #defaultInput>
                          <floating-label-input
                              [id]="input.value"
                              class="extended-input"
                              [label]="input.title"
                              type="text"
                              [(ngModel)]="input.value"
                              (ngModelChange)="setValue(input.ngModel, $event)"
                              (keyup)="onKeyUp($event)">
                              <button class="pin-button" (click)="togglePin(i + 5)">
                                  <img
                                      [src]="
                                        input.pinned
                                          ? '../../../assets/images/pinnedIcon.svg'
                                          : '../../../assets/images/unpinnedIcon.svg'
                                        "
                                      alt="Pin icon"/>
                              </button>
                          </floating-label-input>

                      </ng-template>
                  </div>
              </div>

          </div>

        </div>
      </div>
    </div>
    <div #filterSidebarSmall class="small-filter">
      <div>
        <div
          class="flex flex-row gap-1 justify-content-between align-items-center mb-3"
        >
          <h3 class="title m-0">Filters</h3>
          <button
            class="more-filter-button"
            [ngClass]="isExtendedFilterSidebarOpen ? 'active' : ''"
            (click)="toggleExtendedFilterSidebar()"
          >
            <img
              [src]="
                !isExtendedFilterSidebarOpen
                  ? '../../../assets/images/dropdownIcon.svg'
                  : '../../../assets/images/dropdownIconWhite.svg'
              "
              class="dropdown-image"
            />
            More Filters
          </button>
        </div>
        <p
          class="expand-button mb-3"
          type="button"
          (click)="toggleExpandCollapseAccordions()"
        >
          {{ isExpandedAccordionTabs ? "Collapse All" : "Expand All" }}
        </p>
        <p-accordion [activeIndex]="activeAccordionIndexes" [multiple]="true">
          <p-accordionTab *ngIf="hasPinnedFilter" header="Pinned Filters" (click)="onPanelToggle(0)">
            <div *ngFor="let input of pinnedFilters" class="flex flex-column mb-3">
              <label [htmlFor]="input.value">{{input.title}}</label>
              <div>
                <input
                  class="extended-input"
                  pInputText
                  [id]="input.value"
                  [placeholder]="input.title"
                  [(ngModel)]="input.value"
                  (ngModelChange)="setValue(input.ngModel, $event)"
                  (keyup)="onKeyUp($event)"
                />
                <button class="pin-button" (click)="unpinFromSidebar(input)">
                  <img
                    [src]="
                      input.pinned
                        ? '../../../assets/images/pinnedIcon.svg'
                        : '../../../assets/images/unpinnedIcon.svg'
                    "
                    alt="Pin icon"
                  />
                </button>
              </div>
            </div>
          </p-accordionTab>
          <p-accordionTab header="General" (click)="onPanelToggle(0)">
            <div class="flex flex-column mb-3">
                <floating-label-input
                    id="personNameSurname"
                    label="Name"
                    [(model)]="personNameSurname"
                    (keyup)="onKeyUp($event)"
                />
            </div>
            <div class="flex">
                <floating-label-multiselect
                    [options]="locations"
                    [(selectedOptions)]="selectedLocations"
                    [label]="'Country'"
                    [scrollHeight]="'200px'"
                    [virtualScroll]="true"
                    [lazy]="true"
                    [virtualScrollItemSize]="42"
                    (onChange)="onSelect($event)">
                </floating-label-multiselect>

            </div>
          </p-accordionTab>
          <p-accordionTab header="Experience" (click)="onPanelToggle(1)">
            <div class="flex flex-column mb-3">
                <floating-label-multiselect
                    [options]="companies"
                    [(selectedOptions)]="selectedCompanies"
                    [label]="'Company name'"
                    [scrollHeight]="'200px'"
                    [virtualScroll]="true"
                    [lazy]="true"
                    (onLazyLoad)="fetchCompanies($event)"
                    (onChange)="onSelectAdvanced($event, selectedCompanies)">
                </floating-label-multiselect>
            </div>
            <div class="flex flex-column mb-3">
                <floating-label-multiselect
                    [options]="titles"
                    [(selectedOptions)]="selectedTitles"
                    [label]="'Title'"
                    [scrollHeight]="'200px'"
                    [virtualScroll]="true"
                    [lazy]="true"
                    (onLazyLoad)="fetchTitles($event)"
                    (onChange)="onSelectAdvanced($event, selectedTitles)">
                </floating-label-multiselect>
            </div>
            <div class="flex flex-column mb-3">
                <floating-label-input
                    id="experienceAbout"
                    label="Experience about"
                    [(model)]="experienceAbout"
                    (keyup)="onKeyUp($event)"/>
            </div>
            <div class="flex flex-column mb-3">
              <floating-label-multiselect
                [options]="locations"
                [(selectedOptions)]="selectedExperienceLocations"
                [label]="'Experience location'"
                [scrollHeight]="'200px'"
                [virtualScroll]="true"
                [lazy]="true"
                (onChange)="onSelect($event)">
                </floating-label-multiselect>
            </div>
              <div class="flex flex-row p-fluid">
                  <div class="col-6 pl-0">
                      <floating-label-input
                          id="minYears"
                          label="Min. years of experience"
                          [class]="'custom-input number-input'"
                          [(model)]="minYearsInput"
                          (keyup)="onKeyUp($event)"
                          [min]="0"
                          [max]="50"
                          (input)="onMinYearsInput($event)"/>
                  </div>
                  <div class="col-6 pr-1">
                      <floating-label-input
                          id="maxYears"
                          label="Max. years of experience"
                          [class]="'custom-input number-input'"
                          [(model)]="maxYearsInput"
                          (keyup)="onKeyUp($event)"
                          [min]="0"
                          [max]="50"
                          (input)="onMaxYearsInput($event)"/>
                  </div>
              </div>
          </p-accordionTab>
          <p-accordionTab header="Education" (click)="onPanelToggle(2)">
            <div class="flex flex-column gap-2 mb-3">
                <floating-label-multiselect
                    [options]="schools"
                    [(selectedOptions)]="selectedSchools"
                    [label]="'Institution name'"
                    [scrollHeight]="'200px'"
                    [virtualScroll]="true"
                    [lazy]="true"
                    (onLazyLoad)="fetchSchools($event)"
                    (onChange)="onSelectAdvanced($event, selectedSchools)">
                </floating-label-multiselect>
            </div>
            <div class="flex flex-column gap-2 mb-3">
                <floating-label-multiselect
                    [options]="degrees"
                    [(selectedOptions)]="selectedDegrees"
                    [label]="'Degree'"
                    [scrollHeight]="'200px'"
                    [virtualScroll]="true"
                    [lazy]="true"
                    (onLazyLoad)="fetchDegrees($event)"
                    (onChange)="onSelectAdvanced($event, degrees)">
                </floating-label-multiselect>
            </div>
          </p-accordionTab>
          <p-accordionTab header="Skills And Industry" (click)="onPanelToggle(3)">
            <div class="flex flex-column gap-2 mb-3">
              <floating-label-chips
                [label]="'Skills'"
                class="skills"
                [(ngModel)]="values"
                (onRemove)="onValueRemoved($event)"
                (onAdd)="onValueAdded($event)">
                <ng-template let-item pTemplate="item">
                  <span class="chip-item">{{ item }}</span>
                  <img
                    src="../../../assets/images/closeIconKeyword.svg"
                    alt="remove"
                    class="remove-icon"
                    (click)="removeKeyword($event, item)"
                  />
                </ng-template>
              </floating-label-chips>
            </div>
            <div class="flex flex-column gap-2 mb-3">
              <floating-label-multiselect
                [label]="'Industry name'"
                [options]="industries"
                [(ngModel)]="selectedIndustries"
                [virtualScroll]="true"
                [scrollHeight]="'200px'"
                [lazy]="true"
                [virtualScrollItemSize]="42"
                (onLazyLoad)="fetchIndustries($event)"
                (onChange)="onSelectAdvanced($event, industries)">
              </floating-label-multiselect>
            </div>
          </p-accordionTab>
        </p-accordion>
      </div>
      <div class="gap-1 filter-dropdown">
        <div class="buttons-wrapper">
          <button (click)="clearFilter()">Clear All</button>
          <button
            pButton
            label="Apply"
            tooltipPosition="top"
            class="custom-button p-mr-2"
            (click)="applyFilter()"
            (filterApplied)="filterApplied.emit($event)"
          ></button>
        </div>
      </div>
    </div>
  </div>
</div>
