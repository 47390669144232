<div class="login-container">
  <div class="login-content">
    <p-toast></p-toast>
    <img src="../../../assets/images/purpleLogo.svg" />
    <div class="modal-wrapper">
      <div>
        <h3>Welcome to Linqbase!</h3>
        <span class="info"
          >Please enter your details to register into your organization.</span
        >
        <form class="form" [formGroup]="form">
          <div class="name-wrapper">
            <div class="input-wrapper" id="firstName">
              <label for="firstName">First name</label>
              <input
                id="firstNameInput"
                formControlName="firstName"
                placeholder="First name"
                class="p-inputtext-lg rounded  form-control"
                pInputText
                [ngClass]="displayFieldCss('firstName')"
              />
              <div *ngIf="isFieldInvalid('firstName')" class="error-message">
                <img src="../../../assets/images/error.svg" alt="error" />
                Please enter first name.
              </div>
            </div>
            <div class="input-wrapper" id="lastName">
              <label for="lastName">Last name</label>
              <input
                id="lastNameInput"
                formControlName="lastName"
                placeholder="Last name"
                class="p-inputtext-lg rounded form-control"
                pInputText
                [ngClass]="displayFieldCss('lastName')"
              />
              <div *ngIf="isFieldInvalid('lastName')" class="error-message">
                <img src="../../../assets/images/error.svg" alt="error" />
                Please enter last name.
              </div>
            </div>
          </div>
          <div class="input-wrapper">
            <label for="email">Email</label>
            <input
              id="email"
              formControlName="email"
              class="p-inputtext-lg rounded form-control"
              placeholder="email@email.em"
              pInputText
              [ngClass]="displayFieldCss('email')"
            />
            <div *ngIf="isFieldInvalid('email')" class="error-message">
              <img src="../../../assets/images/error.svg" alt="error" />
              Please enter password.
            </div>
          </div>
          <div class="input-wrapper">
            <label for="password">Password</label>
            <input
              id="password"
              formControlName="password"
              class="p-inputtext-lg rounded form-control"
              type="password"
              placeholder="Password"
              pInputText
              [ngClass]="displayFieldCss('password')"
            />
            <div *ngIf="isFieldInvalid('password')" class="error-message">
              <img src="../../../assets/images/error.svg" alt="error" />
              Please enter password.
            </div>
          </div>

          <div class="input-wrapper">
            <label for="confirmPassword">Confirm Password</label>
            <input
              id="confirmPassword"
              formControlName="confirmPassword"
              class="p-inputtext-lg rounded  form-control"
              type="password"
              placeholder="Confirm password"
              pInputText
              [ngClass]="displayFieldCss('confirmPassword')"
            />
            <div
              *ngIf="isFieldInvalid('confirmPassword')"
              class="error-message"
            >
              <img src="../../../assets/images/error.svg" alt="error" />
              Please enter confirm password.
            </div>

            <div
              *ngIf="isPasswordMismatch() && form.get('password')?.value && form.get('confirmPassword')?.value"
              class="error-message"
            >
              <img src="../../../assets/images/error.svg" alt="error" />
              Passwords do not match.
            </div>
          </div>
          <button
            pButton
            pRipple
            type="submit"
            (click)="onSubmit()"
            label="Register"
            [disabled]="!this.form.valid || isPasswordMismatch()"
            [ngClass]="{'login-button': this.form.valid && !isPasswordMismatch(), 'disabled-btn': (!this.form.valid || isPasswordMismatch()) }"
          ></button>
        </form>
      </div>
    </div>
  </div>
</div>
