<div class="floating-label" [class.has-value]="model.length > 0">
    <label [for]="id">{{ label }}</label>
    <p-chips
        [id]="id"
        [(ngModel)]="model"
        [placeholder]=" "
        (onAdd)="addValue($event)"
        (onRemove)="removeValue($event)">
        <ng-content></ng-content>
    </p-chips>
</div>
