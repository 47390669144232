<div class="container-top">
  <div class="user-info">
    <div>
      <p>Team management</p>
      <span> Here you’ll find the list of all the team members. </span>
    </div>

    <button class="custom-button"  (click)="showInviteMemberModal()">Invite people</button>
   
  </div>
  <app-invite-member-modal></app-invite-member-modal>
</div>
<div class="table-container">
  <div>
    <div class="card talent-wrapper" *ngIf="users.length > 0">
      <div class="col-12 filter-wrapper">
        <div class="col-5">
          <input
            class="search-input"
            pInputText
            placeholder="Search..."
            [(ngModel)]="searchText"
            (keyup)="searchTeammates()"
          />
        </div>
      </div>
      <p-table
        #dt
        [value]="users"
        [rows]="rows"
        [first]="first"
        [paginator]="true"
        [globalFilterFields]="['name']"
        [rowHover]="true"
        dataKey="externalId"
        [showCurrentPageReport]="true"
      >
        <ng-template pTemplate="header" class="header">
          <tr class="header-row">
            <td colspan="6">
              <div class="header-content">
                <th style="width: 5%"></th>
                <th pColumn="user.name"  class="pl-2" style="width: 18%;">Name</th>
                <th pColumn="user.roles" style="width: 49%">
                  Priviliges
                </th>
                <th pColumn="user.createdAt" style="width: 18%">Date Joined</th>

                <th pColumn="user.memberCount"style="width: 12%">Status</th>
                <th style="width: 8%">Actions</th>
              </div>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-user let-expanded="expanded">
          <tr>
            <td style="width: 5%">
              <div>
                <button
                  (click)="user.expanded = !user.expanded"
                  class="expand-button"
                >
                  <img
                    src="../../../assets/images/arrowDown.svg"
                    class="arrow"
                    [ngClass]="{'arrow-up': user.expanded}"
                  />
                </button>
              </div>
            </td>

            <td style="width: 15%">
              <div
                [pTooltip]="user?.name"
                tooltipPosition="bottom"
              >
                <div
                  [ngClass]="{'ellipsis-description': !user.expanded}"
                >
                  {{ user.firstName + ' ' + user.lastName }}
                </div>
              </div>
            </td>
      

            <td style="width: 45.5%">
              <div
                [pTooltip]="user?.roles"
                tooltipPosition="bottom"
                [ngClass]="{'ellipsis-description': !user.expanded}"
                class="pl-2"
              >
                  <div
                    *ngFor="let role of user.roles"
                  >
                    <span >{{ role }}</span>
            </div>

                  <span *ngIf="hasEllipsis">...</span>
              </div>
            </td>

            <td style="width: 16%;">
              <div
                [pTooltip]="user?.roles"
                tooltipPosition="bottom"
              >
                <div
                  [ngClass]="{'ellipsis-description': !user.expanded}"
                >
                  {{ user.createdAt | date: 'dd.MM.y' }}
                </div>
              </div>
            </td>

            <td style="width: 12%;">
              <div class="status text-center" 
              [ngClass]="{
                  'pending':  user.status === 'Pending',
                  'active':  user.status === 'Active',
                  'deactivated':  user.status === 'Deactivated'
                }">
                  <div class="status-text">{{user.status}}</div>
              </div>
            </td>

            <td style="width: 10%;">
              <div class="dropdown">
                <img
                  src="../../../assets/images/dots.svg"
                  alt="dots"
                  (click)="toggleDropdown(user) "
                  height="30"
                  width="30"
                />
                <div *ngIf="user.dropdownVisible" class="dropdown-content">
                  <label class="dropdown-item">
                    <p *ngIf="user.status === 'Pending'" class="remove" (click)="openDialog('Cancel', user)">Cancel invitation</p>
                    <div *ngIf="user.status === 'Active'">
                      <p>Edit</p>
                      <hr/>
                      <p class="remove" (click)="openDialog('Deactivate', user)">Deactivate</p>
                      <p class="remove" (click)="openDialog('Remove', user)">Remove</p>
                    </div>
                    <div *ngIf="user.status === 'Deactivated'">
                      <p>Edit</p>
                      <p class="activate"(click)="openDialog('Activate', user)">Activate</p>
                      <p class="remove" (click)="openDialog('Remove', user)">Remove</p>
                    </div>
                  </label>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
