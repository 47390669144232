import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { AuthHelper } from 'src/app/helpers/auth.helper';
import { ManageUserControllerService, ProcessCandidateStatusControllerService, ProcessCandidateStatusNoteDto, UserControllerService } from 'src/app/oapi_client/data_symphony';

@Component({
  selector: 'app-notes-modal',
  templateUrl: './notes-modal.component.html',
  styleUrls: ['./notes-modal.component.scss']
})
export class NotesModalComponent {

  @Input() notes: any[] | undefined;
  @Input() userId: number | undefined;
  @Output() noteAdded: EventEmitter<any> = new EventEmitter<any>();
  public loggedUser: boolean = false;

  public isOpen = false;
  public note: string = '';
  public errorMessage: string = '';
  constructor(private elementRef: ElementRef, private datePipe: DatePipe, 
  private talentService: ProcessCandidateStatusControllerService,
  private authService: ManageUserControllerService) { }

  ngOnInit(){
    this.notes?.map(note=> note.createdAt = String(this.datePipe.transform(note.createdAt, 'dd.MM.yyyy HH:mm')));
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  addNote(){
    this.talentService.createNote({
      processCandidateStatusId: this.userId!,
      note: this.note
    }).subscribe(
      next => {
        this.noteAdded.emit();
        this.note = ''; 
      },
      error => {
        this.errorMessage = 'This candidate is not editable.'; 
      }
    );
  }
}
