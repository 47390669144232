/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { CreateProcessCandidateStatusNote } from '../models/CreateProcessCandidateStatusNote';
import type { CreateProcessCandidateStatusRequest } from '../models/CreateProcessCandidateStatusRequest';
import type { DSResponseListProcessCandidateStatusDto } from '../models/DSResponseListProcessCandidateStatusDto';
import type { DSResponseListProcessStepsCandidateCountDto } from '../models/DSResponseListProcessStepsCandidateCountDto';
import type { DSResponseProcessCandidateStatusDto } from '../models/DSResponseProcessCandidateStatusDto';
import type { DSResponseProcessCandidateStatusNoteDto } from '../models/DSResponseProcessCandidateStatusNoteDto';
import type { DSResponseString } from '../models/DSResponseString';
import type { UpdateProcessCandidateStatusNoteRequest } from '../models/UpdateProcessCandidateStatusNoteRequest';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class ProcessCandidateStatusControllerService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @param noteId
     * @param requestBody
     * @returns DSResponseProcessCandidateStatusNoteDto OK
     * @throws ApiError
     */
    public updateNote(
        noteId: number,
        requestBody: UpdateProcessCandidateStatusNoteRequest,
    ): Observable<DSResponseProcessCandidateStatusNoteDto> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v1/processManagement/candidateStatus/{noteId}/updateNote',
            path: {
                'noteId': noteId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns DSResponseString OK
     * @throws ApiError
     */
    public uploadVideo(
        id: number,
        requestBody?: {
            file: Blob;
        },
    ): Observable<DSResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v1/processManagement/candidateStatus/{id}/uploadCandidateVideo',
            path: {
                'id': id,
            },
            headers: {
                'Accept': '*/*',
            },
            formData: requestBody,
        });
    }
    /**
     * @param requestBody
     * @returns DSResponseListProcessCandidateStatusDto OK
     * @throws ApiError
     */
    public succeedCandidateStatus(
        requestBody: Array<number>,
    ): Observable<DSResponseListProcessCandidateStatusDto> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v1/processManagement/candidateStatus/succeed',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns DSResponseListProcessCandidateStatusDto OK
     * @throws ApiError
     */
    public failedCandidateStatus(
        requestBody: Array<number>,
    ): Observable<DSResponseListProcessCandidateStatusDto> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v1/processManagement/candidateStatus/failed',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns DSResponseProcessCandidateStatusNoteDto OK
     * @throws ApiError
     */
    public createNote(
        requestBody: CreateProcessCandidateStatusNote,
    ): Observable<DSResponseProcessCandidateStatusNoteDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/processManagement/candidateStatus/createNote',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns DSResponseListProcessCandidateStatusDto OK
     * @throws ApiError
     */
    public createCandidateStatus(
        requestBody: Array<CreateProcessCandidateStatusRequest>,
    ): Observable<DSResponseListProcessCandidateStatusDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/processManagement/candidateStatus/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns DSResponseProcessCandidateStatusDto OK
     * @throws ApiError
     */
    public getById3(
        id: number,
    ): Observable<DSResponseProcessCandidateStatusDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/processManagement/candidateStatus/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @returns DSResponseString OK
     * @throws ApiError
     */
    public deleteCandidateStatus(
        id: number,
    ): Observable<DSResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/v1/processManagement/candidateStatus/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @returns binary OK
     * @throws ApiError
     */
    public getCandidateVideo(
        id: number,
    ): Observable<Blob> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/processManagement/candidateStatus/{id}/getCandidateVideo',
            path: {
                'id': id,
            },
            headers: {
                'Accept': '*/*', 
                'Content-Type':'application/octet-stream',
            },
            responseType:'blob'
        });
    }
    /**
     * @param processId
     * @returns DSResponseListProcessCandidateStatusDto OK
     * @throws ApiError
     */
    public getAllByProcessId1(
        processId: number,
    ): Observable<DSResponseListProcessCandidateStatusDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/processManagement/candidateStatus/process/{processId}',
            path: {
                'processId': processId,
            },
        });
    }
    /**
     * @param processId
     * @returns DSResponseListProcessStepsCandidateCountDto OK
     * @throws ApiError
     */
    public countByProcessId(
        processId: number,
    ): Observable<DSResponseListProcessStepsCandidateCountDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/processManagement/candidateStatus/countByProcessId/{processId}',
            path: {
                'processId': processId,
            },
        });
    }
    /**
     * @param noteId
     * @returns DSResponseString OK
     * @throws ApiError
     */
    public deleteNote(
        noteId: number,
    ): Observable<DSResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/v1/processManagement/candidateStatus/{noteId}/deleteNote',
            path: {
                'noteId': noteId,
            },
        });
    }
    /**
     * @param id
     * @returns DSResponseString OK
     * @throws ApiError
     */
    public deleteCandidateVideo(
        id: number,
    ): Observable<DSResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/v1/processManagement/candidateStatus/{id}/deleteCandidateVideo',
            path: {
                'id': id,
            },
        });
    }
}
