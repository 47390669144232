import { Component } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  ManageUserControllerService,
  VerifyAndRegisterUserRequest,
} from 'src/app/oapi_client/data_symphony';

@Component({
  selector: 'app-member-register',
  templateUrl: './member-register.component.html',
  styleUrls: ['./member-register.component.scss'],
})
export class MemberRegisterComponent {
  form: UntypedFormGroup;
  verificationCode: string | null = null;
  loading = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private userService: ManageUserControllerService
  ) {
    this.form = this.formBuilder.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
      confirmPassword: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.verificationCode = this.route.snapshot.queryParamMap.get('code');
  }

  isFieldInvalid(field: string): boolean {
    const control = this.form.get(field);
    return !!(control && control.invalid && control.touched);
  }

  isPasswordMismatch(): boolean {
    const password = this.form.get('password')?.value;
    const confirmPassword = this.form.get('confirmPassword')?.value;
    return password && confirmPassword && password !== confirmPassword;
  }

  displayFieldCss(field: string): { [key: string]: boolean } {
    const control = this.form.get(field);
    return {
      'ng-dirty': control?.dirty ?? false,
      'ng-valid': control?.valid && control?.value ? true : false,
      'ng-invalid': control?.invalid && control?.touched ? true : false,
      'has-value': control?.value !== null && control?.value !== '',
      'no-value': control?.value === null || control?.value === '',
    };
  }

  onSubmit(): void {
    if (this.form.invalid || !this.verificationCode) {
      return;
    }

    const formData = this.form.value;
    const requestBody: VerifyAndRegisterUserRequest = {
      verificationCode: this.verificationCode,
      firstName: formData.firstName,
      lastName: formData.lastName,
      password: formData.password,
      confirmPassword: formData.confirmPassword,
    };

    this.loading = true;
    this.userService.acceptInvitation(requestBody).subscribe({
      next: (response) => {
        console.log('success', response);
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => {
        this.loading = false;
      },
    });
  }
}
