<div [ngClass]="!buttonSplit ? 'card':'form-card mt-6'">
  (<span class="required">*</span>)<span>Required fields</span>
  <form [formGroup]="myForm">
    <div class="form">
      <div class="section section-1">
        <h3>
          Requirements
          <button
            class="image-button mr-2"
            pTooltip="Enter the details you’re looking for in a candidate."
            tooltipPosition="bottom"
          >
            <img
              src="../../../assets/images/info.svg"
              alt="info icon"
              class="info-icon"
            />
          </button>
        </h3>
        <div class="mb-0 title">
          <label><span class="required">*</span>Request title</label>
          <input
            formControlName="title"
            placeholder="Enter Title"
            [ngClass]="getClassObject('title')"
          />
        </div>
        <div class="input-group mb-0 years-wrapper">
          <div class="input-max">
            <div>
              <label
                ><span class="required">*</span>Min. years of experience</label
              >
              <input
                formControlName="min"
                placeholder="Min. experience"
                [ngClass]="{'border-invalid': !myForm.get('min')?.valid || isMinMaxInvalid()}"
                type="number"
              />
            </div>
            <div>
              <label
                ><span class="required">*</span>Max. years of experience</label
              >
              <input
                formControlName="max"
                placeholder="Max. experience"
                [ngClass]="{'border-invalid':  isMinMaxInvalid()}"
                type="number"
              />
            </div>
          </div>
          <div *ngIf="isMinMaxInvalid()" class="error-message">
            Min years cannot be greater than Max years.
          </div>
        </div>
        <div class="input-group">
          <label><span class="required">*</span>Keywords</label>
          <p-chips
            placeholder="Type your keywords and press enter"
            formControlName="keywordList"
            (onRemove)="removeKeyword($event.value)"
            (onAdd)="onValueAdded($event)"
            id="chipsInput"
          >
            <ng-template let-item pTemplate="item">
              <span class="chip-item">{{ item }}</span>
              <img
                src="../../../assets/images/closeIconKeyword.svg"
                alt="remove"
                class="remove-icon"
                (click)="removeKeyword(item)"
              /> </ng-template
          ></p-chips>
        </div>
        <ng-container *ngFor="let control of section1Dropdowns; let i = index">
          <div *ngIf="control.control !== 'salaryRange'" class="dropdown-group">
            <label [for]="'dropdown' + i">
              <span class="required">*</span>{{ control.label }}
            </label>
            <p-multiSelect
              [options]="control.array"
              [formControlName]="control.control"
              placeholder="{{ control.placeholder }}"
              [id]="'dropdown' + i"
              (onChange)="onSelect($event, i)"
            >
            </p-multiSelect>
          </div>
        </ng-container>

        <div class="calendar-wrapper">
          <div>
            <label><span class="required">*</span>Deadline</label>
            <p-calendar
              [showIcon]="true"
              inputId="icon"
              placeholder="Select"
              formControlName="endDate"
              [minDate]="todaysDate"
            ></p-calendar>
          </div>
        </div>

        <div class="input-group mb-0 years-wrapper">
          <div class="input-max">
            <div>
              <label
                ><span class="required">*</span>Min. salary range</label
              >
              <input
                formControlName="salaryMin"
                placeholder="Select"
                [ngClass]="{'border-invalid': !myForm.get('salaryMin')?.valid || isMinMaxSalaryInvalid()}"
                type="number"
              />
            </div>
            <div>
              <label
                ><span class="required">*</span>Max. salary range</label
              >
              <input
                formControlName="salaryMax"
                placeholder="Select"
                [ngClass]="{'border-invalid':  isMinMaxSalaryInvalid()}"
                type="number"
              />
            </div>
          </div>
          <div *ngIf="isMinMaxSalaryInvalid()" class="error-message">
            Min salary range cannot be greater than Max salary range.
          </div>
        </div>

        <div class="dropdown-group">
          <label [for]="'dropdown-salaryRange'">
            <span class="required mt-2">*</span>Currency
          </label>
          <p-dropdown
            [options]="currencyArray"
            formControlName="currency"
            placeholder="Select"
            id="dropdown-salaryRange"
            class="salary-range-dropdown"
          >
          </p-dropdown>
        </div>

        <div>
          <label><span class="required">*</span>Number of candidates</label>
          <input
            formControlName="numOfCandidates"
            placeholder="Number of candidates"
            type="number"
          />
        </div>
      </div>
      <div class="section section-2">
        <h3 class="step-title">
          Add steps <span class="optional mr-2">(Optional)</span>
          <button
            class="image-button"
            pTooltip="You can define the steps, or you can leave it for the HR company. Keep in mind, if you decide not to add any, you will not be able to edit this later once it goes to HR company."
            tooltipPosition="bottom"
          >
            <img
              src="../../../assets/images/info.svg"
              alt="info icon"
              class=" info-icon"
            />
          </button>
        </h3>
        <div formArrayName="steps" class="step-container">
          <div
            class="input-group mb-0"
            *ngFor="let dropdown of steps.controls; let i = index"
            [formGroupName]="i"
          >
            <p-dropdown
              formControlName="selectedOption"
              [options]="filteredStepLists[i]"
              placeholder="Select an option"
              class="input-steps"
            >
              <ng-template pTemplate="footer">
                <div class="define-step">
                  <input
                    type="text"
                    class="custom-input"
                    placeholder="Add new step (on enter)"
                    (click)="stop($event)"
                    (keydown.enter)="addStep($event)"
                  />
                </div> </ng-template
            ></p-dropdown>
            <button
              pTooltip="Delete"
              class="input-steps-button"
              (click)="removeDropdown(i)"
            >
              <img
                src="../../../assets/images/deleteIcon.svg"
                alt="Delete Image"
              />
            </button>
          </div>
        </div>
        <button
          pButton
          pRipple
          icon="pi pi-plus"
          (click)="addDropdown()"
          class="button-more"
          [disabled]="myForm.value.steps.at(-1).selectedOption == null"
        >
          Add more
        </button>
      </div>
      <div class="section section-3">
        <div *ngFor="let control of section3Textareas; let i = index">
          <h3>
            {{control.label}}
            <span class="optional mr-2" *ngIf="control.optional"
              >(Optional)</span
            >
            <button
              class="image-button"
              pTooltip="{{ control.control === 'note' ? 'If you have any specific notes for the HR company or any specific requests from the HR company, you can write them down here.' : 'You can describe to the HR company any specific qualities you’re looking for (e.g. soft skills, etc.).' }}"
              tooltipPosition="bottom"
              tooltipStyleClass="custom-tooltip"
            >
              <img
                src="../../../assets/images/info.svg"
                alt="info icon"
                class=" info-icon"
              />
            </button>
          </h3>
          <div class="textarea-group">
            <label [for]="'textarea' + i">{{control.label}}</label>
            <textarea
              [id]="'textarea' + i"
              [formControlName]="control.control"
              placeholder="{{control.placeholder}}"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2">
      <div class="down-buttons">
        <button
          type="button"
          label="Cancel"
          class="p-button-cancel  mr-5"
          iconPos="left"
          (click)="cancelEdit()"
        >
          Cancel
        </button>
        <button
          type="submit"
          class="p-button-save"
          iconPos="left"
          (click)="onSubmit()"
          [disabled]="!myForm.valid"
          *ngIf="!provider && !typeInternal"
        >
          {{ !requestId ? 'Next' : 'Save' }}
        </button>
        <button
          type="submit"
          class="p-button-save internal"
          iconPos="left"
          (click)="onSubmitInternal()"
          [disabled]="!myForm.valid"
          *ngIf="provider || typeInternal"
        >
          Create Internal Process
        </button>
      </div>
    </div>
  </form>
</div>
