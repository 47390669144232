import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { AuthHelper } from 'src/app/helpers/auth.helper';
import {
  CountryControllerService,
  DSResponseListString,
  LinkedinProfilesControllerService,
} from 'src/app/oapi_client/data_symphony';

@Component({
  selector: 'app-experience-location-modal',
  templateUrl: './experience-location-modal.component.html',
  styleUrls: ['./experience-location-modal.component.scss'],
})
export class ExperienceLocationModalComponent implements OnInit {
  @Output() filterApplied: EventEmitter<any> = new EventEmitter();

  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  isOpen: boolean = false;
  maxYearsInput: number | string = '';
  minYearsInput: number | string = '';
  suggestedWords: string[] = [];
  displayedSuggestions: string[] = [];
  checkbox1: boolean = false;
  checkbox2: boolean = false;
  values: string[] = [];
  selectedSkills: string[] = [];
  locations: any;
  selectedLocations: any[] = [];
  loading: boolean = false;
  page: number = 0;
  empty: boolean = false;
  currentUserID: number = 0;

  constructor(
    private elementRef: ElementRef,
    private linkedinProfileService: LinkedinProfilesControllerService,
    private authHelper: AuthHelper,
    private messageService: MessageService,
    private countryControllerService: CountryControllerService
  ) {
    countryControllerService.findCountry('EN').subscribe((response) => {
      if (response.success) {
        this.locations = response.body;
      }
    });
    this.updateDisplayedSuggestions();
  }

  ngOnInit(): void {
    this.authHelper.currentUser.subscribe(
      (user) => (this.currentUserID = user.id)
    );
  }


  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  onValueRemoved(event: any): void {
    const removedWord = event.value;
    const index = this.selectedSkills.indexOf(removedWord);
    if (index !== -1) {
      this.selectedSkills.splice(index, 1);
      this.addWordToSuggestions(removedWord);
      this.updateDisplayedSuggestions();
    }
  }

  onValueAdded(event: any): void {
    this.suggestKeyword(this.currentUserID, event.value).subscribe(
      (response) => {
        if (response.body) {
          this.suggestedWords = response.body || [];
          this.updateDisplayedSuggestions();
        }
      }
    );
  }

  suggestKeyword(
    userId: number,
    input: string
  ): Observable<DSResponseListString> {
    return this.linkedinProfileService.suggestKeyword(userId, input);
  }

  removeEnteredWordsFromSuggestions(): void {
    this.values.forEach((enteredWord: string) => {
      const index = this.suggestedWords.indexOf(enteredWord);
      if (index !== -1) {
        this.suggestedWords.splice(index, 1);
      }
    });
    this.updateDisplayedSuggestions();
  }

  addWordToSkills(event: Event, word: string): void {
    event.stopPropagation();
    if (this.suggestedWords.includes(word)) {
      const index = this.values.indexOf(word);
      if (index === -1) {
        this.values.push(word);
        this.removeWordFromSuggestions(word);
        this.updateDisplayedSuggestions();
      }
    } else {
      const index = this.values.indexOf(word);
      if (index === -1) {
        this.values.push(word);
      }
    }
  }

  updateDisplayedSuggestions(): void {
    const availableSuggestions = this.suggestedWords.filter(
      (word) => !this.values.includes(word.toLowerCase())
    );
    const allWords = [...availableSuggestions, ...this.selectedSkills];
    this.displayedSuggestions = allWords.slice(0, 4);
  }

  addWordToSuggestions(word: string): void {
    if (!this.suggestedWords.includes(word)) {
      this.suggestedWords.push(word);
      this.updateDisplayedSuggestions();
    }
  }

  removeWordFromSuggestions(word: string): void {
    const index = this.suggestedWords.indexOf(word);
    if (index !== -1) {
      this.suggestedWords.splice(index, 1);
    }
  }

  onSelect(event: any){
    event.originalEvent.stopPropagation();
    const selectedValues = event.value;
    this.locations?.sort((a: any, b: any) => {
      const isSelectedA = selectedValues.includes(a);
      const isSelectedB = selectedValues.includes(b);
  
      if (isSelectedA && !isSelectedB) {
        return -1;
      } else if (!isSelectedA && isSelectedB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  clearFilter() {
    this.minYearsInput = 0;
    this.maxYearsInput = 0;
    this.values = [];
    this.displayedSuggestions = [];
    this.selectedLocations = [];
    this.checkbox1 = false;
    this.checkbox2 = false;
  }

  onMinYearsInput(event: any): void {
    let value: string = event.target.value.trim();
    value = value.replace(/^(-)?0+(?=\d)/, '$1');
    const parsedValue: number = parseFloat(value);
    if (isNaN(parsedValue) || parsedValue < 0 || parsedValue > 50) {
      event.target.value = '';
      this.minYearsInput = '';
    } else {
      this.minYearsInput = parsedValue;
    }
  }

  onMaxYearsInput(event: any): void {
    let value: string = event.target.value.trim();
    value = value.replace(/^(-)?0+(?=\d)/, '$1');
    const parsedValue: number = parseFloat(value);
    if (isNaN(parsedValue) || parsedValue < 0 || parsedValue > 50) {
      event.target.value = '';
      this.maxYearsInput = '';
    } else {
      this.maxYearsInput = parsedValue;
    }
  }

  validateYears(): boolean {
    if (
      this.maxYearsInput &&
      this.minYearsInput &&
      this.maxYearsInput < this.minYearsInput
    ) {
      return false;
    }
    return true;
  }

  applyFilter() {
    if (!this.validateYears()) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Max years cannot be less than Min years.',
      });
      return;
    }

    const filters = {
      minYears: this.minYearsInput || '',
      maxYears: this.maxYearsInput || '',
      skill: this.values,
      country: this.selectedLocations.map((location) => location.code),
    };

    this.filterApplied.emit(filters);
    this.isOpen = false;
  }

  removeKeyword(event: MouseEvent, value: string) {
    event.stopPropagation();
    const index = this.values.indexOf(value);
    if (index !== -1) {
      this.values.splice(index, 1);
      this.onValueRemoved({ value });
    }
  }
}
