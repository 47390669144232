<div class="container-top">
  <div class="user-info">
    <div>
      <p>Team management</p>
      <span> Here you’ll find the list of all the team members. </span>
    </div>

    <button class="custom-button" (click)="showInviteMemberModal()">Invite people</button>

  </div>
  <app-invite-member-modal></app-invite-member-modal>
</div>
<div class="table-container">
  <div class="card talent-wrapper">
    <div *ngIf="orgUsers.length == 0 ">
      <div class="text-center">
        <div class="image">
          <img src="../assets/images/no-users.svg">
        </div>
        <div>
          <h3>No users available</h3>
          <p class="font-bold">Click "Invite people" to get started.</p>
        </div>
      </div>
    </div>
    <div *ngIf="orgUsers.length > 0">
      <div class="col-12 filter-wrapper">
        <div class="col-5">
          <input class="search-input" pInputText placeholder="Search..." [(ngModel)]="searchText"
            (keyup)="searchTeammates()" />
        </div>
      </div>

      <div>
        <p-table #dt [value]="users" [rows]="rows" [first]="first" [paginator]="true" [globalFilterFields]="['name']"
          [rowHover]="true" dataKey="externalId" [showCurrentPageReport]="true">
          <ng-template pTemplate="header" class="header">
            <tr class="header-row">
              <td colspan="6">
                <div class="header-content">
              <th style="width: 4%"></th>
              <th pColumn="user.name" class="pl-2" style="width: 18.12%;">Name</th>
              <th pColumn="user.roles" style="width: 44%">
                Priviliges
              </th>
              <th pColumn="user.createdAt" style="width: 16%">Date Joined</th>

              <th pColumn="user.memberCount" style="width: 11%">Status</th>
              <th>Actions</th>
      </div>
      </td>
      </tr>
      </ng-template>

      <ng-template pTemplate="body" let-user let-expanded="expanded">
        <tr>
          <td style="width: 5%">
            <div>
              <button (click)="user.expanded = !user.expanded" class="expand-button">
                <img src="../../../assets/images/arrowDown.svg" class="arrow" [ngClass]="{'arrow-up': user.expanded}" />
              </button>
            </div>
          </td>

          <td style="width: 15%">
            <div [pTooltip]="user?.name" tooltipPosition="bottom">
              <div [ngClass]="{'ellipsis-description': !user.expanded}">
                {{ user.email }}
              </div>
              <span *ngIf="hasEllipsis">...</span>

            </div>
          </td>


          <td style="width: 45.5%">
            <div [pTooltip]="user?.roles" tooltipPosition="bottom" [ngClass]="{'ellipsis-description': !user.expanded}"
              class="pl-2">
              <div *ngFor="let role of user.roles" class="d-flex">
                <span class="mr-2">{{ role }}, </span>
              </div>
              <div *ngIf="user.roles.length === 0" style="font-style: italic;">
                No roles assigned yet.
              </div>
              <span *ngIf="hasEllipsis">...</span>
            </div>
          </td>

          <td style="width: 16%;">
            <div [pTooltip]="user?.roles" tooltipPosition="bottom">
              <div [ngClass]="{'ellipsis-description': !user.expanded}">
                {{ user.createdAt | date: 'dd.MM.y' }}
              </div>
            </div>
          </td>

          <td style="width: 12%;">
            <div class="status text-center" [ngClass]="{
                'pending':  user.status === 'Pending',
                'active':  user.status === 'Active',
                'deactivated':  user.status === 'Deactivated'
              }">
              <div class="status-text">{{user.status}}</div>
            </div>
          </td>

          <td style="width: 10%;">
            <div class="dropdown" *ngIf="adminLogged">
              <img src="../../../assets/images/dots.svg" (click)="toggleDropdown(user)"  alt="dots"  height="30"
                width="30" />
              <div *ngIf="user.dropdownVisible" class="dropdown-content">
                <label class="dropdown-item">
                  <p *ngIf="user.status === 'Pending'" class="remove" (click)="openDialog('Cancel', user)">Cancel
                    invitation</p>
                  <div *ngIf="user.status === 'Active'">
                    <p class="edit-button" (click)="openEditPriviligesModal(user)">Edit</p>
                    <hr />
                    <p class="remove" (click)="openDialog('Deactivate', user)">Deactivate</p>
                    <p class="remove" (click)="openDialog('Remove', user)">Remove</p>
                  </div>
                  <div *ngIf="user.status === 'Deactivated'">
                    <p>Edit</p>
                    <p class="activate" (click)="openDialog('Activate', user)">Activate</p>
                    <p class="remove" (click)="openDialog('Remove', user)">Remove</p>
                  </div>
                </label>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
      </p-table>
    </div>
    <div *ngIf="users?.length === 0  && searchText.length > 0 ">
      <div class="text-center font-bold">No results found for "{{ searchText }}". Please try a different query.</div>
    </div>
  </div>
</div>
</div>