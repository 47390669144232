<div class="modal-header-container">
  <p class="modal-title">Add from Candidate pools</p>
  <span class="modal-description">
    You can add complete groups or you can choose individual people.
  </span>

  <p-dropdown
    [options]="groups$"
    [(ngModel)]="selectedGroup"
    optionLabel="name"
    placeholder="Select candidate pool"
    (onChange)="onGroupChange()"
  >
  </p-dropdown>
</div>

<p-table
  #dt
  [value]="profiles$"
  [lazy]="true"
  (onLazyLoad)="loadProfiles($event)"
  [rows]="10"
  [paginator]="true"
  [totalRecords]="totalRecords"
  [dataKey]="'publicMemberId'"
  class="table"
  *ngIf="profiles$.length !== 0"
>
  <ng-template pTemplate="header" class="header">
    <tr>
      <th colspan="5" class="header-buttons">
        <div class="button-wrapper">
          <label class="custom-checkbox-label">
            <input
              type="checkbox"
              [checked]="selectAll"
              (change)="toggleSelectAll($event)"
            />
            <span class="custom-checkbox-icon"></span>
          </label>
          <span style="padding-left: 5px; display: flex">
            Select All
            <span *ngIf="selectAll" class="records"> ({{totalRecords}}) </span>
          </span>
        </div>
      </th>
    </tr>
    <tr class="header-row">
      <td colspan="5">
        <div class="header-content">
          <th
            pColumn="profile.person_info.name"
            style="width: 25.3%; margin-left: 25px;"
          >
            Name
          </th>
          <th pColumn="person_info.headline" style="width: 21.3%;" >Title</th>
          <th pColumn="person_info.experience" style="width: 39.1%">
            Experiences
          </th>

          <th pColumn="person_info.location" style="width: 10.8%;">
            Location
          </th>
        </div>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-profile>
    <tr>
      <td style="padding-left: 1rem; width: 26.5%;">
        <div class="name">
          <label class="custom-checkbox-label">
            <input
              type="checkbox"
              [checked]="selectedProfiles[filter.pageIndex ?? 0]?.has(profile.publicMemberId || profile.publicLinkedinMemberId)"
              (change)="toggleSelect(profile)"
              class="custom-checkbox"
            />
            <span class="custom-checkbox-icon"></span>
          </label>

          <span
            class="ellipsis-description"
            [pTooltip]="profile.personInfo.name"
            tooltipPosition="bottom"
            style="padding-left: 5px; width: 172px;"
          >
            {{ profile.personInfo.name }}
          </span>
        </div>
      </td>
      <td style="width: 21%;">
        <div
          [pTooltip]="profile?.personInfo?.headline"
          tooltipPosition="bottom"
        >
          <div class="ellipsis-description">
            {{ profile?.lastExperienceTitle }}
          </div>
        </div>
      </td>
      <td  style="width: 39%">
        <div [pTooltip]="generateTooltip(profile)" tooltipPosition="bottom">
          <div class="ellipsis-description" style="width: 273px">
            <ng-container
              *ngFor="let experience of profile?.experienceCompany; let last = last"
            >
              <span
                *ngIf="!last && isValidInstitutionName(experience)"
                >{{ experience }},
              </span>
              <span *ngIf="last">{{ experience }}</span>
            </ng-container>
          </div>
        </div>
      </td>
      <td style="width: 12.8%;">
        <div class="ellipsis-description">
          {{ profile.personInfo?.location }}
        </div>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="paginatorright" *ngIf="profiles$.length !== 0">
    <span class="records">
      Showing {{ dt?.first! + 1 }} to {{ dt?.first! + profiles$?.length! }} of
      {{ totalRecords }}
    </span>
  </ng-template>
</p-table>

<div class="empty-pool" *ngIf="profiles$.length === 0">
  There are no members in this candidate pool.
</div>
<div class="button-container">
  <button class="button-transparent" (click)="onClose()">Cancel</button>
  <button class="button-purple" (click)="addTalentsToProcessStep()">Add</button>
</div>
