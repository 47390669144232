import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CreateProcessRequest } from 'src/app/oapi_client/data_symphony';

@Component({
  selector: 'app-send-counter-offer',
  templateUrl: './send-counter-offer.component.html',
  styleUrls: ['./send-counter-offer.component.scss']
})
export class SendCounterOfferComponent {
  public form: UntypedFormGroup;
  public characterCount: number = 0;
  public title: string = "Send offer";
  public paraph: string = "Send the proposal and wait for client's confirmation";

  public currencyArray: any[] = [
    {label: CreateProcessRequest.salaryCurrency.DOLLAR, value: CreateProcessRequest.salaryCurrency.DOLLAR},
    {label: CreateProcessRequest.salaryCurrency.EURO, value: CreateProcessRequest.salaryCurrency.EURO},
    {label: CreateProcessRequest.salaryCurrency.TL, value: CreateProcessRequest.salaryCurrency.TL},
  ];

  constructor(private formBuilder: UntypedFormBuilder, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    this.form = this.formBuilder.group({
      amount: new UntypedFormControl('', [Validators.required, Validators.min(1)]),
      note: new UntypedFormControl(''),
      currency:new UntypedFormControl('', [Validators.required, Validators.min(1)])
    });
    if(this.config.data){
      this.title = this.config.data.title;
      this.paraph = this.config.data.paraph; 
    }
    
  }

  incrementCount() {
    this.characterCount = this.form.value.note.length;
  }

  getClassObject(field: string) {
    return {
      'border-invalid': !this.form!.get(field)!.valid && this.form!.get(field)!.touched,
    };
  }

  save(){
    this.ref.close({amount: this.form.value.amount, note: this.form.value.note, currency: this.form.value.currency});
  }

  closeDialog(){
    this.ref.close(false);
  }
}
