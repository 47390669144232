<div class="modal-overlay">
  <div class="modal">
    <h1>Welcome to the {{companyName}}</h1>
    <p class="modal-subtitle">You have been granted new privileges.</p>
    <div class="content-wrapper">
      <div class="modal-header">
        <h2>{{ roleContent[roles[currentIndex]]?.title }}</h2>
      </div>
      <div class="modal-body">
        <p>{{ roleContent[roles[currentIndex]]?.subtitle }}</p>
        <p class="text">What this means:</p>
        <div *ngFor="let desc of roleContent[roles[currentIndex]]?.description">
          <ul class="custom-list">
            <li>{{ desc }}</li>
          </ul>
        </div>
      </div>

      <div class="pagination-container">
        <div class="slide-info">
          {{ currentIndex + 1 }} of {{ roles.length }}
        </div>

        <div class="pagination">
          <button (click)="previous()" [disabled]="currentIndex === 0">
            &lt;
          </button>

          <div class="pagination-dots">
            <span
              *ngFor="let dot of [].constructor(roles.length); let i = index"
              [class.active]="i === currentIndex"
              (click)="goToSlide(i)"
              class="dot"
            ></span>
          </div>

          <button
            (click)="next()"
            [disabled]="currentIndex === roles.length - 1"
          >
            &gt;
          </button>
        </div>
      </div>
    </div>

    <button class="general-button-purple" (click)="closeModal()">
      Let's Start
    </button>
  </div>
</div>
