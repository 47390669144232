<div class="company-details">
  <div class="container-top">
    <div class="company-header-wrapper">
      <img
      [src]="company?.companyDto?.logoUrl"
      class="profile-photo"
      alt="logo"
      (error)="onImageError($event)" 
    />
      <div>
        <h1>{{ company?.companyDto?.name }}</h1>
        <p class="profile-title">
          <a
            href="{{ company?.companyDto?.webSiteUrl }}"
            >{{ company?.companyDto?.webSiteUrl }}</a
          >
        </p>
      </div>
    </div>
  </div>
  <div class="wrapper">
    <div class="wrapper-user-info-left">
      <div>
        <h1>About the company</h1>
        <p>{{ company?.companyDto?.additional }}</p>
        <hr />
      </div>

      <div class="work-history-container">
        <h1>Work history</h1>
        <div *ngIf="workHistory.length === 0">
          <p>No work history yet for this company.</p>
        </div>
        <div
          *ngFor="let item of workHistory; let i = index"
          class="work-history-item"
        >
          <div class="work-history-content">
            <div class="work-history-main">
              <div class="work-history-title">
                <span class="dot"></span>
                <span class="title">{{ item.title }}</span>
              </div>
              <div class="work-history-details">
                <span class="date"
                  >From: <strong>{{ item.company }}</strong></span
                >
                <span class="small-dot">•</span>
                <span class="date">{{ item.date }}</span>
              </div>
            </div>
            <div class="work-history-cost">
              <span>Total cost</span>
              <span>{{ item.totalCost }}</span>
            </div>
          </div>
          <div
            class="recent-reviews-container"
            *ngFor="let review of item.reviews"
          >
            <div class="review-item">
              <div class="review-content">
                <div class="review-rating">
                  <p-rating
                    [(ngModel)]="review.starCount"
                    [readonly]="true"
                    [cancel]="false"
                  ></p-rating>
                </div>
                <div class="review-description">
                  <p>{{ review.comment }}</p>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="i < workHistory.length - 1">
            <hr class="partial-hr" />
          </div>
        </div>
        <!-- <hr class="full-hr" /> -->
      </div>
    </div>
    <div class="wrapper-line">
      <hr />
    </div>
    <div class="wrapper-user-info-right">
      <h1>Rating</h1>
      <div class="review-rating">
        <p-rating
          [(ngModel)]="averageRating"
          [readonly]="true"
          [cancel]="false"
        ></p-rating>
        <span>({{ averageRating.toFixed(1) }})</span>
      </div>
      <h1
        *ngIf="company && company.completedProcessList && company.completedProcessList.length > 1"
      >
        {{ company.completedProcessList.length }} jobs done
      </h1>
      <h1
        *ngIf="company && company.completedProcessList && company.completedProcessList.length === 1"
      >
        {{ company.completedProcessList.length }} job done
      </h1>

      <h1 *ngIf="totalBudget">{{ totalBudget }} earned</h1>
      <hr />
      <h1>Industry</h1>
      <p>{{ company?.companyDto?.industry }}</p>
      <h1>Company type</h1>
      <p>{{ company?.companyDto?.type }}</p>
      <h1>Company size</h1>
      <p>{{ company?.companyDto?.size }}</p>
      <h1>Address</h1>
      <p>{{ company?.companyDto?.address }}</p>
      <h1>Email</h1>
      <p>
        <a
          class="email"
          href="mailto:{{ company?.companyDto?.email }}"
          >{{ company?.companyDto?.email }}</a
        >
      </p>
      <h1>Phone number</h1>
      <p>{{ company?.companyDto?.phoneNumber }}</p>
      <h1>Membership date</h1>
      <p>{{ formatDate(company?.companyDto?.createdAt) }}</p>
      <h1>Tax number</h1>
      <p>{{ company?.companyDto?.taxNumber }}</p>
    </div>
  </div>
</div>
