import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'floating-label-input',
  templateUrl: './floating-label-input.component.html',
  styleUrls: ['./floating-label-input.component.scss']
})
export class FloatingLabelInputComponent {
  @Input() label: string = '';
  @Input() type: 'text' | 'password' | 'email' | 'number' | 'date' | 'file' | 'checkbox' = 'text';
  @Input() model: any;
  @Input() id: string = '';
  @Input() class: string = '';
  @Input() min?: number;
  @Input() max?: number;

  @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onKeyUp: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBlur: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFocus: EventEmitter<any> = new EventEmitter<any>();

  onInput(event: any) {
    const value = (event.target as HTMLInputElement).value;
    this.modelChange.emit(this.type === 'number' ? Number(value) : value);
  }
}
