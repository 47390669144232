<div class="multi-select-container" [class.focused]="isFocused || selectedOptions.length > 0">
    <label class="floating-label" [class.active]="isFocused || selectedOptions.length > 0">{{ label }}</label>
    <p-multiSelect
        #multiSelect
        placeholder=" "
        [class]="'multi-select ' + class"
        optionLabel="name"
        [id]="id"
        [options]="options"
        [(ngModel)]="selectedOptions"
        (ngModelChange)="onSelectionChange($event)"
        [scrollHeight]="scrollHeight"
        [virtualScroll]="virtualScroll"
        [lazy]="lazy"
        [virtualScrollItemSize]="virtualScrollItemSize"
        (onLazyLoad)="fetchOptions($event)"
        (onChange)="onChange.emit($event)"
        (focus)="onFocus()"
        (blur)="onBlur()"
    >
    </p-multiSelect>
    <ng-content></ng-content>
</div>