<div class="user-details">
  <div class="container-top">
    <div class="user-info">
      <!-- <img
        *ngIf="profiles$?.personInfo?.profilePictureLink !== null"
        src="{{ profiles$?.personInfo?.profilePictureLink }}"
        class="profile-photo"
        alt="profile-photo"
      /> -->
      <div>
        <h1>{{ profiles$?.personInfo?.name }}</h1>
        <p class="profile-title">{{ profiles$?.personInfo?.headline }}</p>
      </div>
      <a [href]="profiles$?.linkedinProfileLink" target="_blank">
        <button class="custom-button">LinkedIn Profile</button>
      </a>
    </div>
  </div>
  <div class="wrapper">
    <div class="wrapper-user-info-left">
      <div
        *ngIf="
          profiles$ &&
          profiles$.personInfo &&
          profiles$.personInfo.about !== null
        "
      >
        <h1>About</h1>
        <p>{{ profiles$.personInfo.about }}</p>
        <hr />
      </div>

      <div
        *ngIf="
          profiles$ && profiles$.experience && profiles$.experience.length > 0
        "
      >
        <h1>Experience</h1>
        <ul>
          <ng-container *ngFor="let experience of profiles$?.experience">
            <div class="container">
              <div>
                <h2>{{ experience.institutionName }}</h2>

                <ul *ngFor="let titles of experience.titles">
                  <tr>
                    <td>
                      <p>{{ titles.positionTitle }}</p>
                    </td>
                  </tr>
                  <tr>
                    <span *ngIf="titles.fromDate || titles.toDate">
                      {{ titles.fromDate | date : "MMM yyyy" }} -
                      {{ titles.toDate | date : "MMM yyyy" }} /
                      {{ titles.duration }}</span
                    >
                  </tr>

                  <div class="about">{{ titles.about }}</div>
                  <br />
                </ul>
              </div>
            </div>
          </ng-container>
        </ul>
        <hr />
      </div>

      <div
        *ngIf="
          profiles$ && profiles$.education && profiles$.education.length > 0
        "
      >
        <h1>Education</h1>

        <ul>
          <ng-container *ngFor="let education of profiles$?.education">
            <div class="container">
              <div>
                <h2>{{ education.institutionName }}</h2>
                <div>
                  <p *ngIf="education.about">
                    {{ education.about }}
                  </p>
                  <p *ngIf="education.degree">
                    {{ education.degree }}
                  </p>
                </div>
                <tr>
                  <span *ngIf="education.fromDate || education.toDate"
                    >{{ education.fromDate | date : "yyyy" }} -
                    {{ education.toDate | date : "yyyy" }}</span
                  >
                </tr>
              </div>
            </div>
          </ng-container>
        </ul>
        <hr />
      </div>
      <div
        *ngIf="
        profiles$ && profiles$.project && profiles$.project.length > 0
      "
      >
        <h1>Projects</h1>

        <ul>
          <ng-container *ngFor="let project of profiles$?.project">
            <div class="container">
              <div>
                <div class="project-link">
                <h2>{{ project.title }}</h2>
                <a [href]="project.projectLink" *ngIf="project.projectLink?.startsWith('http')" target="_blank" rel="noopener noreferrer">
                  <img src="../../../assets/images/link.svg" alt="link"
                /></a>
              </div>
                <div>
                  <span *ngIf="project.fromDate "
                    >{{ project.fromDate | date : "yyyy" }} </span
                  ><span *ngIf="project.toDate">
                    - {{ project.toDate | date : "yyyy" }}
                  </span>
                </div>
                <tr>
                  <p *ngIf="project.about" class="about">
                    {{ project.about }}
                  </p>
                </tr>
              </div>
            </div>
          </ng-container>
        </ul>
        <hr />
      </div>

      <ng-container
        *ngIf="profiles$ && profiles$.course && profiles$.course.length > 0"
      >
        <h1>Course</h1>
        <ul>
          <ng-container
            *ngFor="let course of profiles$?.course | slice: 0 : visibleCourses"
          >
            <li>
              <p>{{ course.title }}</p>
              <p
                class="additional-info"
                *ngIf="course.about && course.about !== '-'"
              >
                {{ course.about }}
              </p>
            </li>
          </ng-container>
        </ul>

        <button
          *ngIf="profiles$.course.length > 5 && !showAll"
          (click)="showAllCourses()"
          class="course-button"
        >
          View More
        </button>
        <button
          *ngIf="showAll"
          (click)="showLessCourses()"
          class="course-button"
        >
          Show Less
        </button>

        <hr />
      </ng-container>

      <ng-container
        *ngIf="
          profiles$ &&
          profiles$.certification &&
          profiles$.certification.length > 0
        "
      >
        <h1>Licence & Certifications</h1>
        <ng-container *ngFor="let certificate of profiles$?.certification">
          <div class="container">
            <div>
              <h2 *ngIf="certificate.title">
                {{ certificate.title }}
              </h2>

              <tr>
                <ng-container>
                  <p
                    *ngIf="certificate.institution && certificate.institution!=='-'"
                  >
                    {{ certificate.institution }}
                  </p>
                </ng-container>
              </tr>
              <tr>
                <span>{{ certificate.date | date : "MMM yyyy" }}</span>
              </tr>
            </div>
          </div>
        </ng-container>
        <hr />
      </ng-container>

      <ng-container
        *ngIf="profiles$ && profiles$.award && profiles$.award.length > 0"
      >
        <h1>Honors and Awards</h1>
        <ng-container *ngFor="let award of profiles$?.award">
          <h2>{{ award.title }}</h2>
          <p *ngIf="award.institution">
            {{ award.institution }}
          </p>
          <p *ngIf="award.about" class="about">{{ award.about }}</p>
          <tr>
            <td>
              <span>{{ award.date | date : "MMM yyyy" }}</span>
            </td>
          </tr>
        </ng-container>
        <hr />
      </ng-container>

      <ng-container
        *ngIf="
      profiles$ && profiles$.recommendation && profiles$.recommendation.length > 0
    "
      >
        <h1>Recommendations</h1>

        <ul>
          <ng-container
            *ngFor="let recommendation of profiles$?.recommendation"
          >
            <div class="container">
              <div>
                <h2>{{  recommendation.recommender }}</h2>
                <div>
                  <!-- <p *ngIf="recommendation.recommenderLink">
                    {{ recommendation.recommenderLink }}
                  </p> -->
                  <p *ngIf="recommendation.emphasis" class="about">
                    {{ recommendation.emphasis }}
                  </p>
                </div>
              </div>
            </div>
          </ng-container>
        </ul>
        <hr />
      </ng-container>
    </div>
    <div class="wrapper-line">
      <hr />
    </div>
    <div class="wrapper-user-info-right">
      <h1>Location</h1>
      <p>{{ profiles$?.personInfo?.location }}</p>

      <ng-container
        *ngIf="profiles$ && profiles$.skill && profiles$.skill.length > 0"
      >
        <h1>Skills</h1>
        <ng-container *ngFor="let skill of profiles$?.skill">
          <p class="keyword">{{ skill }}</p>
        </ng-container>
      </ng-container>

      <ng-container
        *ngIf="profiles$ && profiles$.language && profiles$.language.length > 0"
      >
        <h1>Languages</h1>
        <div *ngFor="let language of profiles$?.language" class="language">
          <p>{{ language.name }}</p>
          <span>{{ language.degree }}</span>
        </div>
      </ng-container>
    </div>
  </div>
</div>
