<div>
    <h4 class="text-center mt-4 mb-4">Upload video for {{name}}</h4>
    <div class="card-border mt-5">
        <div class="import mt-4">
            <h6>Drop your files here or browse from <span (click)="triggerFileUpload()">Computer</span>
                or <span>OneDrive</span></h6>
            <span class="size">Maximum upload file size: 120mb</span>
        </div>
    </div>
    <div  style="height: 130px;">

        <div *ngIf="fileSize !== '' && fileName !== ''" class="mt-2" style="display: inline-flex;">
            <div>
                <video [src]="url" *ngIf="url" height="100" width="100" controls></video>
            </div>
            <div class="ml-2">
                <p>{{fileName}}</p>
                <span class="size">{{fileSize}}Mb</span>
            </div>
        </div>
    </div>
    <div>
        <button (click)="uploadVideo()" class="upload-button">Upload</button>
        <button (click)="cancel()" class="cancel-button">Cancel</button>
    </div>
</div>

<input type="file" #fileInput style="display: none" (change)="handleFileInput($event)" accept="video/mp4"/>
