<div *ngIf="isLoading">
  <app-loading-spinner></app-loading-spinner>
</div>

<div class="layout-dashboard" *ngIf="!isLoading">
  <div class="col-12 md:col-6 xl:col-3 analytics">
    <div class="analytics-item-container">
      <div class="analytics-item" (click)="navigateToTalentDiscovery()">
        <img
          src="../../../assets/images/cpDashboardIcon.svg"
          alt="folder icon"
        />
        <div>
          <span>Candidates</span>
          <p>{{this.totalResultCount}}</p>
          <span class="green-text">
            <span>
              {{this.todayResultCount}}
            </span>
            added in last 24 hours
          </span>
        </div>
      </div>
      <div class="vertical-separator"></div>
    </div>
    <div class="analytics-item-container">
      <div class="analytics-item" (click)="navigateToTalentHunt()">
        <img
          src="../../../assets/images/talentHuntDashboard.svg"
          alt="talent icon"
        />
        <div>
          <span>Talent Hunt</span>
          <p>{{this.statsCounts.completed}}</p>
          <span class="purple-text">
            <span
              class="purple-text"
              >{{this.statsCounts.inProgress + this.statsCounts.started}}</span
            >
            in progress</span
          >
        </div>
      </div>
      <div class="vertical-separator"></div>
    </div>
    <div class="analytics-item-container">
      <div class="analytics-item" (click)="navigateToCandidatePool()">
        <img
          src="../../../assets/images/folderDashboardIcon.svg"
          alt="cp icon"
        />
        <div>
          <span>Candidate pools</span>
          <p>{{userGroupCount}}</p>
          <span class="green-text">
            <span> {{teamGroupCount}} </span>
            created by me
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 md:col-6 xl:col-3 analytics" style="margin: 3rem 0">
    <div class="countries-container">
      <div class="header">
        <div>
          <h2>Country and Experience</h2>
          <p>Profile Distribution by Country and Experience Range</p>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>0-2 y</th>
            <th>2-5 y</th>
            <th>5-8 y</th>
            <th>8-10 y</th>
            <th>10+ y</th>
            <th>TOTAL</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let stat of statistics">
            <td>{{ stat.country }}</td>
            <td>
              <img src="../../../assets/images/people.svg" alt="person icon" />
              {{ stat.ZERO_TWO_YEARS }}
            </td>
            <td>
              <img src="../../../assets/images/people.svg" alt="person icon" />
              {{ stat.TWO_FIVE_YEARS }}
            </td>
            <td>
              <img src="../../../assets/images/people.svg" alt="person icon" />
              {{ stat.FIVE_EIGHT_YEARS }}
            </td>
            <td>
              <img src="../../../assets/images/people.svg" alt="person icon" />
              {{ stat.EIGHT_TEN_YEARS }}
            </td>
            <td>
              <img src="../../../assets/images/people.svg" alt="person icon" />
              {{ stat.TEN_PLUS_YEARS }}
            </td>
            <td>
              <img src="../../../assets/images/people.svg" alt="person icon" />
              {{ stat.TOTAL }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <app-privileges-modal 
  *ngIf="isModalOpen" 
  [roles]="rolesForModal" 
  (close)="isModalOpen = false">
</app-privileges-modal>
</div>


