<div class="login-container">
  <div *ngIf="isLoading">
    <p class="loading">Loading...</p>
    <p-progressSpinner ariaLabel="loading" />
  </div>
  <div class="container" *ngIf="!isLoading && isSuccess">
    <img src="../../../assets/images/success.svg" />
    <h1>Email confirmed!</h1>
    <p>You have successfully verified your email used for this account.</p>
    <button class="general-button-purple email" (click)="navigateToLoginPage()">
      Log In
    </button>
  </div>
  <div class="container" *ngIf="!isLoading && !isSuccess">
    <img src="../../../assets/images/errorIcon.svg" />
    <h1>Email verfication failed!</h1>
    <p>Please try again.</p>
    <button class="general-button-purple email" (click)="verifyEmail(code)">
      Try Again
    </button>
  </div>

  <!-- //expired -->
  <!-- <div class="container" *ngIf="!isLoading && !isSuccess">
    <img src="../../../assets/images/emailExpired.svg" />
    <h1>Email verfication link expired!</h1>
    <p>Please resend the code to your email address.</p>
    <button class="general-button-purple">Resend Link</button>
  </div>
</div> -->
</div>
