import { Component } from '@angular/core';

import {
  DialogService,
  DynamicDialogComponent,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { ProcessControllerService, UserControllerService } from 'src/app/oapi_client/data_symphony';
import { RequestSuccessDialogComponent } from '../request-success-dialog/request-success-dialog.component';
import { ErrorDialogComponent } from 'src/app/admin/negotiation-dialogs/error-dialog/error-dialog.component';

@Component({
  selector: 'app-confirm-dialog-outgoing-request',
  templateUrl: './confirm-dialog-outgoing-request.component.html',
  styleUrls: ['./confirm-dialog-outgoing-request.component.scss'],
})
export class ConfirmDialogOutgoingRequestComponent {
  public providers: any[] = [];
  public averageRating: number = 0;
  public title: string =
    'Are you sure you want to send request to these companies?';
  public loggedMember: boolean = false;
  constructor(
    private processService: ProcessControllerService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private dialogService: DialogService,
    private authService: UserControllerService,
  ) {
    this.providers = this.config.data.providers;
    if (this.config.data.requestId) {
      if (!this.config.data.assignToHrProvider) {
        this.title = 'Are you sure you want to update this request?';
      }
    } else {
      this.title = 'Are you sure you want to send request to these companies?';
    }
  }

  ngOnInit(){
    this.checkIfMember();
  }

  public checkIfMember(){
    this.authService.getUserRoles().subscribe((roles) => {
      if (
        roles.body!.includes('ROLE_EMPLOYER_MEMBER') || 
        roles.body!.includes('ROLE_HR_PROVIDER_MEMBER')
      ) {
        this.loggedMember = true;
      } else {
        this.loggedMember = false;
      }
    });
  }

  send() {
    if (!this.config.data.requestId) {
      this.processService
        .create(this.config.data.request)
        .subscribe((response) => {
          this.ref.close(response.body!.id);
          const ref = this.dialogService.open(RequestSuccessDialogComponent, {
            data: {
              title: 'Request successfully sent!',
              paraph:
                'You’ll get notified once the company responds to your request',
            },
          });

          if(this.loggedMember){
            this.processService.sendProcessToBeApproved(response.body?.id!).subscribe(()=>{});
          }

          const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
          const dynamicComponent =
            dialogRef?.instance as DynamicDialogComponent;
          const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
          dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
        }, error=>{
          const ref = this.dialogService.open(ErrorDialogComponent, {
            data: {
              title: `Request failed to send!`,
              paraph:
                'Please try again.',
            },
          })
          const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
          const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;
      
          const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
          dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
        });
    } else {
      this.processService
        .updateProcess(this.config.data.requestId, this.config.data.request)
        .subscribe((response) => {
          this.ref.close();
          const ref = this.dialogService.open(RequestSuccessDialogComponent, {
            data: {
              title: 'Request successfully updated!',
              paraph:
                'You’ll get notified once the company responds to your request.',
            },
          });
          const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
          const dynamicComponent =
            dialogRef?.instance as DynamicDialogComponent;
          const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
          dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
        }, error=>{
          this.ref.close();
          const ref = this.dialogService.open(ErrorDialogComponent, {
            data: {
              title: `Request failed to update!`,
              paraph:
                'Please try again.',
            },
          })
          const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
          const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;
      
          const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
          dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
        });
    }
  }

  close() {
    this.ref.close();
  }

  onImageError(event: Event): void {
    (event.target as HTMLImageElement).src = '../assets/images/hr-provider/default-logo.svg';
    console.error('Image failed to load, using fallback.');
  }
}
