<div *ngIf="isLoading">
  <app-loading-spinner></app-loading-spinner>
</div>

<div class="container-top" *ngIf="!isLoading">
  <p>Talent Discovery</p>
  <span>
    Here you’ll find the list of all the talents we have in our database.
  </span>
</div>
<div class="content-container relative" [style.minHeight.px]="filterSidebarMinHeight">
  <div class="main-content">
    <div class="table-container" *ngIf="!isLoading">
      <div class="card talent-wrapper">
        
        <p-table
          #dt
          [value]="profiles$"
          [lazy]="true"
          (onLazyLoad)="loadProfiles($event)"
          [rows]="paginationService.lazyProfileListPaginationState.rows"
          [first]="paginationService.lazyProfileListPaginationState.first"
          [paginator]="true"
          [rowHover]="true"
          dataKey="externalId"
          [paginatorPosition]="'bottom'"
          class="table"
          *ngIf="filtersApplied && !isLoading"
        >
          <ng-template pTemplate="header" class="header">
            <tr>
              <th colspan="5" class="header-buttons">
                <div class="button-wrapper">
                  <div style="display: flex">
                    <th style="display: flex; align-items: center">
                      <label class="custom-checkbox-label">
                        <input
                          type="checkbox"
                          [checked]="selectAll"
                          (change)="toggleSelectAll($event)"
                        />
                        <span class="custom-checkbox-icon"></span>
                      </label>
                      <span style="padding-left: 5px;"
                        >Select All
                        <span *ngIf="selectAll" class="records">
                          ({{totalRecords}})</span
                        ></span
                      >
                    </th>
                  </div>
                  <div class="dropdown">
                    <div
                      class="container"
                      clickable
                      *ngIf="groupName; else showDropdown"
                    >
                      <span class="pi pi-angle-down"></span>
                      <button
                        pButton
                        pRipple
                        type="button"
                        label="{{ groupName }}"
                        icon="pi pi-plus"
                        (click)="addMembersToGroup(groupId)"
                        id="button-width"
                        iconPos="left"
                        tabindex="0"
                        pStyleClass="@next"
                        enterFromClass="hidden"
                        enterActiveClass="scalein"
                        leaveActiveClass="fadeout"
                        leaveToClass="hidden"
                        [hideOnOutsideClick]="true"
                      ></button>
                    </div>
                    <ng-template #showDropdown>
                      <div class="container" *ngIf="checkPermission" clickable>
                        <span
                          class="pi pi-angle-down"
                          (click)="dropdownVisible = !dropdownVisible"
                        ></span>
                        <button
                          pButton
                          pRipple
                          type="button"
                          label="Add to Candidate Pool"
                          icon="pi pi-plus"
                          (click)="dropdownVisible = !dropdownVisible"
                          id="button-width"
                          iconPos="left"
                          tabindex="0"
                          pStyleClass="@next"
                          enterFromClass="hidden"
                          enterActiveClass="scalein"
                          leaveActiveClass="fadeout"
                          leaveToClass="hidden"
                          [hideOnOutsideClick]="true"
                        ></button>
                        <div *ngIf="dropdownVisible">
                          <div class="absolute transition right-0">
                            <ul
                              class="list-none border-round shadow-2 cp-list surface-overlay"
                            >
                              <div class="pools">
                                <ng-container
                                  *ngIf="groups$ && groups$.length > 0; else noGroupsTemplate"
                                >
                                  <div *ngFor="let group of groups$" class="pool">
                                    <div (click)="addMembersToGroup(group.id)">
                                      {{ group.name }}
                                    </div>
                                  </div>
                                </ng-container>
                                <ng-template #noGroupsTemplate>
                                  <div class="no-pools-message">
                                    No candidate pools available.
                                  </div>
                                </ng-template>
                              </div>

                              <div class="add-pool">
                                <button
                                  pButton
                                  pRipple
                                  type="button"
                                  label="Create new candidate pool"
                                  icon="pi pi-plus"
                                  id="button-width"
                                  iconPos="left"
                                  (click)="openDialog()"
                                ></button>
                              </div>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </th>
            </tr>
            <tr class="header-row">
              <td colspan="5">
                <div class="header-content">
                  <th
                    pColumn="profile.person_info.name"
                    style="width: 16.8%; margin-left: 25px"
                  >
                    Candidate
                  </th>
                  <th pColumn="person_info.headline" style="width: 27.3%">Title</th>
                  <th pColumn="person_info.experience" style="width: 32.2%">
                    Experiences
                  </th>

                  <th pColumn="person_info.location" style="width: 19.7%">
                    Location
                  </th>
                  <th style="width: 8.3%">Actions</th>
                </div>
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-profile let-expanded="expanded">
            <tr>
              <td style="width: 13.5%">
                <div class="wrapper" style="padding-left: 1rem">
                  <label class="custom-checkbox-label">
                    <input
                      type="checkbox"
                      [checked]="selectedProfiles[filter.pageIndex ?? 0]?.has(profile.publicMemberId || profile.publicLinkedinMemberId)"
                      (change)="toggleSelect(profile)"
                      class="custom-checkbox"
                    />
                    <span class="custom-checkbox-icon"></span>
                  </label>

                  <span
                    class="ellipsis-description"
                    [pTooltip]="profile.personInfo.name"
                    tooltipPosition="bottom"
                    (click)="viewProfileDetails(profile)"
                    style="padding-left: 5px; width: 172px;"
                  >
                    {{ profile.personInfo.name }}
                  </span>
                </div>
              </td>
              <td (click)="viewProfileDetails(profile)" style="width: 27%">
                <div
                  class="wrapper"
                  [pTooltip]="profile?.personInfo?.headline"
                  tooltipPosition="bottom"
                >
                  <div
                    class="ellipsis-description"
                    style="max-width: 492px; margin-right: 5px;"
                  >
                    {{ profile?.lastExperienceTitle }}
                  </div>
                </div>
              </td>
              <td (click)="viewProfileDetails(profile)" style="width: 31.8%">
                <div
                  class="wrapper"
                  [pTooltip]="generateTooltip(profile)"
                  tooltipPosition="bottom"
                >
                  <div class="ellipsis-description">
                    <ng-container
                      *ngFor="let experience of profile?.experienceCompany; let last = last"
                    >
                      <span *ngIf="!last && isValidInstitutionName(experience)"
                        >{{ experience }},
                      </span>
                      <span *ngIf="last">{{ experience }}</span>
                    </ng-container>
                  </div>
                </div>
              </td>
              <td (click)="viewProfileDetails(profile)" style="width: 19.5%">
                <div class="wrapper">
                  {{ profile.personInfo?.location }}
                </div>
              </td>
              <td style="width: 10%;">
                <div class="wrapper" *ngIf="groupName">
                  <button
                    class="custom-button mr-2"
                    pTooltip="Add to CP"
                    tooltipPosition="bottom"
                  >
                    <img
                      src="../../../assets/images/addToCPIcon.svg"
                      alt="Add to CP Image"
                      (click)="addSingleProfileToGroup(profile)"
                    />
                  </button>
                  <a [href]="profile?.linkedinProfileLink" target="_blank">
                    <button
                      class="custom-button"
                      pTooltip="LinkedIn"
                      tooltipPosition="bottom"
                    >
                      <img
                        src="../../../assets/images/linkedinIcon.svg"
                        alt="LinkedIn Image"
                      />
                    </button>
                  </a>
                </div>
                <div class="wrapper" *ngIf="!groupName">
                  <a [href]="profile?.linkedinProfileLink" target="_blank">
                    <button
                      class="custom-button"
                      pTooltip="LinkedIn"
                      tooltipPosition="bottom"
                    >
                      <img
                        src="../../../assets/images/linkedinIcon.svg"
                        alt="LinkedIn Image"
                      />
                    </button>
                  </a>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
    <div class="empty-page" *ngIf="!filtersApplied">
      <p>To see the results, you need to enter the appropriate filters.</p>
    </div>
  </div>
  <app-advanced-filter-sidebar
    (filterApplied)="applyFilters($event)"
    (sidebarHeightChange)="updateSidebarFilterHeight($event)"
    ></app-advanced-filter-sidebar>
</div>