import {inject} from '@angular/core';
import { Router } from '@angular/router';

import {AuthHelper} from './auth.helper';

export const authGuard = () => {
  const authHelper = inject(AuthHelper);
  const router = inject(Router);

  if (authHelper.isAuthenticated()) {
    return true; // User is authenticated, allow access
  } else {
    authHelper.logout();
    //router.navigate(['/login']); // User is not authenticated, redirect to login page
    return false;
  }
};

 