import { Component, Input, Output } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  AbstractControl,
} from '@angular/forms';
import { DialogService, DynamicDialogComponent } from 'primeng/dynamicdialog';
import { ConfirmDialogOutgoingRequestComponent } from './confirm-dialog-outgoing-request/confirm-dialog-outgoing-request.component';
import {
  CompanyDto,
  CreateRequirementRequest,
  CountryControllerService,
  StepControllerService,
  CreateProcessRequest,
  ProcessControllerService,
  UpdateProcessRequest,
  UserControllerService,
  CreateProcessResponsibleRequest,
} from 'src/app/oapi_client/data_symphony';
import { ActivatedRoute, Router } from '@angular/router';
import { EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { SuccessDialogComponent } from 'src/app/admin/negotiation-dialogs/success-dialog/success-dialog.component';
import { ErrorDialogComponent } from 'src/app/admin/negotiation-dialogs/error-dialog/error-dialog.component';

@Component({
  selector: 'app-upsert-outgoing-request',
  templateUrl: './upsert-outgoing-request.component.html',
  styleUrls: ['./upsert-outgoing-request.component.scss'],
})
export class UpsertOutgoingRequestComponent {
  public myForm: FormGroup;
  public today!: Date;
  @Input() public providers: CompanyDto[] = [];
  @Input() public buttonSplit: boolean = false;
  @Output() public update = new EventEmitter();
  public provider: boolean = false;
  public todaysDate: Date = new Date();
  public languagesArray: any[] = [
    'TURKISH',
    'ARABIC',
    'ENGLISH',
    'RUSSIA',
    'FRENCH',
    'SPANISH',
    'BOSNIAN',
    'CHINESE',
    'HINDI',
    'PORTUGUESE',
    'JAPANESE',
    'GERMAN',
    'KOREAN',
    'ITALIAN',
    'FARSI',
  ];

  public currencyArray: any[] = [
    {label: CreateProcessRequest.salaryCurrency.DOLLAR, value: CreateProcessRequest.salaryCurrency.DOLLAR},
    {label: CreateProcessRequest.salaryCurrency.EURO, value: CreateProcessRequest.salaryCurrency.EURO},
    {label: CreateProcessRequest.salaryCurrency.TL, value: CreateProcessRequest.salaryCurrency.TL},
  ];

  public section1Dropdowns: any[] = [
    {
      label: 'Country',
      placeholder: 'Select Country',
      control: 'location',
      array: [],
    },
    {
      label: 'Working type',
      placeholder: 'Select Working Type',
      control: 'remote',
      array: [
        { label: 'Remote', value: 'REMOTE' },
        { label: 'On Site', value: 'ON_SITE' },
        { label: 'Hybrid', value: 'HYBRID' },
      ],
    },
    {
      label: 'Job type',
      placeholder: 'Select Job Type',
      control: 'type',
      array: [
        { label: 'Full-time', value: 'FULL_TIME' },
        { label: 'Part-Time', value: 'PART_TIME' },
      ],
    },
    {
      label: 'Languages',
      placeholder: 'Select Languages',
      control: 'languages',
      array: this.languagesArray.map((language) => ({
        label: language,
        value: language,
      })),
    }
  ];

  public section3Textareas = [
    {
      control: 'description',
      label: 'Description',
      placeholder: 'Enter Description',
      optional: false,
    },
    {
      control: 'note',
      label: 'Add note',
      placeholder: 'Enter Note',
      optional: true,
    },
  ];

  public selectedSkills: any;
  public values: string[] = [];
  public countries?: Array<Country>;
  public stepList: any[] = [];
  public requestId: number | null = null;
  public stepName: string = '';
  public filteredStepLists: any[] = [];
  public form: any = null;
  public typeInternal: boolean = false;
  public loggedMember: boolean = false;

  constructor(
    private fb: FormBuilder,
    private dialogService: DialogService,
    private countryService: CountryControllerService,
    private stepController: StepControllerService,
    private route: ActivatedRoute,
    private processControllerService: ProcessControllerService,
    private router: Router,
    private location: Location,
    private authService: UserControllerService,
    private processManagementService: ProcessControllerService
  ) {
    this.myForm = this.fb.group(
      {
        title: ['', [Validators.required, Validators.minLength(3)]],
        keywordList: ['', [Validators.required]],
        location: ['', [Validators.required]],
        remote: ['', [Validators.required]],
        type: ['', [Validators.required]],
        languages: ['', [Validators.required]],
        min: [0, Validators.required],
        max: [0, [Validators.required, Validators.min(1)]],
        salaryMin: [0, Validators.required],
        salaryMax: [0, [Validators.required, Validators.min(1)]],
        currency: ['', Validators.required],
        numOfCandidates: [0, [Validators.required, Validators.min(1)]],
        description: [''],
        note: [''],
        steps: this.fb.array([this.createDropdown()]),
        endDate: ['', [Validators.required]],
      },
      {
        validators: [this.minMaxValidator,this.minMaxSalaryValidator]
      }
    );

    countryService.findCountry('EN').subscribe((response) => {
      if (response.success) {
        this.section1Dropdowns[0].array = response.body?.map((item) => ({
          label: item['name'],
          value: item['name'],
        }));
      }
    });

    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras.state) {
      this.form = navigation.extras.state['form'];
    }
  }

  minMaxValidator(control: AbstractControl) {
    const min = control.get('min')?.value;
    const max = control.get('max')?.value;

    if (min != null && max != null && min > max) {
      return { minGreaterThanMax: true };
    }
    return null;
  }

  minMaxSalaryValidator(control: AbstractControl) {
    const min = control.get('salaryMin')?.value;
    const max = control.get('salaryMax')?.value;

    if (min != null && max != null && min > max) {
      return { minSalaryGreaterThanMax: true };
    }
    return null;
  }

  isMinMaxSalaryInvalid(){
    return this.myForm.hasError('minSalaryGreaterThanMax');
  }

  isMinMaxInvalid() {
    return this.myForm.hasError('minGreaterThanMax');
  }

  public getCurrencyOptions() {
    return this.currencyArray.map((currency) => ({
      label: currency,
      value: currency,
    }))
  }

  ngOnInit(): void {
    this.checkIfMember();
    this.route.params.subscribe((params) => {
      this.requestId = +params['id'];
      if (this.requestId) {
        this.loadData();
      }
      if (String(params['id']) == 'internal') {
        this.typeInternal = true;
      }
      this.today = new Date();
    });

    if (this.form) {
      this.myForm.patchValue({
        title: this.form?.title,
        keywordList: this.form?.keywordList,
        location: this.form?.location,
        remote: this.form?.remote,
        type: this.form?.type,
        languages: this.form?.languages,
        min: this.form?.min,
        max: this.form?.max,
        numOfCandidates: this.form?.numOfCandidates,
        salaryRange: this.form?.salaryRange.match(/\d+\s*-\s*\d+/)?.[0],
        deadline: new Date(this.form?.endDate),
        description: this.form?.description,
        note: this.form?.note,
        currency: this.form.currency
      });

      this.steps.clear();
      if (this.form?.steps?.length) {
        this.form.steps.forEach((step: any) => {
          this.addDropdown(step.selectedOption);
        });
      } else {
        this.addDropdown();
      }
    }

    this.getStepList();
    this.steps.valueChanges.subscribe(() => {
      this.updateFilteredStepLists();
    });

    this.getRole();
  }

  public getRole() {
    this.authService.getUserRoles().subscribe((roles) => {
      if (
        roles.body!.includes('ROLE_EMPLOYER_ADMIN') ||
        roles.body!.includes('ROLE_EMPLOYER_MEMBER')
      ) {
      } else {
        this.provider = true;
      }
    });
  }

  public checkIfMember(){
    this.authService.getUserRoles().subscribe((roles) => {
      if (
        roles.body!.includes('ROLE_EMPLOYER_MEMBER')|| 
        roles.body!.includes('ROLE_HR_PROVIDER_MEMBER')
      ) {
        this.loggedMember = true;
      } else {
        this.loggedMember = false;
      }
    });
  }

  loadData() {
    
    this.processControllerService
      .getById1(this.requestId!)
      .subscribe((data) => {
        const response = data.body;
        this.providers = response?.hrProviders!;
        this.myForm.patchValue({
          title: response?.requirement?.name,
          keywordList: response?.requirement?.keywordList,
          location: response?.requirement?.location,
          remote: response?.requirement?.workingTypes,
          type: response?.requirement?.jobTypes,
          languages: response?.requirement?.languages,
          min: response?.requirement?.minExperience,
          max: response?.requirement?.maxExperience,
          numOfCandidates: response?.numberOfHires,
          salaryRange: response?.salaryRange?.match(/\d+\s*-\s*\d+/)?.[0],
          endDate: new Date(response?.deadline!),
          description: response?.requirement?.description,
          note: response?.note,
          currency: response?.salaryCurrency
        });
        this.steps.clear();
        if (response?.steps?.length) {
          response.steps.forEach((step) => {
            this.addDropdown(step.id);
          });
        } else {
          this.addDropdown();
        }
      });
  }

  get steps(): FormArray {
    return this.myForm.get('steps') as FormArray;
  }

  createDropdown(selectedOption: number | null = null): FormGroup {
    return this.fb.group({
      selectedOption: [selectedOption],
    });
  }

  addDropdown(selectedOption: number | null = null) {
    this.steps.push(this.createDropdown(selectedOption));
    this.updateFilteredStepLists();
  }

  removeDropdown(index: number) {
    this.steps.removeAt(index);
    this.updateFilteredStepLists();
  }

  getStepList() {
    this.stepController.getStepList().subscribe((response) => {
      if (response.body) {
        this.stepList = response.body?.map((item) => ({
          label: item['name'],
          value: item['id'],
        }));
        this.updateFilteredStepLists();
      }
    });
  }

  updateFilteredStepLists() {
    const selectedOptions = this.steps.controls.map(
      (control) => control.get('selectedOption')?.value
    );

    this.steps.controls.forEach((control, index) => {
      this.filteredStepLists[index] = this.stepList.filter(
        (step) =>
          !selectedOptions.includes(step.value) ||
          step.value === control.get('selectedOption')?.value
      );
    });
  }

  stop(event: any) {
    event.stopPropagation();
  }

  addStep(event: any) {
    event.preventDefault();
    this.stepController
      .createStep({ name: event.target.value })
      .subscribe(() => {
        this.getStepList();
        event.target.value = '';
      });
  }

  onSelect(event: any, index: number) {
    event.originalEvent.stopPropagation();
    const selectedValues = event.value;

    this.section1Dropdowns[index].array.sort((a: any, b: any) => {
      const isSelectedA = selectedValues.includes(a.value);
      const isSelectedB = selectedValues.includes(b.value);

      if (isSelectedA && !isSelectedB) {
        return -1;
      } else if (!isSelectedA && isSelectedB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  public setAssigne(userId: number, processId: number) {
    const user = JSON.parse(localStorage.getItem('currentUser')!);
    const request: CreateProcessResponsibleRequest = {
      userId: user.id,
      processId: processId,
    };
    this.processManagementService.setResponsibleUser(request).subscribe({
      next: () => {},
      error: () => {},
    });
  }

  onSubmitInternal(): void {
    if (this.myForm.valid) {
      const formValues = this.myForm.value;

      const trimmedName = formValues.title.trim();
      const trimmedDescription = formValues.description.trim();
      const trimmedNote = formValues.note?.trim() || '';
      const localDeadline = new Date(formValues.endDate);
      localDeadline.setMinutes(localDeadline.getMinutes() - localDeadline.getTimezoneOffset());

      const requirement: CreateRequirementRequest = {
        name: trimmedName,
        keywordList: formValues.keywordList,
        minExperience: Number(formValues.min),
        maxExperience: Number(formValues.max),
        jobTypes: formValues.type,
        location: formValues.location,
        workingTypes: formValues.remote,
        description: trimmedDescription,
        languages: formValues.languages,
      };

      const stepIds =
        formValues.steps
          .filter(
            (step: { selectedOption: number | null }) =>
              step.selectedOption !== null
          )
          .map(
            (step: { selectedOption: number | null }) => step.selectedOption
          ) || [];

      const processedStepIds = stepIds.length > 0 ? stepIds : null;
      let request = {
        requirement: requirement,
        hrProviderIds: this.providers
          .map((provider) => provider.id)
          .filter((id): id is number => id !== undefined),
        note: trimmedNote,
        stepIds: processedStepIds,
        numberOfHires: Number(formValues.numOfCandidates),
        salaryMin: formValues.salaryMin,
        salaryMax: formValues.salaryMax,
        salaryCurrency: formValues.currency,
        deadline: localDeadline.toISOString()
      } as CreateProcessRequest;

      this.processControllerService.createInternal(request).subscribe((res) => {
        this.setAssigne(res.body?.employer?.id!, res.body?.id!);
        const ref = this.dialogService.open(SuccessDialogComponent, {
          data: {
            title: 'Process successfully created!',
            paraph: 'You can begin with talent acquisition.',
          },
        });

        if(this.loggedMember){
          this.processControllerService.sendProcessToBeApproved(res.body?.id!).subscribe(()=>{});
        }

        const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
        const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

        const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
        dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
        ref.onClose.subscribe(() => {
          this.myForm.reset();
          if (this.typeInternal) {
            this.router.navigate(['/outgoing-request']);
          }
          this.router.navigate(['/received-requests']);
        });
      }, error=>{
        const ref = this.dialogService.open(ErrorDialogComponent, {
          data: {
            title: "Process creation failed!",
            paraph:
              'Please try again.',
          },
        })
        const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
        const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;
    
        const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
        dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
      });
    }
  }

  onSubmit(): void {
    if (this.myForm.valid) {
      const formValues = this.myForm.value;

      const trimmedName = formValues.title.trim();
      const trimmedDescription = formValues.description.trim();
      const trimmedNote = formValues.note?.trim() || '';
      const localDeadline = new Date(formValues.endDate);
      localDeadline.setMinutes(localDeadline.getMinutes() - localDeadline.getTimezoneOffset());

      const requirement: CreateRequirementRequest = {
        name: trimmedName,
        keywordList: formValues.keywordList,
        minExperience: Number(formValues.min),
        maxExperience: Number(formValues.max),
        jobTypes: formValues.type,
        location: formValues.location,
        workingTypes: formValues.remote,
        description: trimmedDescription,
        languages: formValues.languages,
      };

      const stepIds =
        formValues.steps
          .filter(
            (step: { selectedOption: number | null }) =>
              step.selectedOption !== null
          )
          .map(
            (step: { selectedOption: number | null }) => step.selectedOption
          ) || [];

      const processedStepIds = stepIds.length > 0 ? stepIds : null;
      let request: CreateProcessRequest | UpdateProcessRequest;

      if (!this.requestId) {
        request = {
          requirement: requirement,
          hrProviderIds: this.providers
            .map((provider) => provider.id)
            .filter((id): id is number => id !== undefined),
          note: trimmedNote,
          stepIds: processedStepIds,
          numberOfHires: Number(formValues.numOfCandidates),
          salaryMin: formValues.salaryMin,
          salaryMax: formValues.salaryMax,
          salaryCurrency: formValues.currency,
          deadline: localDeadline.toISOString()
        } as CreateProcessRequest;
      } else {
        request = {
          requirement: requirement,
          note: trimmedNote,
          stepIds: processedStepIds,
          numberOfHires: Number(formValues.numOfCandidates),
          salaryMin: formValues.salaryMin,
          salaryMax: formValues.salaryMax,
          salaryCurrency: formValues.currency,
          deadline: localDeadline.toISOString()
        } as UpdateProcessRequest;
      }

      if (this.providers.length !== 0 || this.requestId) {
        const ref = this.dialogService.open(
          ConfirmDialogOutgoingRequestComponent,
          {
            data: {
              request: request,
              providers: this.providers,
              requestId: this.requestId,
            },
          }
        );

        const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
        const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

        const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
        dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
        ref.onClose.subscribe(() => {
       
          this.myForm.reset();
          if (this.buttonSplit) {
            this.update.emit('');
          } else {
            this.router.navigate(['/outgoing-requests']);
          }
        });
      } else {
        this.router.navigate(['/hr-providers'], {
          state: { request: request, form: this.myForm.value },
        });
      }
    }
  }

  getClassObject(field: string) {
    return {
      'border-invalid':
        !this.myForm!.get(field)!.valid && this.myForm!.get(field)!.touched,
    };
  }

  onValueRemoved(event: any): void {
    const index = this.selectedSkills?.indexOf(event?.value);
    if (index !== -1) {
      this.selectedSkills.splice(index, 1);
    }
  }

  onValueAdded(event: any): void {
    this.values = this.values.map((str) => str.trim().toLowerCase());
    const uniqueSkills = [...new Set(this.values)];
    this.values = uniqueSkills.flat();
  }

  removeKeyword(item: string): void {
    const valuesControl = this.myForm?.get('keywordList');
    if (valuesControl && valuesControl.value) {
      const updatedValues = valuesControl.value.filter(
        (value: string) => value !== item
      );

      valuesControl.setValue(updatedValues);
    }
  }

  cancelEdit() {
    this.location.back();
  }
}

interface Country {
  code: string;
  name: string;
}
