import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { guestGuard } from '../helpers/guest-guard.service';
import { EmailConfirmationComponent } from './email-confirmation/email-confirmation.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { LoginComponent } from './login/login.component';
import { MemberRegisterComponent } from './member-register/member-register.component';
import { RegisterComponent } from './register/register.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [guestGuard],
      },
      {
        path: 'register',
        component: RegisterComponent,
        canActivate: [guestGuard],
      },
      {
        path: 'email-verification',
        component: EmailVerificationComponent,
        canActivate: [guestGuard],
      },
      {
        path: 'email-confirmation/verify',
        component: EmailConfirmationComponent,
        canActivate: [guestGuard],
      },
      {
        path: 'member-register',
        component: MemberRegisterComponent,
        canActivate: [guestGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LandingRoutingModule {}
