import { Component } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
})
export class AdminLayoutComponent {
  constructor(private router: Router) {}

  ngOnInit() {}

  getLayoutClassByURL(): boolean {
    const currentUrl = this.router.url;
    return currentUrl.startsWith('/dashboard');
  }
}
