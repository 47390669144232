import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import {
  AmsResponseString,
  ManageUserControllerService,
} from 'src/app/oapi_client/data_symphony';
import { EmailRequest } from 'src/app/oapi_client/data_symphony/models/EmailRequest';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss'],
})
export class EmailVerificationComponent {
  email: string | null = '';

  constructor(
    private emailService: ManageUserControllerService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.email = sessionStorage.getItem('userEmail');
  }

  resendLink(): void {
    if (this.email) {
      const requestBody: EmailRequest = {
        email: this.email,
      };

      this.emailService.resendVerificationEmail(requestBody).subscribe({
        next: (response: AmsResponseString) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Verification email sent successfully!',
          });
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Failed to resend verification email.',
          });
        },
      });
    }
  }
}
