<div class="filter-dropdown" [ngClass]="{ isOpen: isOpen }">
  <button (click)="toggleDropdown()" class="dropdown-button">
    <img src="../../../assets/images/dropdownIcon.svg" class="dropdown-image" />
    Filters
  </button>
  <div *ngIf="isOpen" class="dropdown-container">

    <div class="dropdown-content">
      <label for="multiSelectInput">Minimum number of ratings</label>
      <p-dropdown [options]="rating" [(ngModel)]="selectedRating"  placeholder="Select the minimum number of ratings" optionLabel="label"
        id="singleSelectInput" />

    </div>
    <div class="dropdown-content">
      <label for="multiSelectInput">Range of completed jobs</label>
      <p-dropdown [options]="finishedJobs" [(ngModel)]="selectedJobs" placeholder="Select the range of completed jobs" optionLabel="label"
      id="singleSelectInput" />
    </div>
    <div class="buttons-wrapper">
      <button (click)="clearFilters()">Clear All</button>
      <button pButton label="Apply" tooltipPosition="top" class="custom-button p-mr-2"  (click)="applyFilter()"></button>
    </div>
  </div>
</div>