import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { LandingModule } from './landing/landing.module';
import { AccountClient, AnimalClient, API_BASE_URL } from './client/apiclient';

import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CommonModule } from '@angular/common';
import { AuthHelper } from './helpers/auth.helper';
import { DateHelper } from './helpers/date.helper';
import { AdminModule } from './admin/admin.module';
import { AuthenticationControllerService } from './oapi_client/data_symphony';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { HttpClientModule } from '@angular/common/http';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    LandingModule,
    AdminModule,
    ProgressSpinnerModule,
    CommonModule,
    HttpClientModule,
  ],
  exports: [],
  providers: [
    AnimalClient,
    AuthHelper,
    DateHelper,
    AccountClient,
    AuthenticationControllerService,
    DialogService,

    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: CsrfInterceptor, multi: true },
    { provide: 'VERSION', useValue: environment.version },
    {
      provide: 'BASE_API_URL',
      useValue: environment.baseUrl,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
