import { Component, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanyControllerService, CreateHRCompanyFilterRequest, DSResponseListCompanyDto} from 'src/app/oapi_client/data_symphony';

@Component({
  selector: 'app-filters-modal',
  templateUrl: './filters-modal.component.html',
  styleUrls: ['./filters-modal.component.scss']
})
export class FiltersModalComponent {
  
  public isOpen: boolean = false;
  public rating = [{ label: '1', value: 1 }, { label: '2', value: 2 }, { label: '3', value: 3 }, { label: '4', value: 4 }, { label: '5', value: 5 }];
  public finishedJobs = [{ label: '<10', value: 10, min: 0 }, { label: '11-50', value: 50, min: 11 }, { label: '>50', value: 50, min: 50 }]
  public selectedRating: { label: string; value: number} = {
    label: "",
    value: 0,
  };
  public selectedJobs: { label: string; value: number; min: number } = {
    label: "",
    value: 0,
    min: 0
  };
  @Output() filtersApplied: EventEmitter<any> = new EventEmitter<any>();

  constructor(private elementRef: ElementRef, private companyService: CompanyControllerService) { }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  applyFilter(){
    const request: CreateHRCompanyFilterRequest = {
      ...(this.selectedRating.value != 0 && { minRating: this.selectedRating.value }),
      ...(this.selectedJobs.min != 0 && { minFinishedJobCount: this.selectedJobs.min }),
      ...(this.selectedJobs.value != 0 && { maxFinishedJobCount: this.selectedJobs.value })
    };
    
    this.filterOutProviders(request).subscribe((response)=>{
      const resp = response.body;
      this.filtersApplied.emit({ resp, request });
      this.isOpen = false;
    })
  }

  filterOutProviders(
    requestBody: CreateHRCompanyFilterRequest
  ): Observable<DSResponseListCompanyDto> {
    return this.companyService.filter(requestBody);
  }

  clearFilters(){
    this.selectedJobs = {label: "", value: 0, min: 0};
    this.selectedRating = {label: "", value: 0};
  }
}
