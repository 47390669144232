/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { CreateAndUpdateStepRequest } from '../models/CreateAndUpdateStepRequest';
import type { DSResponseListStepDto } from '../models/DSResponseListStepDto';
import type { DSResponseObject } from '../models/DSResponseObject';
import type { DSResponseStepDto } from '../models/DSResponseStepDto';
import type { DSResponseString } from '../models/DSResponseString';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class StepControllerService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @param id
     * @returns DSResponseStepDto OK
     * @throws ApiError
     */
    public getStep(
        id: number,
    ): Observable<DSResponseStepDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/processManagement/step/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns DSResponseStepDto OK
     * @throws ApiError
     */
    public updateStep(
        id: number,
        requestBody: CreateAndUpdateStepRequest,
    ): Observable<DSResponseStepDto> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v1/processManagement/step/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns DSResponseString OK
     * @throws ApiError
     */
    public deleteStep(
        id: number,
    ): Observable<DSResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/v1/processManagement/step/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @returns DSResponseListStepDto OK
     * @throws ApiError
     */
    public getStepList(): Observable<DSResponseListStepDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/processManagement/step/',
        });
    }
    /**
     * @param requestBody
     * @returns DSResponseObject OK
     * @throws ApiError
     */
    public createStep(
        requestBody: CreateAndUpdateStepRequest,
    ): Observable<DSResponseObject> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/processManagement/step/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
