import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'floating-label-chips',
  templateUrl: './floating-label-chips.component.html',
  styleUrls: ['./floating-label-chips.component.scss']
})
export class FloatingLabelChipsComponent {
  @Input() label: string = '';
  @Input() id: string = '';
  @Input() model: string[] = [];
  @Input() availableSuggestions: string[] = [];

  @Output() modelChange: EventEmitter<string[]> = new EventEmitter();
  @Output() onAdd: EventEmitter<string> = new EventEmitter();
  @Output() onRemove: EventEmitter<string> = new EventEmitter();

  displayedSuggestions: string[] = [];

  addValue(event: any) {
    this.onAdd.emit(event.value);
    this.modelChange.emit(this.model);
  }

  removeValue(event: any) {
    this.onRemove.emit(event.value);
    this.modelChange.emit(this.model);
  }

  filterSuggestions(event: any) {
    let query = event.query.toLowerCase();
    this.displayedSuggestions = this.availableSuggestions.filter(suggestion =>
        suggestion.toLowerCase().includes(query)
    );
  }
}
