import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService, DynamicDialogComponent } from 'primeng/dynamicdialog';
import { ProcessUtilsService } from 'src/app/helpers/process-utils.service';
import {
  BudgetDto,
  CreateNegotiationRequest,
  NegotiationControllerService,
  ProcessControllerService,
  ReceivedProcessDto,
  StepDto,
  UserControllerService,
} from 'src/app/oapi_client/data_symphony';
import { CompanyControllerService } from 'src/app/oapi_client/data_symphony/services/CompanyControllerService';
import { SendCounterOfferComponent } from '../negotiation-dialogs/send-counter-offer/send-counter-offer.component';
import { ConfirmationDialogComponent } from '../negotiation-dialogs/confirmation-dialog/confirmation-dialog.component';
import { SuccessDialogComponent } from '../negotiation-dialogs/success-dialog/success-dialog.component';
import { forkJoin, map, switchMap, tap } from 'rxjs';

interface ProcessStep {
  name: string;
  details?: string;
}

@Component({
  selector: 'app-received-requests',
  templateUrl: './received-requests.component.html',
  styleUrls: ['./received-requests.component.scss'],
})
export class ReceivedRequestsComponent {

  processSteps: ProcessStep[] = [];
  skills: string[] = [];
  processes: any[] = [];
  filteredProcesses: any[] = [];
  paginatedProcesses: any[] = [];
  selectedProcessId: number | null = null;
  selectedProcess: ReceivedProcessDto | null = null;
  selectedTab: string = 'all';
  page: number = 1;
  totalRecords: number = 0;
  pageSize: number = 5;
  searchText: string = '';
  hrProviderId: number = 37;
  isLoading: boolean = false;
  internalProcesses: any[]=[];
  admin: boolean = false;
  emptyMessage: string = 'No outgoing request.';
  emptyParaph: string = 'You haven‘t created any request yet.'

  constructor(
    private processManagementService: CompanyControllerService,
    private processUtils: ProcessUtilsService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private negotiationControllerService: NegotiationControllerService,
    private processControllerService: ProcessControllerService,
    private userController: UserControllerService
  ) { }

  ngOnInit(): void {
    this.receiveRequests();
    this.route.queryParams.subscribe((params) => {
      if (params['id']) {
        this.selectProcess(parseInt(params['id'], 10));
      }
    });
  }

  getRole(){
    this.userController.getUserRoles().subscribe({
      next:(value)=> {
        if(value.body?.includes('ROLE_HR_PROVIDER_ADMIN')){
          this.admin = true;
        }
      },
    })
  }

  receiveRequests(): void {
    this.isLoading = true;
    this.processManagementService.getReceivedRequest().pipe(
      switchMap(response => {
        this.processes = response?.body?.sort(
          (a, b) => new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()
        ) || [];
        
        this.filterProcesses('all');
        
        if (this.selectedProcessId !== null) {
          this.selectedProcess = this.processes.find(
            process => process.id === this.selectedProcessId
          ) || null;
        }
  
        const offerRequests = this.processes.map(process =>
          this.negotiationControllerService.getAllByHrCompanyId(process.id).pipe(
            map(res => ({ processId: process.id, offers: res?.body || [] }))
          )
        );
  
        return forkJoin(offerRequests);
      })
    ).subscribe({
      next: offersData => {
        offersData.forEach(({ processId, offers }) => {
          const process = this.processes.find(p => p.id === processId);
          if (process) {
            process.offers = offers;
          }
        });
        this.isLoading = false;
      },
      error: error => {
        console.error('Error fetching received requests or offers:', error);
        this.isLoading = false;
      }
    });
  }
  
  acceptOffer(event: any, proposal: any) {
    event.stopPropagation();
    const ref = this.dialogService.open(ConfirmationDialogComponent, {
      data: {
        title: "Are you sure you want to accept Employers counter-offer?",
        paraph: 'Once you confirm all the other companies offers will be automatically declined',
        proposal: `Counter offer: ${proposal.offer.amount + ' ' + proposal.offer.type} `,
      }
    })

    const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
    const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

    const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
    dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;

    ref.onClose.subscribe((result) => {
      if (result) {
        this.negotiationControllerService.approveByHrProvider
          (proposal.id)
          .subscribe(() => {
            const ref = this.dialogService.open(SuccessDialogComponent, {
              data: {
                title: 'Offer accepted',
                paraph: 'You have accepted the offer. The process can start.',
              },
            });
            const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
            const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

            const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
            dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;

            this.receiveRequests();
      });
        const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
        const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

        const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
        dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
      }
    });


  }

  sendOffer(event: any, processId: number, employerId: number) {
    event.stopPropagation();
    const ref = this.dialogService.open(SendCounterOfferComponent, {});

    const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
    const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

    const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
    dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;

    ref.onClose.subscribe((res) => {
      if (res) {
        const offer: CreateNegotiationRequest = {
          offerToId: employerId,
          processId: processId,
          offer: {
            type: BudgetDto.type.EURO,
            amount: res.amount,
          },
          note: res.note,
        };
        this.negotiationControllerService
          .createNegotiation(offer)
          .subscribe(() => {
            this.receiveRequests();
            const ref = this.dialogService.open(SuccessDialogComponent, {
              data: {
                title: 'Offer successfully sent!',
                paraph:
                  'You’ll get notified once the company approves the offer!',
              },
            });

            const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
            const dynamicComponent =
              dialogRef?.instance as DynamicDialogComponent;

            const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
            dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
          });
      }
    });
  }

  decline(event: any, processId: number) {
    event.stopPropagation();
    const ref = this.dialogService.open(ConfirmationDialogComponent, {
      data: {
        title: 'Are you sure you want to decline Company XY‘s request?',
        paraph: 'Once you confirm, you can‘t go back.',
      },
    });

    const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
    const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

    const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
    dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;

    ref.onClose.subscribe((res) => {
      if (res) {
        this.negotiationControllerService
          .declineOffer(processId)
          .subscribe((res) => {
            const ref = this.dialogService.open(SuccessDialogComponent, {
              data: {
                title: 'Request declined',
                paraph:
                  'You have declined the request. The Employer will be notified.',
              },
            });

            const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
            const dynamicComponent =
              dialogRef?.instance as DynamicDialogComponent;

            const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
            dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
            this.receiveRequests();
          });
      }
    });
  }

  toggleView(): void {
    this.selectedProcess = null;
  }

  filterProcesses(status: string): void {
    this.selectedTab = status;
    this.page = 1;
    let filtered = this.processes;
    
    if(status ==='INTERNAL'){
      filtered = filtered.filter((process) => process.isInternal);
    }
    else if (status !== 'all') {
      filtered = filtered.filter((process) => process.status === status);
    }

    if (this.searchText) {
      filtered = filtered.filter((process) =>
        process.requirement?.name
          ?.toLowerCase()
          .includes(this.searchText.toLowerCase())
      );
    }

    this.filteredProcesses = filtered;
    this.totalRecords = filtered.length;

    if(this.totalRecords == 0){
      this.setMessage(status);
    }
    this.updatePaginatedProcesses();
  }

  setMessage(status: string){
    if(status == 'all'){
      this.emptyMessage = 'No outgoing request.';
      this.emptyParaph = 'You haven‘t created any request yet.'
      return;
    }
    this.emptyMessage = `No ${status.charAt(0).toUpperCase() + status.slice(1).toLocaleLowerCase()} requests.`;
    this.emptyParaph = `There is no ${status.charAt(0).toUpperCase() + status.slice(1).toLocaleLowerCase()} requests yet.`
  }

  updatePaginatedProcesses(): void {
    const start = (this.page - 1) * this.pageSize;
    const end = this.page * this.pageSize;
    this.paginatedProcesses = this.filteredProcesses.slice(start, end);
  }

  onPageChange(event: any): void {
    this.page = event.page + 1;
    this.updatePaginatedProcesses();
  }

  onSearchChange(event: any): void {
    this.searchText = event.target.value;
    this.filterProcesses(this.selectedTab);
  }

  formatDate(dateString: string | undefined): string {
    return this.processUtils.formatDate(dateString);
  }

  formatJobType(jobType: string): string {
    return this.processUtils.formatJobType(jobType);
  }

  formatExperience(min: number | undefined, max: number | undefined): string {
    return this.processUtils.formatExperience(min, max);
  }

  formatLanguages(languages: Array<string> | undefined): string {
    return this.processUtils.formatLanguages(languages);
  }

  formatJobTypes(jobTypes: Array<string> | undefined): string {
    return this.processUtils.formatJobTypes(jobTypes);
  }

  formatWorkingTypes(workingTypes: Array<string> | undefined): string {
    return this.processUtils.formatWorkingTypes(workingTypes);
  }

  setProcessSteps(
    steps: StepDto[] | undefined,
    stepOrder: number[] | undefined
  ): void {
    const orderedSteps = stepOrder?.map((orderId) =>
      steps?.find((step) => step.id === orderId)
    );
    this.processSteps = orderedSteps?.filter(
      (step) => step !== undefined
    ) as ProcessStep[];
  }

  selectProcess(processId: any): void {
    this.selectedProcessId = processId;
    this.selectedProcess =
      this.processes.find((process) => process.id === processId) || null;
    if (this.selectedProcess) {
      this.setProcessSteps(
        this.selectedProcess.steps,
        this.selectedProcess.stepOrder
      );
    }
  }

  navigateToDetails(id: any): void {
    this.router.navigate([`/received-request-details/${id}`]);
  }
}
