import { AuthHelper } from './auth.helper';
import { inject } from '@angular/core';
import { Router } from '@angular/router';

export const guestGuard = () => {
  const authHelper = inject(AuthHelper);
  const router = inject(Router);

  if (authHelper.isAuthenticated()) {
    router.navigate(['/dashboard']);
    return false;
  }

  return true;
};
