import { Component, HostListener, ViewChild } from '@angular/core';
import { InviteMemberModalComponent } from './invite-member-modal/invite-member-modal.component';
import { Table } from 'primeng/table';
import { PaginationService } from '../../../app/helpers/pagination.service';
import { ParameterService } from '../../../app/helpers/parameter.service';
import { ManageUserControllerService, UserControllerService } from '../../../app/oapi_client/data_symphony';
import { DialogService, DynamicDialogComponent } from 'primeng/dynamicdialog';
import { ConfirmationDialogComponent } from '../negotiation-dialogs/confirmation-dialog/confirmation-dialog.component';
import { SuccessDialogComponent } from '../negotiation-dialogs/success-dialog/success-dialog.component';
import { TeamManagamentPrivilegesModalComponent } from './team-managament-privileges-modal/team-managament-privileges-modal.component';
import { ErrorDialogComponent } from '../negotiation-dialogs/error-dialog/error-dialog.component';

@Component({
  selector: 'app-team-management',
  templateUrl: './team-management.component.html',
  styleUrls: ['./team-management.component.scss'],
})

export class TeamManagementComponent {
  @ViewChild(InviteMemberModalComponent) inviteMemberModal!: InviteMemberModalComponent;

  @ViewChild('dt') dataTable: Table | undefined;

  public isLoading: boolean = true;
  public users: any[] = [];
  public searchText: string = '';
  public orgUsers: any[] = [];
  public first: number = 0;
  public rows: number = 5;
  public totalMemberCount: number = 0;
  public hasEllipsis: boolean = false;
  public dropdownVisible: boolean = false;
  public adminLogged: boolean = false;
  constructor(
    protected paginationService: PaginationService,
    protected parameterService: ParameterService,
    public manageUserControllerService: ManageUserControllerService,
    public dialogService: DialogService, private authService: UserControllerService
  ) { }


  showInviteMemberModal() {
    this.inviteMemberModal.display = true;
  }

  ngOnInit() {
    this.getTeammates();
    this.authService.getUserRoles().subscribe((roles) => {
      if (roles.body!.includes('ROLE_EMPLOYER_ADMIN') || roles.body!.includes('ROLE_HR_PROVIDER_ADMIN')) {
        this.adminLogged = true;
      }
    });
  }

  getTeammates() {
    this.manageUserControllerService.userTeammates().subscribe(res => {
      if (res.body){
        this.users = res.body.map(user => {
          user.roles = user.roles.filter(role => !role.startsWith('ROLE'));
          return user; 
      });        
      this.orgUsers = this.users;
      }
    })
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const clickedInsideDropdown = (event.target as HTMLElement).closest(
      '.dropdown'
    );
    if (!clickedInsideDropdown) {
      this.users.map(u => u.dropdownVisible = false);
    }
  }

  toggleDropdown(user: any) {
    if (user.dropdownVisible) {
      user.dropdownVisible = false;
      return;
    }
    this.users.map(u => u.dropdownVisible = false);
    user.dropdownVisible = true;
  }

  openDialog(action: 'Activate' | 'Deactivate' | 'Remove' | 'Cancel', user: any): void {
    const actionData = {
      'Deactivate': {
        confirmTitle: 'Are you sure you want to deactivate the member?',
        confirmParaph: 'If you do, the user will no longer have access to this organization.',
        successTitle: `You have successfully deactivated ${user.email}.`,
        successParaph: 'You can send the invitation again.',
        serviceMethod: () => this.manageUserControllerService.deactivateUser(user.id)
      },
      'Activate': {
        confirmTitle: 'Are you sure you want to activate the member?',
        confirmParaph: 'If you do, the user will now have access to this organization.',
        successTitle: `You have successfully activated ${user.email}.`,
        successParaph: 'You can deactivate the user if you made a mistake.',
        serviceMethod: () => this.manageUserControllerService.activateUser(user.id)
      },
      'Remove': {
        confirmTitle: 'Are you sure you want to remove the member?',
        confirmParaph: 'If you do, you will have to send the invitation again.',
        successTitle: `You have successfully removed ${user.email}.`,
        successParaph: 'You can invite the user again if you made a mistake.',
        serviceMethod: () => this.manageUserControllerService.deleteUser(user.id)
      },
      'Cancel': {
        confirmTitle: 'Are you sure you want to cancel the invitation?',
        confirmParaph: 'If you do, you will have to send it again.',
        successTitle: `You have successfully cancelled the invitation for ${user.email}.`,
        successParaph: 'You can invite the user again if you made a mistake.',
        serviceMethod: () => this.manageUserControllerService.cancelInvitation(user.id)
      }
    };

    const data = actionData[action];
    if (data) {
      this.confirmAction(data.confirmTitle, data.confirmParaph)
        .subscribe((confirmed) => {
          if (confirmed) {
            data.serviceMethod().subscribe(() => {
              this.showSuccessDialog(data.successTitle, data.successParaph);
            },error=>{
              const ref = this.dialogService.open(ErrorDialogComponent, {
                data: {
                  title: "There was a problem with team management.",
                  paraph: "Please try again later."
                },
              })
              const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
              const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;
          
              const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
              dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
            });
          }
        });
    }
  }

  confirmAction(title: string, paraph: string) {
    return this.dialogService.open(ConfirmationDialogComponent, {
      data: { title, paraph }
    }).onClose;
  }

  showSuccessDialog(title: string, paraph: string) {
    this.dialogService.open(SuccessDialogComponent, {
      data: { title, paraph }
    }).onClose.subscribe(() => this.getTeammates());
  }

  searchTeammates() {
    this.searchText = this.searchText.toLocaleLowerCase();
    this.users = this.orgUsers.filter((user) => {
      return (
        user.firstName?.toLocaleLowerCase().includes(this.searchText) ||
        user.lastName?.toLocaleLowerCase().includes(this.searchText) ||
        user.email?.toLocaleLowerCase().includes(this.searchText)
      );
    });
  }

  openEditPriviligesModal(user: any) {
    const ref = this.dialogService.open(TeamManagamentPrivilegesModalComponent, {
      data: {
        user: user
      }
    })
    const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
    const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

    const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
    dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;

    ref.onClose.subscribe({
      next: () => {
        this.getTeammates();
      }
    })
  }
}
