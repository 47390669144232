<div *ngIf="isLoading">
  <app-loading-spinner></app-loading-spinner>
</div>

<div class="container-top" *ngIf="!isLoading">
  <div class="app-outgoing">
    <div>
      <p>Received requests</p>
      <span>
        Here you’ll find the list of all the requests that have been sent to you.
      </span>
    </div>
    <div>
      <button class="create-request" routerLink="/outgoing-requests-upsert/">
        Create New Process
      </button>
    </div>

  </div>

</div>

<div class="wrapper" *ngIf="selectedProcess">
  <div class="left">
    <div class="left-wrapper">
      <div class="tabs" *ngIf="processes.length !== 0">
        <button [ngClass]="{'active': selectedTab === 'all'}" (click)="filterProcesses('all')">
          View all
        </button>

        <button [ngClass]="{'active': selectedTab === 'IN_PROGRESS'}" (click)="filterProcesses('IN_PROGRESS')">
          In progress
        </button>
        <button [ngClass]="{'active': selectedTab === 'IN_REVIEW'}" (click)="filterProcesses('IN_REVIEW')">
          In review
        </button>
        <button [ngClass]="{'active': selectedTab === 'SUCCEED'}" (click)="filterProcesses('SUCCEED')">
          Done
        </button>
        <button [ngClass]="{'active': selectedTab === 'PENDING'}" (click)="filterProcesses('PENDING')">
          Pending
        </button>
        <button [ngClass]="{'active': selectedTab === 'INTERNAL'}" (click)="filterProcesses('INTERNAL')">
          Internal
        </button>
      </div>
      <div class="small-card-container">
        <ng-container *ngFor="let process of paginatedProcesses">
          <div class="card-info" [ngClass]="{'selected': process.id === selectedProcessId}"
            (click)="selectProcess(process.id)">
            <div class="card-info-wrapper">
              <div class="card-info-left">
                <div>
                  <h4>{{ process?.requirement?.name }}</h4>
                  <span *ngIf="!process.isInternal">By <strong>{{process.employerName}}</strong></span>
                  <span *ngIf="process.isInternal"><strong>Internal request</strong></span>
                </div>
                <p class="status" [ngClass]="{
                    'succeed': process.status === 'SUCCEED' || process.status === 'IN_REVIEW',
                    'pending': process.status === 'PENDING',
                    'in-progress': process.status === 'IN_PROGRESS'
                  }">
                  <ng-container *ngIf="process.status === 'SUCCEED'; else otherStatus">
                    done
                  </ng-container>
                  <ng-template #otherStatus>
                    {{ process.status | titlecase }}
                  </ng-template>
                </p>
              </div>
              <div class="card-info-right">
                <div class="price" *ngIf="process.status === 'IN_PROGRESS' || process.status === 'DONE'">
                  <div style="display: block;" class="mr-6">
                    <div style="float: right;">
                      <p *ngIf="process.budget === null">
                        Price: <strong>Not defined</strong>
                      </p>
                      <p *ngIf="process.budget !== null">
                        Price:
                        <strong>{{ process.budget?.amount
                          }}&nbsp;{{process.budget?.type}}</strong>
                      </p>
                    </div>
                  </div>
                  <div style="display: block;" class="mr-6">
                    <div style="display:inline-flex; float: inline-end;">
                      <div *ngIf="process.responsibleId && process.isInternal" class="assigned-user">Assigned to {{process.responsibleName}}
                      </div>
                      <div *ngIf="!process.responsibleId && process.isInternal" class="not-assigned">Not assigned</div>
                      <div style="float: right;">{{ formatDate(process.createdAt) }}</div>
                    </div>
                  </div>
                </div>
                <div style="display: block; width: 200px;">
                  <div style="display: block; width: 100%; text-align: right;">
                    <ng-container *ngIf="process.status === 'PENDING' && process?.offers?.at(-1)?.offer === null && process?.offers?.at(-1)?.isHrAgreed === null
                    && process?.offers?.at(-1)?.isEmployerAgreed === null && admin">
                        <button class="offer mr-2" (click)="sendOffer($event,process.id!, process.employerId!)">
                          Send Offer
                        </button>
                        <button class="decline" (click)="decline($event,process?.offers?.at(-1).id)">
                          Decline
                        </button>
                      </ng-container>
                      <ng-container *ngIf="process.status === 'PENDING'&& process?.offers !== null && process?.offers?.length == 1 && process?.offers?.at(-1).isEmployerAgreed == null
                      && process?.offers?.at(-1).isHrAgreed == true">
                        <div class="text-center" style="justify-content: right;"> Offer sent: <div class="font-bold">
                            {{process?.offers?.at(-1).offer.amount}}
                            {{process?.offers?.at(-1).offer.type}}</div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="process.status === 'PENDING'&& process?.offers !== null && process?.offers?.length == 2 &&  process?.offers?.at(-1).isHrAgreed === null && 
                      process?.offers?.at(-1).isEmployerAgreed === null && admin">
                      <div style="display: inline-flex;">
                        <div class="text-center mt-1"> Counter-offer: <div class="font-bold">
                          {{process?.offers?.at(-1).offer.amount}}
                          {{process?.offers?.at(-1).offer.type}}</div>
                      </div>
                      <div>
                        <button pButton type="button" label="Accept" class="accept"
                          (click)="acceptOffer($event,process?.offers?.at(-1))"></button>
                        <button pButton type="button" label="Decline" class="decline"
                          (click)="decline($event,process?.offers?.at(-1).id)"></button>
                        <button pButton type="button" label="Last price" class="offer"
                          (click)="sendOffer($event,process.id!, process.employerId!)"></button>
                      </div>
                      </div>
                       
                      </ng-container>
                      <ng-container
                        *ngIf="process.status === 'PENDING'&& process?.offers !== null && process?.offers?.length == 2 && process?.offers?.at(-1).isHrAgreed
                        && process?.offers?.at(-1).isEmployerAgreed == null">
                        <div class="text-center"> You have accepted the employers counter offer: <div class="font-bold">
                            {{process?.offers?.at(-1).offer.amount}}
                            {{process?.offers?.at(-1).offer.type}}</div>
                        </div>
                      </ng-container>
                      <ng-container
                      *ngIf="process.status === 'PENDING'&& process?.offers !== null && process?.offers?.length == 2 && process?.offers?.at(-1).isHrAgreed 
                      && process?.offers?.at(-1).isEmployerAgreed == false">
                      <div class="text-center font-bold"> The employer have revoked his counter offer.
                      </div>
                    </ng-container>
                      <ng-container
                        *ngIf="process.status === 'PENDING'&& process?.offers !== null && process?.offers?.length == 3 && process?.offers?.at(-1).isEmployerAgreed != false">
                        <div class="text-center"> Last price offer sent: <div class="font-bold">
                            {{process?.offers?.at(-1).offer.amount}}
                            {{process?.offers?.at(-1).offer.type}}</div>
                        </div>
                      </ng-container>
                      <ng-container
                        *ngIf="process.status === 'PENDING'&& process?.offers !== null && process?.offers?.length === 1 && process?.offers?.at(-1).isEmployerAgreed == false">
                        <div class="text-center font-bold" style="justify-content: right;">Your offer was declined.</div>
                      </ng-container>
                      <ng-container *ngIf="process.status === 'PENDING'&& process?.offers?.length == 2 && process?.offers?.at(-1).isEmployerAgreed == null && 
                      process?.offers?.at(-1).isHrAgreed == false  && process?.offers?.at(-1).offer != null && 
                      process?.offers?.at(-1).offeredById == process?.offers?.at(-1).employerId">
                        <div class="text-center font-bold" style="justify-content: right; width: 100%;">Your have declined the counter offer.</div>
                      </ng-container>
                      <ng-container *ngIf="process.status === 'PENDING' && process?.offers?.length === 1 && process?.offers?.at(-1).offer == null && 
                      process?.offers?.at(-1).isHrAgreed == false && process?.offers?.at(-1).isEmployerAgreed == null">
                        <div class="text-center font-bold">Your have declined this request.</div>
                      </ng-container>
                      <ng-container
                        *ngIf="process.status === 'IN_PROGRESS' || process.status === 'SUCCEED' ||  process.status === 'IN_REVIEW'">
                          <strong class="text text-center" (click)="navigateToDetails(process.id)">View Process</strong>
                      </ng-container>
                  </div>
                  <div style="display: block; width: 100%; text-align: right;" *ngIf="!process?.isInternal" class="mr-6">
                    <div style="display:inline-flex;">
                      <div *ngIf="process.responsibleId" class="assigned-user">Assigned to {{process.responsibleName}}
                      </div>
                      <div *ngIf="!process.responsibleId" class="not-assigned">Not assigned</div>
                      <div style="float: right;">{{ formatDate(process.createdAt) }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="wrapper-line"></div>
  <div class="right">
    <ng-container>
      <div class="right-top">
        <div>
          <img src="../../../assets/images/expandIcon.svg" alt="expand" style="margin-bottom: 10px;"
            (click)="navigateToDetails(selectedProcess.id)" />

          <p class="status" [ngClass]="{
                'succeed': selectedProcess.status === 'SUCCEED' || selectedProcess.status === 'IN_REVIEW',
                'pending': selectedProcess.status === 'PENDING',
                'in-progress': selectedProcess.status === 'IN_PROGRESS'
              }">
            <ng-container *ngIf="selectedProcess.status === 'SUCCEED'; else otherStatus">
              done
            </ng-container>
            <ng-template #otherStatus>
              {{ selectedProcess.status | titlecase }}
            </ng-template>
          </p>
        </div>
        <div class="company-container">
          <img src="../../../assets/images/closeIcon.svg" alt="close" style="margin-bottom: 10px;"
            (click)="toggleView()" />
          <div class="company-info">
            <span>By <strong>{{selectedProcess.employerName}}</strong></span>
            <span>{{ formatDate(selectedProcess.createdAt) }}</span>
          </div>
        </div>
      </div>
      <h2 class="title">{{selectedProcess.requirement?.name}}</h2>

      <div class="container">
        <div>
          <h2>Experience</h2>
          <p>
            {{ formatExperience(selectedProcess?.requirement?.minExperience,
            selectedProcess?.requirement?.maxExperience) }}
          </p>
        </div>
        <div>
          <h2>Language(s)</h2>
          <p>
            {{ formatLanguages(selectedProcess?.requirement?.languages) }}
          </p>
        </div>
      </div>
      <div class="container">
        <div>
          <h2>Location</h2>
          <p>{{selectedProcess?.requirement?.location}}</p>
        </div>
        <div>
          <h2>Remote</h2>
          <p>
            {{ formatWorkingTypes(selectedProcess?.requirement?.workingTypes) }}
          </p>
        </div>
      </div>
      <div class="container">
        <div>
          <h2>Job type</h2>
          <p>{{ formatJobTypes(selectedProcess?.requirement?.jobTypes) }}</p>
        </div>
      </div>

      <h2>Skills</h2>
      <div class="skills">
        <ng-container *ngFor="let keyword of selectedProcess?.requirement?.keywordList">
          <span>{{ keyword }}</span>
        </ng-container>
      </div>
      <h2>Process</h2>
      <ul>
        <li *ngFor="let step of processSteps">
          <div class="process">
            <span>{{ step.name }}</span>
          </div>
        </li>
      </ul>
      <div class="info">
        <img src="../../../assets/images/info.svg" alt="info" />
        <p>
          You’ll be able to add more tasks and edit existing ones once you
          confirm this request.
        </p>
      </div>
    </ng-container>
  </div>
</div>

<div class="wrapper expanded" *ngIf="!selectedProcess">
  <div class="wrapper-content">
    <div class="tabs" *ngIf="processes.length !== 0">
      <button [ngClass]="{'active': selectedTab === 'all'}" (click)="filterProcesses('all')">
        View all
      </button>

      <button [ngClass]="{'active': selectedTab === 'IN_PROGRESS'}" (click)="filterProcesses('IN_PROGRESS')">
        In progress
      </button>
      <button [ngClass]="{'active': selectedTab === 'IN_REVIEW'}" (click)="filterProcesses('IN_REVIEW')">
        In review
      </button>
      <button [ngClass]="{'active': selectedTab === 'SUCCEED'}" (click)="filterProcesses('SUCCEED')">
        Done
      </button>
      <button [ngClass]="{'active': selectedTab === 'PENDING'}" (click)="filterProcesses('PENDING')">
        Pending
      </button>
      <button [ngClass]="{'active': selectedTab === 'INTERNAL'}" (click)="filterProcesses('INTERNAL')">
        Internal
      </button>
    </div>
    <div class="small-card-container">
      <ng-container *ngFor="let process of paginatedProcesses">
        <div class="card-info" [ngClass]="{'selected': process.id === selectedProcessId}"
          (click)="selectProcess(process.id)">
          <div class="card-info-wrapper">
            <div class="card-info-left">
              <div class="card-name">
                <div class="overflow-text">
                  <h4>{{ process.requirement?.name }}</h4>
                  <span *ngIf="!process.isInternal">By <strong>{{process.employerName}}</strong></span>
                  <span *ngIf="process.isInternal"><strong>Internal request</strong></span>
                </div>
              </div>
              <p class="status" [ngClass]="{
                        'succeed': process.status === 'SUCCEED' || process.status === 'IN_REVIEW',
                        'pending': process.status === 'PENDING',
                        'in-progress': process.status === 'IN_PROGRESS'
                      }">
                <ng-container *ngIf="process.status === 'SUCCEED'; else otherStatus">
                  done
                </ng-container>
                <ng-template #otherStatus>{{ process.status | titlecase }}</ng-template>
              </p>
            </div>
            <div class="card-info-right">
              <div class="price" *ngIf="process.status === 'IN_PROGRESS' || process.status === 'DONE'">
                <div style="display: block;" class="mr-6">
                  <div style="float: right;">
                    <p *ngIf="process.budget === null">
                      Price: <strong>Not defined</strong>
                    </p>
                    <p *ngIf="process.budget !== null">
                      Price:
                      <strong>{{ process.budget?.amount
                        }}&nbsp;{{process.budget?.type}}</strong>
                    </p>
                  </div>
                </div>
                <div style="display: block;" class="mr-6">
                  <div style="display:inline-flex; float: inline-end;">
                    <div *ngIf="process.responsibleId && process.isInternal" class="assigned-user">Assigned to {{process.responsibleName}}
                    </div>
                    <div *ngIf="!process.responsibleId && process.isInternal" class="not-assigned">Not assigned</div>
                    <div style="float: right;">{{ formatDate(process.createdAt) }}</div>
                  </div>
                </div>
              </div>
              <div style="display: block; min-width: 200px;">
                <div style="display: block">
                  <ng-container *ngIf="process.status === 'PENDING' && process?.offers?.at(-1)?.offer === null && process?.offers?.at(-1)?.isHrAgreed === null
                && process?.offers?.at(-1)?.isEmployerAgreed === null && admin">
                    <button class="offer mr-2" (click)="sendOffer($event,process.id!, process.employerId!)">
                      Send Offer
                    </button>
                    <button class="decline" (click)="decline($event,process?.offers?.at(-1).id)">
                      Decline
                    </button>
                  </ng-container>
                  <ng-container *ngIf="process.status === 'PENDING'&& process?.offers !== null && process?.offers?.length == 1 && process?.offers?.at(-1).isEmployerAgreed == null
                  && process?.offers?.at(-1).isHrAgreed == true">
                    <div class="text-center" style="justify-content: right;"> Offer sent: <div class="font-bold">
                        {{process?.offers?.at(-1).offer.amount}}
                        {{process?.offers?.at(-1).offer.type}}</div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="process.status === 'PENDING'&& process?.offers !== null && process?.offers?.length == 2 &&  process?.offers?.at(-1).isHrAgreed === null && 
                  process?.offers?.at(-1).isEmployerAgreed === null && admin">
                  <div style="display: inline-flex;">
                    <div class="text-center mt-1"> Counter-offer: <div class="font-bold">
                      {{process?.offers?.at(-1).offer.amount}}
                      {{process?.offers?.at(-1).offer.type}}</div>
                  </div>
                  <div>
                    <button pButton type="button" label="Accept" class="accept"
                      (click)="acceptOffer($event,process?.offers?.at(-1))"></button>
                    <button pButton type="button" label="Decline" class="decline"
                      (click)="decline($event,process?.offers?.at(-1).id)"></button>
                    <button pButton type="button" label="Last price" class="offer"
                      (click)="sendOffer($event,process.id!, process.employerId!)"></button>
                  </div>
                  </div>
                   
                  </ng-container>
                  <ng-container
                    *ngIf="process.status === 'PENDING'&& process?.offers !== null && process?.offers?.length == 2 && process?.offers?.at(-1).isHrAgreed
                    && process?.offers?.at(-1).isEmployerAgreed == null">
                    <div class="text-center"> You have accepted the employers counter offer: <div class="font-bold">
                        {{process?.offers?.at(-1).offer.amount}}
                        {{process?.offers?.at(-1).offer.type}}</div>
                    </div>
                  </ng-container>
                  <ng-container
                  *ngIf="process.status === 'PENDING'&& process?.offers !== null && process?.offers?.length == 2 && process?.offers?.at(-1).isHrAgreed 
                  && process?.offers?.at(-1).isEmployerAgreed == false">
                  <div class="text-center font-bold"> The employer have revoked his counter offer.
                  </div>
                </ng-container>
                  <ng-container
                    *ngIf="process.status === 'PENDING'&& process?.offers !== null && process?.offers?.length == 3 && process?.offers?.at(-1).isEmployerAgreed != false">
                    <div class="text-center"> Last price offer sent: <div class="font-bold">
                        {{process?.offers?.at(-1).offer.amount}}
                        {{process?.offers?.at(-1).offer.type}}</div>
                    </div>
                  </ng-container>
                  <ng-container
                    *ngIf="process.status === 'PENDING'&& process?.offers !== null && process?.offers?.length === 1 && process?.offers?.at(-1).isEmployerAgreed == false">
                    <div class="text-center font-bold" style="justify-content: right;">Your offer was declined.</div>
                  </ng-container>
                <div class="overflow-text"></div>
                <ng-container *ngIf="process.status === 'PENDING'&& process?.offers?.length == 2 && process?.offers?.at(-1).isEmployerAgreed == null && 
                  process?.offers?.at(-1).isHrAgreed == false  && process?.offers?.at(-1).offer != null && 
                  process?.offers?.at(-1).offeredById == process?.offers?.at(-1).employerId">
                    <div class="text-center font-bold" style="justify-content: right; width: 100%;">Your have declined the counter offer.</div>
                  </ng-container>
                  <ng-container *ngIf="process.status === 'PENDING' && process?.offers?.length === 1 && process?.offers?.at(-1).offer == null && 
                  process?.offers?.at(-1).isHrAgreed == false && process?.offers?.at(-1).isEmployerAgreed == null">
                    <div class="text-center font-bold">Your have declined this request.</div>
                  </ng-container>
                  <ng-container
                    *ngIf="process.status === 'IN_PROGRESS' || process.status === 'SUCCEED' ||  process.status === 'IN_REVIEW'">
                      <strong class="text text-center" (click)="navigateToDetails(process.id)">View Process</strong>
                  </ng-container>
                </div>
                <div style="display: block; width: 100%; text-align: right;" *ngIf="!process?.isInternal" class="mr-6">
                  <div style="display:inline-flex;">
                    <div *ngIf="process.responsibleId" class="assigned-user">Assigned to {{process.responsibleName}}
                    </div>
                    <div *ngIf="!process.responsibleId" class="not-assigned">Not assigned</div>
                    <div style="float: right;">{{ formatDate(process.createdAt) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div *ngIf="(processes.length === 0 || totalRecords ==0) && !isLoading" class="empty">
  <div><img src="../../../assets/images/folders.svg" alt="folder" /></div>
  <h2>{{emptyMessage}}</h2>
  <p>{{emptyParaph}}</p>
</div>

<div class="pagination" *ngIf="totalRecords > pageSize" [ngClass]="{
  'expandView': selectedProcess
}">
  >
  <p-paginator [rows]="pageSize" [totalRecords]="totalRecords" (onPageChange)="onPageChange($event)"></p-paginator>
</div>