<button class="note-button" (click)="isOpen = !isOpen">
    <img src="../../../assets/images/note-process.svg" alt="Note">
</button>
<ul class="list-none border-round shadow-2  absolute  right-0" *ngIf="isOpen">
    <div class="note-modal">
        <div class="no-notes">
            You can leave a comment here regarding this step.
        </div>
        <div class="add-note">
            <input class="add" [(ngModel)]="note" placeholder="Add a comment..." (keydown.enter)="updateStep()">
        </div>
    </div>
</ul>