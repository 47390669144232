<div class="table-wrapper">
  <div>
    <h1>Proposals</h1>
    <p>
      If you accept a proposal, the rest of them are automatically declined.
    </p>
    <p class="sort">View: <span>All</span></p>
  </div>
  <div class="proposals-table">
    <p-table [value]="proposals" [paginator]="true" [rows]="10">
      <ng-template pTemplate="header">
        <tr>
          <th class="rating-th">Sent to</th>
          <th class="rating-th">Rating</th>
          <th class="text-center">Number of jobs</th>
          <th class="date-of-sending-th">Date of sending</th>
          <th style="padding-left: 28px;">Proposal</th>
          <th class="text-right pr-5" style="width: 30%;">Actions</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-proposal>
        <tr>
          <td>{{proposal.companyName}}</td>
          <td class="rating-td">
            <span>{{ proposal.avgStarCount | number: '1.1-1' }}</span>
            <p-rating 
              [(ngModel)]="proposal.avgStarCount" 
              [readonly]="true" 
              [cancel]="false" 
              [min]="0" 
              [max]="1" 
              [stars]="1" 
              [ngModelOptions]="{ standalone: true }" 
              [value]="proposal.avgStarCount > 1 ? 1 : proposal.avgStarCount">
            </p-rating>
            
        
          </td>
          <td class="text-center">{{proposal.succeedProcessCount}}</td>
          <td>{{ proposal.date | date:'short' }}</td>
          <td class="text-center status-proposal">
            <ng-container 
              *ngIf="proposal.isHrAgreed == true && proposal.isEmployerAgreed == false">
              <div class="proposal-pending-status declined text-center">
              Declined
              </div>
            </ng-container>
            <ng-container
              *ngIf="proposal.offer == null && proposal.isHrAgreed == null && proposal.isEmployerAgreed == null">
              <div class="proposal-pending-status"><span class="pl-2 pr-2">Pending</span></div>
            </ng-container>
            <ng-container *ngIf="proposal.offer !== null && proposal.isEmployerAgreed == null">
              <div class="proposal-offer-status text-center">
                <span class="pl-2 pr-2"> {{proposal.offer.amount + ' ' + proposal.offer.type}}</span>
              </div>
            </ng-container>
            <ng-container *ngIf="proposal.isEmployerAgreed == true">
              <div class="proposal-offer-status  accepted text-center" >
                Accepted -
                {{proposal.offer.amount + ' ' + proposal.offer.type}}
              </div>
            </ng-container>
           
          </td>
          <td class="text-right">
            <div *ngIf="proposal.offer == null && proposal.isHrAgreed == null && proposal.employerId">
              <button pButton type="button" label="Cancel" class="proposal-pending-action" (click)="cancelProvider(proposal.hrProviderId)"></button>
            </div>
            <div *ngIf="proposal.offer != null && proposal.isEmployerAgreed == null && proposal.isHrAgreed == true">
              <button pButton type="button" label="Accept" class="proposal-accept-action mr-2"
                (click)="openDialog('Accept', proposal.id, proposal.offer.amount, proposal.offer.type)"></button>
              <button pButton type="button" label="Decline" class="proposal-decline-action mr-2"
                (click)="openDialog('Decline',proposal.id, undefined, undefined, proposal.employerId)"></button>
              <button pButton type="button" label="Counter Offer" class="proposal-counter-offer"
                *ngIf="!proposal.hasThreeOccurrences && proposal.employerId != proposal.offeredById"
                (click)="openDialog('Counter', proposal.id)"></button>
            </div>
            <div
              *ngIf="proposal.offer != null && proposal.employerId == proposal.offeredById && proposal.isEmployerAgreed != true">
              <div *ngIf="proposal.isHrAgreed == null">
                Counter offer sent
              </div>
              <div *ngIf="proposal.isHrAgreed == false">
                Counter offer declined
              </div>
            </div>
            <div *ngIf="proposal.isHrAgreed == true && proposal.isEmployerAgreed == false" class="pr-2">
              <img src="../../../assets/images/deleteIcon.svg" (click)="deleteProposal(proposal.hrProviderId)" class="image-delete" />
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>